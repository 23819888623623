export const actions = {
  TOGGLE_FOR_DELETION_SELECTION: 'TOGGLE_FOR_DELETION_SELECTION',
  SELECT_ALL_CONTEXTS_FOR_DELETION: 'SELECT_ALL_CONTEXTS_FOR_DELETION',
  UNSELECT_ALL_CONTEXTS_FOR_DELETION: 'UNSELECT_ALL_CONTEXTS_FOR_DELETION',
  SELECT_ALL_DOCUMENTS_FOR_DELETION: 'SELECT_ALL_DOCUMENTS_FOR_DELETION',
  UNSELECT_ALL_DOCUMENTS_FOR_DELETION: 'UNSELECT_ALL_DOCUMENTS_FOR_DELETION',
};

const removeObjectProperty = (object, property) =>
  Object.keys(object).reduce((obj, key) => {
    if (key !== property) {
      return { ...obj, [key]: object[key] };
    }
    return obj;
  }, {});

const getSelectedForDeletion = (selectedContexts, state, documentId) => {
  if (selectedContexts.length > 0) {
    return {
      ...state.selectedForDeletion,
      [documentId]: selectedContexts,
    };
  }

  return removeObjectProperty(state.selectedForDeletion, documentId);
};

const reduceToggleForDeletionSelection = (state, payload) => {
  const currentDocument = state.selectedForDeletion[payload.documentId];
  const currentContext = payload.selectedContexts.find((context) => context.id === payload.contextId);
  const contextIsRemoved = currentDocument && currentDocument.some((context) => context.id === payload.contextId);

  const selectedContexts = contextIsRemoved
    ? currentDocument.filter((context) => context.id !== payload.contextId)
    : currentDocument
    ? currentDocument.concat(currentContext)
    : [currentContext];

  const selectedForDeletion = getSelectedForDeletion(selectedContexts, state, payload.documentId);
  return { ...state, selectedForDeletion };
};

const reduceSelectAllContextsForDeletion = (state, payload) => {
  const currentDocument = payload.selectedDocuments.find((x) => x.id === payload.documentId);

  const allDocumentContexts = currentDocument.contexts;

  const selectedForDeletion = getSelectedForDeletion(allDocumentContexts, state, payload.documentId);

  return { ...state, selectedForDeletion };
};

const reduceUnselectAllContextsForDeletion = (state, { documentId }) => {
  const selectedForDeletion = getSelectedForDeletion([], state, documentId);
  return { ...state, selectedForDeletion };
};

const reduceSelectAllDocumentsForDeletion = (state, { selectedDocuments }) => {
  const selectedForDeletion = selectedDocuments.reduce((obj, document) => ({ ...obj, [document.id]: document.contexts }), {});
  return { ...state, selectedForDeletion };
};

const reduceUnselectAllDocumentsForDeletion = (state) => ({
  ...state,
  selectedForDeletion: {},
});

export const reducer = (state, { action, payload }) => {
  switch (action) {
    case actions.TOGGLE_FOR_DELETION_SELECTION:
      return reduceToggleForDeletionSelection(state, payload);
    case actions.SELECT_ALL_CONTEXTS_FOR_DELETION:
      return reduceSelectAllContextsForDeletion(state, payload);
    case actions.UNSELECT_ALL_CONTEXTS_FOR_DELETION:
      return reduceUnselectAllContextsForDeletion(state, payload);
    case actions.SELECT_ALL_DOCUMENTS_FOR_DELETION:
      return reduceSelectAllDocumentsForDeletion(state, payload);
    case actions.UNSELECT_ALL_DOCUMENTS_FOR_DELETION:
      return reduceUnselectAllDocumentsForDeletion(state);
    default:
      return state;
  }
};
