import React from 'react';
import { claimContextShape } from '../../lib/customProptypes';
import { getFormatedPeriod } from '../../lib/helpers';
import s from './ClaimInfo.module.scss';

const ClaimInfo = ({ context }) => (
  <div>
    <div className={s.description}>Description</div>
    <div>{context.description}</div>
    <div className={s.details}>Details</div>
    <div className={s.caption}>Contract Period</div>
    <div>{context.contractPeriod && getFormatedPeriod(context.contractPeriod)}</div>
    <div className={s.caption}>Insurance Period</div>
    <div>{context.insurancePeriod && getFormatedPeriod(context.insurancePeriod)}</div>
    <div className={s.caption}>Client claim reference</div>
    <div>{context.clientReference}</div>
    <div className={s.caption}>Xchanging</div>
    <div>{context.xChanging}</div>
  </div>
);

ClaimInfo.propTypes = { context: claimContextShape };

export default ClaimInfo;
