import React from 'react';
import { number, string } from 'prop-types';
import style from './PageStatus.module.scss';

const defaultErrors = Object.freeze({
  404: 'The requested resource was not found',
});

const PageStatus = ({ statusCode, message }) => (
  <main className={style.statusPage}>
    <div className={style.statusCode}>{statusCode}</div>
    <div className={style.statusMessage}>{message || defaultErrors[statusCode]}</div>
  </main>
);

PageStatus.propTypes = {
  statusCode: number.isRequired,
  message: string,
};

export default PageStatus;
