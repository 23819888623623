import PropTypes from 'prop-types';
import React from 'react';
import { mapTaxonomyDisplayName } from '../../../lib';
import FlexibleContent from '../FlexibleContent';
import Tag, { tagType } from '../Tag';
import styles from './flexibleKeywordsContent/FlexibleKeywordsContent.module.scss';

const FlexibleKeywordsContent = ({ keywords, type = tagType.stroke }) => {
  if (!keywords || keywords.length === 0) return null;
  const tags = keywords.map((keyword) => <Tag key={keyword} className={styles.tag} text={mapTaxonomyDisplayName(keyword)} type={type} />);
  const numberOfTags = tags.length;
  const flexibleTags = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numberOfTags; i++) {
    const counter = numberOfTags - i;
    const TagsToShow = tags.slice(0, counter);
    const keywordsInToolTip = keywords.slice(counter);

    flexibleTags.push(
      <>
        {TagsToShow}
        {i !== 0 && <Tag className={styles.tag} text={`+${i}`} type={type} data-tip={keywordsInToolTip} data-for="keywords-tooltip" />}
      </>
    );
  }

  const TagsCounter = <Tag className={styles.tag} text={`+${tags.length}`} type={type} data-tip={keywords} data-for="keywords-tooltip" />;

  return <FlexibleContent content={[...flexibleTags, TagsCounter]} />;
};

FlexibleKeywordsContent.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.oneOf(Object.values(tagType)),
};

export default FlexibleKeywordsContent;
