import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer, useMemo } from 'react';

const initialState = [];

const ForcedPiiContext = createContext(initialState);

export const useForcedPiiNotifier = () => {
  const { state, dispatch } = useContext(ForcedPiiContext);

  const addForcedPii = (piiSection) => {
    dispatch({ type: ADD_FORCED_PII, payload: piiSection });
  };

  const clearForcedPiiSections = () => {
    dispatch({ type: CLEAR_FORCED_PIIS });
  };

  return {
    forcedPiiSections: state,
    addForcedPii,
    clearForcedPiiSections,
  };
};

const ADD_FORCED_PII = 'ADD_FORCED_PII';
const CLEAR_FORCED_PIIS = 'CLEAR_FORCED_PIIS';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case ADD_FORCED_PII: {
      const orderedByDocumentName = [...state, payload].sort((a, b) => a.name.localeCompare(b.name));

      return orderedByDocumentName;
    }

    case CLEAR_FORCED_PIIS: {
      return [];
    }

    default:
      return state;
  }
};

export const ForcedPiiProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );

  return <ForcedPiiContext.Provider value={value}>{children}</ForcedPiiContext.Provider>;
};

ForcedPiiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
