import { mdiAccountAlert, mdiLockOutline, mdiPaperclip } from '@mdi/js';
import MdiIcon, { Icon } from '@mdi/react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { mapTaxonomyDisplayName } from '../../lib';
import useLongPress from '../../hooks/useLongPress';
import { getFormatedDate, getFormatedTime } from '../../lib/helpers';
import * as constants from '../constVariables';
import EmailDirectionIndicator from '../SearchResult/EmailDirectionIndicator';
import { Tag } from '../Shared';
import DescriptionItem from '../Shared/DescriptionItem';
import styles from './CaseDocumentsLimitedSpace.module.scss';
import { documentViewModel } from './helpers';

const CaseDocumentsLimitedSpace = ({ documents, selectedDocumentsIds, toggleDocumentSelection, toggleDocumentDetails }) => (
  <div className={styles.root}>
    {documents.map((document) => (
      <CaseDocument
        key={document.id}
        document={document}
        selectedDocumentsIds={selectedDocumentsIds}
        toggleDocumentSelection={toggleDocumentSelection}
        toggleDocumentDetails={toggleDocumentDetails}
      />
    ))}
  </div>
);

CaseDocumentsLimitedSpace.propTypes = {
  documents: PropTypes.arrayOf(documentViewModel).isRequired,
  selectedDocumentsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleDocumentSelection: PropTypes.func.isRequired,
  toggleDocumentDetails: PropTypes.func.isRequired,
};

export default CaseDocumentsLimitedSpace;

const CaseDocument = ({ document, selectedDocumentsIds, toggleDocumentSelection, toggleDocumentDetails }) => {
  const { id, direction, date, to, from, notes, title, docType, keywords, additionalInfo } = document;
  const { hasAttachments, containsPii } = additionalInfo;
  const dateTime = `${getFormatedDate(date)} - ${getFormatedTime(date)}`;
  const selected = selectedDocumentsIds.includes(id);
  const itemRef = useRef(null);

  const onLongPress = () => {
    toggleDocumentSelection(id);
  };

  const onClick = () => {
    toggleDocumentDetails(id);
  };

  useLongPress(itemRef, onLongPress, onClick);

  const ariaLabel = `Open details for document from ${from}, to ${to}, titled ${title}, dated ${dateTime}'`;
  return (
    <button key={id} ref={itemRef} type="button" className={styles.document} aria-label={ariaLabel}>
      <article className={cn(styles.card, { [styles.selected]: selected })}>
        <header className={styles.header}>
          <div className={styles.metadata}>
            <EmailDirectionIndicator direction={direction} className={styles.emailIndicator} />
            <MdiIcon path={mdiLockOutline} size="16px" color={constants.GREY_ICON_COLOR} />
            <span>{dateTime}</span>
          </div>

          <div className={styles.metadata}>
            {containsPii && <Icon path={mdiAccountAlert} title="Includes personally identifiable information" size={constants.SMALL_ICON_SIZE} />}
            {hasAttachments && <Icon path={mdiPaperclip} title="Has attachments" size={constants.SMALL_ICON_SIZE} />}
            {docType ? <Tag text={mapTaxonomyDisplayName(docType)} /> : null}
          </div>
        </header>

        <section className={styles.users}>
          <div>
            <DescriptionItem term="From" detail={from} />
          </div>

          <div>
            <DescriptionItem term="To" detail={to} />
          </div>
        </section>

        <section className={styles.content}>
          <div>
            <DescriptionItem term="Title" detail={title} classNameDetail={styles.truncateOverflow} />
          </div>

          <div>
            <DescriptionItem term="Notes" detail={notes} classNameDetail={styles.truncateOverflow} />
          </div>
        </section>

        <footer className={styles.keywords}>
          {keywords.map((keyword) => (
            <Tag key={keyword} text={mapTaxonomyDisplayName(keyword)} type="stroke" />
          ))}
        </footer>
      </article>
    </button>
  );
};

CaseDocument.propTypes = {
  document: documentViewModel.isRequired,
  selectedDocumentsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleDocumentSelection: PropTypes.func.isRequired,
  toggleDocumentDetails: PropTypes.func.isRequired,
};
