import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledLink = styled.a`
  text-decoration: none;
  font-size: 18px;
  :hover {
    color: white;
  }
`;
const Container = styled.div`
  height: 100vh;
  background-color: #003c71;
  display: flex;
  flex-direction: column;
  justify-content: center;
  * {
    margin-bottom: 16px;
  }
`;
const Content = styled.div`
  color: white;
  text-align: center;
`;
const Title = styled.h1`
  font-size: 64px;
  text-align: center;
`;
const Text = styled.div`
  font-size: 2em;
  text-align: center;
  span {
    font-size: 18px;
  }
`;

export const DefaultErrorMessage = ({ message }) => (
  <Container>
    <Content>
      <Title>Oh no...</Title>
      <Text>Something went wrong</Text>
      <Text>
        <span>({message})</span>
      </Text>
      <StyledLink href="/">Click here to return to the frontpage</StyledLink>
    </Content>
  </Container>
);

DefaultErrorMessage.propTypes = {
  message: PropTypes.string,
};
