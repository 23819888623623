/* eslint-disable no-restricted-globals */
import { isBefore, isValid, parse, format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { getFormatedDate } from '../../lib/helpers';
import * as constants from '../constVariables';

const DatePickerInput = forwardRef(
  ({ handleSpecificDate, handleDateRange, resetAllDates, closeDatepicker, syncFromDateToDatePicker, stateFrom, stateTo, ...rest }, ref) => {
    const [currentInput, setCurrenInput] = useState('');
    const dateFormat = 'dd.MM.yyyy';

    useEffect(() => {
      // Sync input with Url parameters
      const input = stateFrom && stateTo ? `${getFormatedDate(stateFrom)} - ${getFormatedDate(stateTo)}` : getFormatedDate(stateFrom);
      setCurrenInput(input);
    }, [stateFrom, stateTo]);

    const parseAndValidate = (input) => {
      const [startInput, endInput] = input.split('-').map((date) => date.trim());
      const from = parse(startInput, dateFormat, new Date());
      const to = parse(endInput, dateFormat, new Date());

      // Strict validation, user needs to write full date dd.MM.yyyy
      const fromIsValid = isValid(from) && startInput === format(from, dateFormat);
      const toIsValid = isValid(to) && endInput === format(to, dateFormat);

      return { from, to, fromIsValid, toIsValid };
    };

    const onChange = ({ target }) => {
      const inputValue = target.value;
      setCurrenInput(inputValue);

      if (!inputValue) {
        resetAllDates();
        return;
      }

      handleDateChange(inputValue);
    };

    const onKeyDown = (event) => {
      if (event.keyCode === constants.ENTER || event.keyCode === constants.TAB) {
        triggerSearch(event);
        return;
      }

      if (event.keyCode === constants.ESC) {
        resetAllDates();
        closeDatepicker();
      }
    };

    const triggerSearch = (event) => {
      const { value } = event.target;
      const [startInput] = value.split('-').map((date) => date.trim());
      const { from, to, fromIsValid, toIsValid } = parseAndValidate(value);
      const fromUtc = from instanceof Date && !isNaN(from.getTime()) ? from.toISOString() : null;
      const toUtc = to instanceof Date && !isNaN(to.getTime()) ? to.toISOString() : null;

      if (fromIsValid && toIsValid) {
        handleDateRange(fromUtc, toUtc);
      } else if (fromIsValid && !toIsValid) {
        handleSpecificDate(fromUtc);
        setCurrenInput(startInput);
      } else {
        resetAllDates();
        setCurrenInput('');
      }

      closeDatepicker();
    };

    const handleDateChange = (inputValue) => {
      const { from, to, fromIsValid, toIsValid } = parseAndValidate(inputValue);
      const fromUtc = from instanceof Date && !isNaN(from.getTime()) ? from.toISOString() : null;
      const toUtc = to instanceof Date && !isNaN(to.getTime()) ? to.toISOString() : null;

      const userIsTypingEndDate = fromIsValid && inputValue.includes('-') && !toIsValid;
      const noChanges = fromUtc === stateFrom && toUtc === stateTo;
      if (!fromIsValid || userIsTypingEndDate || noChanges) {
        return;
      }

      // Push 'to' date ahead if the user typed a date older than 'from'
      if (fromIsValid && toIsValid && isBefore(to, from)) {
        const newTo = new Date(from);
        newTo.setDate(newTo.getDate() + 1);
        handleDateRange(fromUtc, newTo.toISOString());
        return;
      }

      if (fromIsValid && toIsValid) {
        handleDateRange(fromUtc, toUtc);
        return;
      }

      if (fromIsValid) {
        handleSpecificDate(fromUtc);
        syncFromDateToDatePicker(from);
      }
    };

    return <input ref={ref} {...rest} onChange={onChange} value={currentInput} onKeyDown={onKeyDown} maxLength={23} />;
  }
);

DatePickerInput.propTypes = {
  handleSpecificDate: PropTypes.func.isRequired,
  handleDateRange: PropTypes.func.isRequired,
  resetAllDates: PropTypes.func.isRequired,
  closeDatepicker: PropTypes.func.isRequired,
  syncFromDateToDatePicker: PropTypes.func.isRequired,
  stateFrom: PropTypes.string,
  stateTo: PropTypes.string,
};

export default DatePickerInput;
