import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAccount } from '../../providers/authenticationProvider';

const hasPermission = (permission) => true;

const redirectState = (pathname, location) => ({
  pathname,
  state: { from: location },
});

const LoginRedirect = ({ location }) => <Redirect to={redirectState('/login', location)} />;

LoginRedirect.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }),
};

const UnauthorizedRedirect = ({ location }) => <Redirect to={redirectState('/unauthorized', location)} />;

UnauthorizedRedirect.propTypes = {
  location: PropTypes.string,
};

const ProtectedRoute = ({ component: Component, permission, render, ...rest }) => {
  const { isAuthenticated, login } = useAccount();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          login();
        }
        if (!hasPermission(permission)) {
          return <UnauthorizedRedirect location={props.location} />;
        }
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  permission: PropTypes.string,
  render: PropTypes.func,
  location: PropTypes.shape({}),
};

export default ProtectedRoute;
