import React from 'react';
import PropTypes from 'prop-types';
import { addIndex, map, partition } from 'ramda';
import { nameEmailShape } from '../../lib/customProptypes';
import s from './Details.module.scss';
import { recipientType } from '../constVariables';

const indexedMap = addIndex(map);

const Dot = () => <div className={s.dot} />;

const Recipient = ({ name, email }) => (
  <li>
    <a href={`mailto:${email}`}>
      <span>{name}</span>
      <Dot />
      <span>{email}</span>
    </a>
  </li>
);

Recipient.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

const mapToolTipRecipients = indexedMap((recipient, i) => <Recipient key={i} name={recipient.name} email={recipient.address} />);

const Recipients = ({ recipients = [] }) => {
  const [toRecipients, ccRecipients] = partition((recipient) => recipient.recipientType === recipientType.To, recipients);

  return (
    recipients.length > 0 && (
      <div className={s.extraRecipients}>
        <span>Show recipients ({recipients.length})</span>
        <div className={s.extraRecipientsTooltip}>
          <h6>To</h6>
          <ul>{mapToolTipRecipients(toRecipients)}</ul>
          <h6>Cc</h6>
          <ul>{mapToolTipRecipients(ccRecipients)}</ul>
        </div>
      </div>
    )
  );
};

Recipients.propTypes = {
  recipients: PropTypes.arrayOf(PropTypes.shape(nameEmailShape)).isRequired,
};

export default Recipients;
