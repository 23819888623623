import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import check from '../Icons/check.svg';
import loaderModal from '../Icons/loader_modal.svg';
import useMergeDocuments, { ErrorTypes } from '../../hooks/useMergeDocuments';
import cry from '../Icons/cry.svg';
import XHR_STATUS from '../../lib/xhrStatus';
import { unselectAllDocuments } from '../../containers/SearchResult/actions';
import { useDetailsQueryParam } from '../../hooks/documents';
import style from './ModalMergeDocuments.module.scss';
import ModalMessage from './ModalMessage';

const invokeDownload = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', url);
  document.body.appendChild(link);
  link.click();
  if (link.parentNode) link.parentNode.removeChild(link);
};

const CloseButton = ({ onClick, text = 'Close' }) => (
  <button data-test-id="VV--FI-0QmYa1tLMBVxzw" type="button" className={cn([style.buttons, style.closeButton])} onClick={onClick}>
    {text}
  </button>
);
CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

const LoadingComponent = ({ multipleDocuments, progressText }) => (
  <ModalMessage iconPath={loaderModal} header="Please wait ..." text={`Saving document${multipleDocuments ? 's' : ''} as PDF`}>
    {progressText}
  </ModalMessage>
);
LoadingComponent.propTypes = {
  multipleDocuments: PropTypes.bool.isRequired,
  progressText: PropTypes.string.isRequired,
};

const SuccessComponent = ({ onClose, downloadUrl }) => (
  <ModalMessage iconPath={check} header="Success" text="PDF ready for download">
    <button
      data-test-id="MnMuQ6j7JipZbJObkO2IA"
      type="button"
      className={cn([style.buttons, style.continueButton])}
      onClick={() => invokeDownload(downloadUrl)}
    >
      Download PDF
    </button>
    <CloseButton data-test-id="1PpiPHkwY9O1zh2UHK6KE" onClick={onClose} />
  </ModalMessage>
);
SuccessComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  downloadUrl: PropTypes.string.isRequired,
};

const ErrorComponent = ({ multipleDocuments, errorType, errorFiles, closeModal, forceRetry }) => {
  const { detailsQueryParam, toggleQueryParam } = useDetailsQueryParam();

  const navigateToDocument = (documentId) => {
    if (documentId !== detailsQueryParam) {
      toggleQueryParam(documentId);
    }
    closeModal();
  };

  if (errorType === ErrorTypes.GeneralError) {
    return (
      <ModalMessage iconPath={cry} header="That didn't go well" text={`Unable to create PDF from the selected item${multipleDocuments ? 's.' : '.'}`}>
        <div className={style.errorMessage}>
          <p>
            An unknown error occurred while processing the selected file
            {multipleDocuments ? 's.' : '.'}
          </p>
        </div>
        <CloseButton data-test-id="TkbQoGwso_0SjBrkLaA6q" onClick={closeModal} />
      </ModalMessage>
    );
  }

  if (errorType === ErrorTypes.NoSupportedFiles) {
    return (
      <ModalMessage iconPath={cry} header="That didn't go well" text={`Unable to create PDF from the selected item${multipleDocuments ? 's.' : '.'}`}>
        <div className={style.errorMessage}>
          <p>
            No supported file formats were found in the selected item
            {multipleDocuments ? 's.' : '.'}
          </p>
        </div>
        <CloseButton data-test-id="FPx_CMCccwgoe1XegbBvJ" onClick={closeModal} />
      </ModalMessage>
    );
  }

  if (errorType === ErrorTypes.ErrorDocument) {
    return (
      <ModalMessage iconPath={cry} header="That didn't go well" text={`Unable to create PDF from the selected item${multipleDocuments ? 's.' : '.'}`}>
        <div className={style.errorMessage}>
          <span data-test-id="mvT8fYZxANehwYSo26CgS" onClick={() => navigateToDocument(errorFiles.at(0))} className={style.documentLink}>
            Click here to view the document that caused the issue.
          </span>
        </div>
        <CloseButton data-test-id="QILgB7ECx2n53Mi15fZ6T" onClick={closeModal} />
      </ModalMessage>
    );
  }

  if (errorType === ErrorTypes.UnsupportedFiles) {
    return (
      <ModalMessage iconPath={cry} header={`Unable to create PDF from the selected item${multipleDocuments ? 's.' : '.'}`}>
        <div>
          <p>
            Some of the files or attachments in the selected item
            {multipleDocuments ? 's' : ''} are not supported when downloading as PDF. You may only create a PDF without the following files:
          </p>
          {errorFiles.map((file) => (
            <p key={file} className={style.errorFile}>
              {file}
            </p>
          ))}
        </div>
        <button data-test-id="15bQ_vxRjiBTOkveUMK11" type="button" className={cn([style.buttons, style.continueButton])} onClick={forceRetry}>
          Exclude files and continue
        </button>
        <CloseButton data-test-id="mTSvMfnQtX3Vg3suMsPxr" onClick={closeModal} text="Cancel" />
      </ModalMessage>
    );
  }

  return (
    <ModalMessage iconPath={cry} header="That didn't go well" text={`Unable to create PDF from the selected item${multipleDocuments ? 's.' : '.'}`}>
      <div className={style.errorMessage}>
        <p>An unknown error occurred while processing the files.</p>
      </div>
      <CloseButton data-test-id="iV1zhKHoYA1l3mkRD3exO" onClick={closeModal} />
    </ModalMessage>
  );
};
ErrorComponent.propTypes = {
  multipleDocuments: PropTypes.bool,
  errorType: PropTypes.string,
  errorFiles: PropTypes.arrayOf(PropTypes.string),
  closeModal: PropTypes.func.isRequired,
  forceRetry: PropTypes.func.isRequired,
};

const ModalMergeDocuments = ({ closeModal }) => {
  const globalDispatch = useDispatch();
  const { selectedDocumentsIds } = useSelector((state) => state.searchResult);
  const [ignoreUnsupportedFiles, setIgnoreUnsupportedFiles] = useState(false);
  const { mergeDocuments, status, downloadUrl, errorType, errorDocument, progress } = useMergeDocuments();

  const onClose = () => {
    globalDispatch(unselectAllDocuments);
    closeModal();
  };

  const forceRetry = () => {
    setIgnoreUnsupportedFiles(true);
  };

  useEffect(() => {
    mergeDocuments(selectedDocumentsIds, ignoreUnsupportedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoreUnsupportedFiles]);

  const multipleDocuments = selectedDocumentsIds && selectedDocumentsIds.length > 1;

  const progressText = progress === 0 ? 'Verifying documents' : `Processing ${progress} / ${selectedDocumentsIds.length} documents`;

  switch (status) {
    case XHR_STATUS.LOADING:
      return <LoadingComponent multipleDocuments={multipleDocuments} progressText={progressText} />;
    case XHR_STATUS.SUCCESS:
      return <SuccessComponent onClose={onClose} downloadUrl={downloadUrl} />;
    case XHR_STATUS.ERROR:
      return (
        <ErrorComponent
          multipleDocuments={multipleDocuments}
          errorType={errorType}
          errorFiles={errorDocument}
          closeModal={closeModal}
          forceRetry={forceRetry}
        />
      );
    default:
      return <ErrorComponent closeModal={closeModal} />;
  }
};

ModalMergeDocuments.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalMergeDocuments;
