import React, { useCallback, useContext, useReducer, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { actions, reducer } from './reducer';

const initialState = {
  selectedForDeletion: {},
};

const DeleteDocumentSelectionContext = React.createContext(initialState);

export const DeleteDocumentSelectionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { currentView, selectedDocumentsIds, selectedContexts } = useSelector((s) => s.searchResult);

  const selectedDocuments = currentView.filter((doc) => selectedDocumentsIds.includes(doc.id));

  const toggleForDeletionSelection = useCallback(
    ({ documentId, contextId }) =>
      dispatch({
        action: actions.TOGGLE_FOR_DELETION_SELECTION,
        payload: { documentId, contextId, selectedContexts },
      }),
    [selectedContexts]
  );
  const selectAllContextsForDeletion = useCallback(
    ({ documentId }) =>
      dispatch({
        action: actions.SELECT_ALL_CONTEXTS_FOR_DELETION,
        payload: { documentId, selectedDocuments },
      }),
    [selectedDocuments]
  );
  const unselectAllContextsForDeletion = useCallback(
    ({ documentId }) =>
      dispatch({
        action: actions.UNSELECT_ALL_CONTEXTS_FOR_DELETION,
        payload: { documentId },
      }),
    []
  );
  const selectAllDocumentsForDeletion = useCallback(
    () =>
      dispatch({
        action: actions.SELECT_ALL_DOCUMENTS_FOR_DELETION,
        payload: { selectedDocuments },
      }),
    [selectedDocuments]
  );
  const unselectAllDocumentsForDeletion = useCallback(
    () =>
      dispatch({
        action: actions.UNSELECT_ALL_DOCUMENTS_FOR_DELETION,
      }),
    []
  );

  const hasSelectedDocuments = state.selectedForDeletion && Object.keys(state.selectedForDeletion).length > 0;

  const values = useMemo(
    () => ({
      toggleForDeletionSelection,
      selectAllContextsForDeletion,
      unselectAllContextsForDeletion,
      selectAllDocumentsForDeletion,
      unselectAllDocumentsForDeletion,
      hasSelectedDocuments,
      selectedForDeletion: state.selectedForDeletion,
    }),
    [
      toggleForDeletionSelection,
      selectAllContextsForDeletion,
      unselectAllContextsForDeletion,
      selectAllDocumentsForDeletion,
      unselectAllDocumentsForDeletion,
      hasSelectedDocuments,
      state,
    ]
  );

  return <DeleteDocumentSelectionContext.Provider value={values}>{children}</DeleteDocumentSelectionContext.Provider>;
};

DeleteDocumentSelectionProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export const useDeleteDocumentSelection = () => useContext(DeleteDocumentSelectionContext);
