import React from 'react';
import { SearchContextType } from '../../containers/queryParameters';
import { ClaimSearchHelper, CoverSearchHelper, ReinsuranceSearchHelper } from '../Search/SearchHelp';
import styles from './CaseSearch.module.scss';
import ClaimResult from './ClaimResult';
import CoverResult from './CoverResult';
import ReinsuranceResult from './ReinsuranceResult';

export const renderSearchHelper = (caseContext) => {
  switch (caseContext) {
    case SearchContextType.CLAIM:
      return <ClaimSearchHelper className={styles.helpText} />;
    case SearchContextType.COVER:
      return <CoverSearchHelper className={styles.helpText} />;
    case SearchContextType.REINSURANCE:
      return <ReinsuranceSearchHelper className={styles.helpText} />;
    default:
      return <div>Unsupported case type</div>;
  }
};

export const renderCaseResult = (caseContext, item) => {
  switch (caseContext) {
    case SearchContextType.CLAIM:
      return <ClaimResult key={item.id} item={item} />;
    case SearchContextType.COVER:
      return <CoverResult key={item.id} item={item} />;
    case SearchContextType.REINSURANCE:
      return <ReinsuranceResult key={item.id} item={item} />;
    default:
      return <div>Unsupported case type</div>;
  }
};
