import { mdiFilter } from '@mdi/js';
import '@reach/dialog/styles.css';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IconButton } from '.';
import { ICON_SIZE } from '../constVariables';
import CloseModalButton from './CloseModalButton';
import ItsDialog from './ItsDialog';
import styles from './SearchGrid.module.scss';
import PageSpinner from './Spinner';

const SearchGrid = ({ leftActions, left, middleActions, middle, middleDisabled, rightActions, right, hasDetails }) => {
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <>
      <div className={cn(styles.sidebarActions)}>
        <IconButton data-test-id="4B61AzE1yR_lSQsagdUig" text="Show filters" icon={mdiFilter} iconSize={ICON_SIZE} onClick={open} />
        {leftActions}
      </div>

      <div className={cn(styles.grid, { [styles.hasDetails]: hasDetails })}>
        {/* ACTION ROW */}
        <div className={cn(styles.leftActions)}>{leftActions}</div>

        <fieldset className={cn(styles.middleActions)} disabled={middleDisabled}>
          {middleActions}
        </fieldset>

        <div className={cn(styles.rightActions)}>{rightActions}</div>
        {/* ACTION ROW */}

        {/* CONTENT ROW */}
        <div className={cn(styles.left)}>{left}</div>

        <div className={cn(styles.middle)}>
          {middleDisabled && <PageSpinner className={styles.spinner} />}
          <fieldset disabled={middleDisabled}>{middle}</fieldset>
        </div>

        <div className={cn(styles.right)}>{right}</div>
        {/* CONTENT ROW */}
      </div>

      <ItsDialog isOpen={showDialog} onDismiss={close} className={styles.sidebar} aria-label="Filter menu">
        <CloseModalButton close={close} />

        {left}
      </ItsDialog>
    </>
  );
};

SearchGrid.propTypes = {
  leftActions: PropTypes.node,
  left: PropTypes.node,
  middleActions: PropTypes.node,
  middle: PropTypes.node,
  middleDisabled: PropTypes.bool,
  hasDetails: PropTypes.string,
  rightActions: PropTypes.node,
  right: PropTypes.node,
};

export default SearchGrid;
