import PropTypes from 'prop-types';

export const remoteDataShape = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  is: PropTypes.func,
  '@@values': PropTypes.array.isRequired,
};

export const sortByItemsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
  })
).isRequired;

export const userPropType = PropTypes.shape({
  name: PropTypes.string,
  address: PropTypes.string,
});

const datePeriodShape = PropTypes.shape({
  from: PropTypes.string,
  to: PropTypes.string,
});

const interestShape = PropTypes.shape({ shortName: PropTypes.string });

export const claimContextShape = PropTypes.shape({
  vesselName: PropTypes.string,
  interest: interestShape,
  date: PropTypes.string,
  businessAccount: PropTypes.string,
  description: PropTypes.string,
  contractPeriod: datePeriodShape,
  insurancePeriod: datePeriodShape,
  clientReference: PropTypes.string,
  xChanging: PropTypes.string,
});

export const coverContextShape = PropTypes.shape({
  $type: PropTypes.string,
  id: PropTypes.string,
  businessAccount: PropTypes.string,
  businessAccountId: PropTypes.string,
  insuranceYear: PropTypes.number,
  riskNumber: PropTypes.number,
  policyNumber: PropTypes.number,
  coverNote: PropTypes.number,
  interest: interestShape,
  fleetName: PropTypes.string,
  insurancePeriod: datePeriodShape,
});

export const documentResultShape = PropTypes.oneOfType([
  PropTypes.shape({
    id: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    contexts: PropTypes.array,
    taxonomy: PropTypes.shape(
      PropTypes.shape({
        documentType: PropTypes.string,
        documentTags: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    // eslint-disable-next-line react/forbid-prop-types
    files: PropTypes.array.isRequired,
    sender: userPropType,
    recipients: PropTypes.arrayOf(userPropType),
    otherRecipients: PropTypes.arrayOf(userPropType),
    isPlainText: PropTypes.bool,
  }),
  PropTypes.instanceOf(Object),
]);

export const reactRouterMatch = PropTypes.oneOfType([
  PropTypes.shape({
    params: PropTypes.shape({ documentId: PropTypes.string }),
  }),
  PropTypes.instanceOf(Object),
]);

export const childrenShape = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]);

export const nameEmailShape = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
};

export const claimSearchResultShape = {
  id: PropTypes.string,
  claimNumber: PropTypes.string,
  claimsHandler: PropTypes.string,
  client: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  fleetName: PropTypes.string,
  imoNumber: PropTypes.string,
  insuranceYear: PropTypes.number,
  interest: PropTypes.string,
  peti: PropTypes.string,
  score: PropTypes.number,
  share: PropTypes.number,
  status: PropTypes.string,
  vesselName: PropTypes.string,
};

export const coverSearchResultShape = {
  id: PropTypes.string,
  score: PropTypes.number,
  interest: PropTypes.string,
  insuranceYear: PropTypes.number,
  client: PropTypes.string,
  fleetName: PropTypes.string,
  section: PropTypes.string,
  riskNumber: PropTypes.string,
  coverNoteNumber: PropTypes.string,
  insurancePeriod: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
};

export const reinsuranceSearchResultShape = {
  id: PropTypes.string,
  insuranceYear: PropTypes.number,
  companyName: PropTypes.string,
  companyNumber: PropTypes.number,
  businessAccount: PropTypes.string,
  businessAccountNumber: PropTypes.number,
};
