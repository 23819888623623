import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'ramda';
import MdiIcon from '@mdi/react';
import { mdiArrowRight, mdiAccountOutline } from '@mdi/js';
import { ICON_SIZE, recipientType } from '../constVariables';
import s from './Details.module.scss';
import { nameEmailShape } from '../../lib/customProptypes';
import concatRecipients from './helpers';
import NameAndEmail from './NameAndEmail';
import Recipients from './Recipients';

const defaultPerson = {
  name: '',
  addess: '',
};

const firstToRecipient = (recipients) => find((recipient) => recipient.recipientType === recipientType.To, recipients) || defaultPerson;

const MainRecipientAndTooltip = ({ recipients }) => (
  <>
    <NameAndEmail person={firstToRecipient(recipients)} className={s.nameAndEmail} />
    <Recipients recipients={recipients} />
  </>
);

MainRecipientAndTooltip.propTypes = {
  recipients: PropTypes.arrayOf(PropTypes.shape(nameEmailShape)).isRequired,
};

const MainRecipientOrTooltip = ({ recipients }) =>
  recipients.length < 2 ? <NameAndEmail person={firstToRecipient(recipients)} className={s.nameAndEmail} /> : <Recipients recipients={recipients} />;

MainRecipientOrTooltip.propTypes = {
  recipients: PropTypes.arrayOf(PropTypes.shape(nameEmailShape)).isRequired,
};

const SenderRecipientContent = ({ sender, recipients = [], otherRecipients = [], showAll }) => {
  const mappedRecipients = concatRecipients(recipients, otherRecipients);
  const showMainRecipientAndTooltip = showAll;

  return (
    <div className={s.senderRecipientRow}>
      <MdiIcon path={mdiAccountOutline} size={ICON_SIZE} />
      <NameAndEmail person={sender} className={s.nameAndEmail} />
      <MdiIcon path={mdiArrowRight} size={ICON_SIZE} />
      {showMainRecipientAndTooltip ? (
        <MainRecipientAndTooltip recipients={mappedRecipients} />
      ) : (
        <MainRecipientOrTooltip recipients={mappedRecipients} />
      )}
    </div>
  );
};

SenderRecipientContent.propTypes = {
  sender: PropTypes.shape(nameEmailShape).isRequired,
  recipients: PropTypes.arrayOf(PropTypes.shape(nameEmailShape)),
  otherRecipients: PropTypes.arrayOf(PropTypes.shape(nameEmailShape)),
  showAll: PropTypes.bool.isRequired,
};

export default SenderRecipientContent;
