import cn from 'classnames';
import React from 'react';
import loader from '../Icons/loader.svg';
import style from './Spinner.module.scss';

type ClassName = string | number | boolean | null;

const PageSpinner = ({ className }: { className?: ClassName }) => <Spinner className={cn(style.pageLoader, className)} />;

export default PageSpinner;

// eslint-disable-next-line react/prop-types
export const Spinner = ({ className }: { className?: ClassName }) => (
  <div className={cn(style.loader, className)}>
    <img src={loader} alt="Loading..." />
  </div>
);
