export const GET_COVER_INFO = 'getCoverInfo';
export const GET_COVER_INFO_SUCCESS = 'getCoverInfoSuccess';
export const GET_COVER_INFO_ERROR = 'getCoverInfoError';
export const SET_CONTEXTS_IN_SEARCH = 'setContextsInSearch';
export const HIDE_SEARCH = 'hideSearch';
export const CLOSE_MODAL = 'closeModal';
export const OPEN_MODAL = 'openModal';
export const SHOW_SEARCH = 'showSearch';
export const SET_SELECTED_CONTEXTS = 'setSelectedContexts';
export const SET_SELECTED_CLAIM_CONTEXTS_IN_SEARCH = 'setSelectedClaimContextsInSearch';
export const SET_SELECTED_REINSURANCE_CONTEXTS_IN_SEARCH = 'setSelectedReinsuranceContextsInSearch';
export const SET_SELECTED_COVER_CONTEXTS_IN_SEARCH = 'setSelectedCoverContextsInSearch';
export const SET_SELECTED_CONTEXTS_GROUPS = 'setSelectedContextsGroups';
export const SET_SEARCH_TEXT = 'setSearchText';
export const TOGGE_VERTICAL_LISTING = 'toggeVerticalListing';
export const NAVIGATE = 'navigate';
export const UNSELECT_REINSURANCE_CONTEXT = 'unselectReinsuranceContext';
