import React from 'react';
import cn from 'classnames';
import { useQueryParam } from 'use-query-params';
import { Header } from '..';
import { KnownQueryParams, SearchContextType } from '../../containers/queryParameters';
import CaseSearchMobile from '../CaseSearch/CaseSearchMobile';
import DocumentSearchMobile from '../DocumentSearch/DocumentSearchMobile';
import s from './App.module.scss';
import Main from '../Shared/Main';

const Mobile = () => {
  const [searchContext] = useQueryParam(KnownQueryParams.CONTEXT);

  const isDocumentContext = searchContext === SearchContextType.ALL;

  return (
    <>
      <Header />
      <Main>
        <div className={s.searchView}>{isDocumentContext ? <DocumentSearchMobile /> : <CaseSearchMobile />}</div>
      </Main>
    </>
  );
};

export default Mobile;
