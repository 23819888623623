import { Formik, Form, useFormikContext, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import * as yup from 'yup';
import client from '../../apis/client';
import { getRoleByContextType, mapRoleToSearchContext, SearchContextType } from '../../containers/queryParameters';
import { useClassifications, useDocumentTypes, DocumentType, Classification, DocumentTag } from '../../hooks/documents';
import useDialog from '../../hooks/useDialog';
import { useIsMobile } from '../../hooks/useIsMobile';
import XHR_STATUS from '../../lib/xhrStatus';
import PiiSelector from '../CaseDocumentSearchPage/uploadFilesForm/PiiSelector';
import Header from '../Header';
import InsuranceContextsSelector from '../InsuranceContextsSelector';
import { Main, PageSpinner } from '../Shared';
import ItsDialog, { DialogHeader } from '../Shared/ItsDialog';
import {
  ClassificationSelector,
  DocumentTypeSelector,
  KeywordsSelector,
  NotesInput,
  mapClassificationToVM,
  mapDocumentTypeToVM,
} from '../Shared/upsertDocumentHelpers';
import * as s from '../../styles/variables';
import { Roles, isValidRole } from '../../lib/roles';

const Content = styled.div`
  max-width: 1300px;
  padding-bottom: 40px;
  margin: 0 auto;
  width: 100%;
`;

const Title = styled.div`
  padding-top: 40px;
  padding-bottom: 8px;
  font-size: 28px;
  color: ${s.marineBlue};
  text-align: left;

  @media (max-width: 1023px) {
    padding: 8px 0 0 8px;
  }
`;

const ArchiveButtonWrap = styled.div<{ isDisabled: boolean }>`
  display: flex;
  justify-content: flex-end;
  padding: 0.57rem 0.42rem 0.57rem 0.86rem;

  button {
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    padding: 8px 22px;
    background-color: ${(props) => (props.isDisabled ? s.lightGrey : s.marineBlue)};
    border-radius: 4px;
    font-weight: bold;
    color: #ffffff;
  }
`;

const StyledForm = styled(Form)`
  background-color: #ffffff;
`;

const InsuranceContextsSelectorWrap = styled.div<{ isValid: boolean }>`
  border: 1px solid transparent;
  border-color: ${(props) => (props.isValid ? 'transparent' : '#f5866c')};
  height: 600px;
`;

const StyledPageSpinner = styled(PageSpinner)`
  img {
    margin-top: 0;
  }
`;

const defaultValidationShape = {
  classification: yup.string().required('Select a classification'),
  documentType: yup.string().required('Select a document type'),
  insuranceContexts: yup.array().min(1, 'Select at least one context').ensure(),
};

const defailtValidationSchema = yup.object().shape(defaultValidationShape);

type Role = { role: string; displayName: string };

interface FormProms {
  isMobile: boolean;
  classifications: Classification[];
  documentTypes: DocumentType[];
  updateValidationSchema: (showPii: boolean) => void;
  userRole: Role;
}
const FormikForm = ({ isMobile, classifications, documentTypes, updateValidationSchema, userRole }: FormProms) => {
  const formikContext = useFormikContext<FormValues>();
  const [isSearchMode, setIsSearchMode] = useState(false);
  const classificationVMs = classifications
    .map((x) => mapClassificationToVM(x, formikContext.values.classification))
    .sort((a, b) => a.priority - b.priority);
  const documentTypeVMs = documentTypes
    .filter((d) => !d.disabled)
    .map((d) => mapDocumentTypeToVM(d, formikContext.values.documentType))
    .sort((a, b) => a.displayName.localeCompare(b.displayName));
  const documentType = documentTypes.find((d) => d.name === formikContext.values.documentType);
  const setSelectedContexts = (selectedContexts: any[]) => {
    // Skip initial (first) call of setSelectedContexts function because preSelectedContexts = []
    if (!formikContext.dirty && selectedContexts.length === 0) return;
    formikContext.setFieldValue('insuranceContexts', selectedContexts);
  };

  // Disable archive button if form is not valid
  const disableArchive = isSearchMode || formikContext.isSubmitting || (!formikContext.isInitialValid && !formikContext.dirty);

  return (
    <StyledForm>
      <DocumentTypeSelector fixedSize={!isMobile} documentTypeVMs={documentTypeVMs} />
      <ClassificationSelector classificationVMs={classificationVMs} />
      <PiiSelector updateValidationSchema={updateValidationSchema} />
      <NotesInput />
      <KeywordsSelector tags={documentType ? documentType.tags : []} />
      <InsuranceContextsSelectorWrap isValid={formikContext.errors?.insuranceContexts === undefined}>
        <InsuranceContextsSelector
          setSelectedInsuranceContexts={setSelectedContexts}
          setIsSearchMode={setIsSearchMode}
          activeRole={userRole}
          infoText="The document(s) will be archived under the selected context(s):"
        />
      </InsuranceContextsSelectorWrap>
      <ArchiveButtonWrap isDisabled={disableArchive}>
        <button type="submit" disabled={disableArchive}>
          Archive
        </button>
      </ArchiveButtonWrap>
    </StyledForm>
  );
};

const renderDialogContent = (xhrStatus: number, error: string) => {
  switch (xhrStatus) {
    case XHR_STATUS.ERROR:
      return <p>Error. {error}</p>;
    case XHR_STATUS.SUCCESS:
      return <p>Document created. Close the browser tab and return to DocFlow.</p>;
    default:
      return <StyledPageSpinner />;
  }
};

type User = {
  name: string;
  email: string;
  roles: {
    name: string;
  }[];
};

interface State {
  user: User;
}

type Keyword = DocumentTag & { value: string };

interface FormValues {
  classification: string;
  documentId: string;
  documentType: string;
  notes: string;
  keywords: Keyword[];
  piiSection: string;
  insuranceContexts: any[];
}
const MetadataSelector = () => {
  const [xhrStatus, setXhrStatus] = useState(XHR_STATUS.INITIAL);
  const [error, setError] = useState('');
  const { isShowing: isShowingDialog, show: showDialog, hide: hideDialog } = useDialog();
  const { documentId } = useParams<{ documentId: string }>();
  const isMobile = useIsMobile();
  const initialValues: FormValues = {
    classification: 'Client Info',
    documentId,
    documentType: '',
    notes: '',
    keywords: [],
    piiSection: '',
    insuranceContexts: [],
  };
  const user = useSelector<State, User>((state) => state.user);
  const searchRole = getRoleByContextType(user.roles.length > 0 ? mapRoleToSearchContext(user.roles) : SearchContextType.COVER);
  const role = user.roles.find((uRole) => isValidRole(uRole.name)) ?? Roles.UNDERWRITER;
  const { classifications, isLoading: isClassificationsLoading } = useClassifications();
  const { documentTypes, isLoading: isDocumentTypesLoading } = useDocumentTypes(role.name);

  // eslint-disable-next-line no-unused-vars
  const [validationSchema, setValidationSchema] = useState(defailtValidationSchema);
  const updateValidationSchema = useCallback((showPii: boolean) => {
    setValidationSchema(
      showPii
        ? yup.object().shape({
            ...defaultValidationShape,
            piiSection: yup.string().required('Select a section'),
          })
        : defailtValidationSchema
    );
  }, []);

  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    setXhrStatus(XHR_STATUS.LOADING);
    showDialog();
    const payload = {
      notes: values.notes,
      taxonomy: {
        applicationRoleName: user.roles[0].name,
        documentType: values.documentType,
        documentTags: values.keywords.map((x) => x.value),
      },
      piiSection: values.piiSection,
      documentId: values.documentId,
      classification: values.classification,
      insuranceContexts: values.insuranceContexts,
    };

    try {
      await client.post('document', payload);
      setXhrStatus(XHR_STATUS.SUCCESS);
    } catch (err: any) {
      setError(err.response.data);
      setXhrStatus(XHR_STATUS.ERROR);
    }
  };

  if (!searchRole || isClassificationsLoading || isDocumentTypesLoading) return <PageSpinner className={undefined} />;
  return (
    <>
      <Header />
      <Main>
        <Content>
          <Title>Metadata selector</Title>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            <FormikForm
              isMobile={isMobile}
              classifications={classifications!}
              documentTypes={documentTypes!}
              updateValidationSchema={updateValidationSchema}
              userRole={searchRole}
            />
          </Formik>
        </Content>
      </Main>
      <ItsDialog isOpen={isShowingDialog} onDismiss={hideDialog} aria-label="Archive Document" className={null}>
        <>
          <DialogHeader title="Archive document" close={hideDialog} />
          {renderDialogContent(xhrStatus, error)}
        </>
      </ItsDialog>
    </>
  );
};

export default MetadataSelector;
