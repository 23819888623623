import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { EmptyResult } from '..';
import { fetchNextCasePage } from '../../containers/CaseSearch';
import { caseFilterChanged } from '../../containers/Filters/actions';
import { KnownQueryParams, SearchContextType } from '../../containers/queryParameters';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import RemoteDataComponent from '../../lib/RemoteData';
import RootSearchBar from '../App/RootSearchBar';
import MobileFilterControls from '../Filters/MobileFilterControls';
import { InfiniteScrollContainer, PageSpinner } from '../Shared';
import CaseFilters from './CaseFilters';
import styles from './CaseSearch.module.scss';
import { renderCaseResult, renderSearchHelper } from './helpers';

const CaseSearchSuccessMobile = ({ caseContext }) => {
  const dispatch = useDispatch();
  const {
    caseSearch: { caseResults, remoteNextPageDataState },
  } = useSelector(({ caseSearch }) => ({ caseSearch }));
  const { results: cases, hasMoreItems } = caseResults;

  const [queryParams, setQueryParams] = useQueryParams({
    [KnownQueryParams.SEARCH_TEXT]: StringParam,
    [KnownQueryParams.PAGE]: NumberParam,
  });

  const fetchNextPage = useCallback(() => {
    setQueryParams(
      {
        [KnownQueryParams.PAGE]: (queryParams[KnownQueryParams.PAGE] || 1) + 1,
      },
      'replaceIn'
    );
    dispatch(fetchNextCasePage());
  }, [dispatch, queryParams]);
  const { scrollToTop } = useInfiniteScroll(hasMoreItems, fetchNextPage, true);

  const hasNoItems = cases.length === 0;

  return hasNoItems ? (
    <EmptyResult searchText={queryParams[KnownQueryParams.SEARCH_TEXT]} />
  ) : (
    <>
      <h1 className={styles.header}>Select a case to continue</h1>
      <InfiniteScrollContainer
        items={cases}
        remoteDataState={remoteNextPageDataState}
        hasMoreItems={hasMoreItems}
        fetchNextSearchResult={fetchNextPage}
        scrollToTop={scrollToTop}
      >
        {cases.map((item) => renderCaseResult(caseContext, item))}
      </InfiniteScrollContainer>
    </>
  );
};

CaseSearchSuccessMobile.propTypes = {
  caseContext: PropTypes.oneOf([SearchContextType.CLAIM, SearchContextType.COVER]),
};

const CaseSearchMobile = () => {
  const dispatch = useDispatch();
  const {
    caseSearch: { remoteDataState },
    filters: { facets },
  } = useSelector(({ caseSearch, filters }) => ({ caseSearch, filters }));
  const [searchContext] = useQueryParam(KnownQueryParams.CONTEXT);

  const clearAllFilters = () => {
    dispatch(caseFilterChanged());
  };

  const hasFilters = remoteDataState.isSuccess() && facets.length > 0;
  return (
    <>
      <RootSearchBar />
      {hasFilters && (
        <MobileFilterControls onClearFilters={clearAllFilters}>
          <CaseFilters />
        </MobileFilterControls>
      )}

      <RemoteDataComponent
        type={remoteDataState}
        notAsked={() => renderSearchHelper(searchContext)}
        loading={() => <PageSpinner />}
        failure={(_) => <h1>Search failed</h1>}
        success={(_) => <CaseSearchSuccessMobile caseContext={searchContext} />}
      />
    </>
  );
};

export default CaseSearchMobile;
