const validateOneParamFunc = (isRequired, props, propName, componentName) => {
  if (isRequired) {
    const isOneParamFunc = typeof props[propName] === 'function' && props[propName].length === 1;

    if (!isOneParamFunc) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Must be a function with one parameter.`);
    }
  }
  return null;
};

const createCustomPropType = (propTypeValidator) => {
  const customPropType = propTypeValidator.bind(null, false);
  customPropType.isRequired = propTypeValidator.bind(null, true);

  return customPropType;
};

export default {
  oneParamFunc: createCustomPropType(validateOneParamFunc),
};
