import { mdiInformationOutline } from '@mdi/js';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { bool, array, func } from 'prop-types';
import { SearchContextType } from '../../../containers/queryParameters';
import { SMALL_ICON_SIZE } from '../../constVariables';
import { IconButton } from '../../Shared';
import ContextTable, { ContextRow } from './ContextTable';
import { FeatureEnum, useFeatureFlag } from '../../../providers/FeatureFlagProvider';

const ClaimContexts = ({ contexts, showAll, navigateToContext, showDetails }) => {
  const linkToCoverNoteDocsIsEnabled = useFeatureFlag(FeatureEnum.LINKTOCOVERNOTEDOCS);
  const headers = (
    <tr>
      <th>Vessel</th>
      {showAll && <th>Claim number</th>}
      <th>Interest</th>
      {linkToCoverNoteDocsIsEnabled && <th>Cover Note</th>}
      {showAll && <th>Assured</th>}
      <th />
    </tr>
  );

  const rows = contexts.map((context) => {
    const { interest: { shortName } = {}, claimNumber, vesselName, businessAccount, insuranceYear, coverNote } = context;
    const riskNumber = context.riskNumber ? context.riskNumber : 0;
    const pathnameClaim = `/cases/${SearchContextType.CLAIM}/${claimNumber}/${insuranceYear}`;
    const pathnameCover = `/cases/${SearchContextType.COVER}/${riskNumber}/${coverNote}/${insuranceYear}`;

    const onClaimClick = () => {
      navigateToContext(pathnameClaim);
    };
    const onCoverNoteClick = () => {
      navigateToContext(pathnameCover);
    };

    return (
      <ContextRow key={uuid()}>
        <td data-test-id="Y0xlsyNvDkOD7bfieoSux" onClick={onClaimClick}>
          {vesselName}
        </td>
        {showAll && (
          <td data-test-id="fsvRlY0JF_3SyiY9VOpuL" onClick={onClaimClick}>
            {claimNumber}
          </td>
        )}
        <td data-test-id="ImfRuZ0CckYtcxCaxA2kf" onClick={onClaimClick}>
          {shortName}
        </td>
        {linkToCoverNoteDocsIsEnabled && (
          <td data-test-id="yvPrbrI1zCcd0Br5E22zY" onClick={coverNote !== 0 ? onCoverNoteClick : null}>
            {coverNote}
          </td>
        )}
        {showAll && <td>{businessAccount}</td>}
        <td>
          <IconButton
            data-test-id="HRAurl9RErf_JE7YwoHWD"
            icon={mdiInformationOutline}
            iconSize={SMALL_ICON_SIZE}
            typeStyle="primary"
            aria-label="Open context details"
            onClick={(event) => showDetails(event, context)}
          />
        </td>
      </ContextRow>
    );
  });

  return <ContextTable headers={headers} rows={rows} />;
};

ClaimContexts.propTypes = {
  showAll: bool,
  contexts: array.isRequired,
  navigateToContext: func.isRequired,
  showDetails: func.isRequired,
};

export default ClaimContexts;
