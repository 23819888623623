import React from 'react';
import PropTypes from 'prop-types';
import { mdiVectorCombine } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { ICON_SIZE } from '../constVariables';
import { FileDownloadButton, FileViewer, EmailViewer } from '../Shared';
import { documentResultShape } from '../../lib/customProptypes';
import style from './Details.module.scss';
import DetailsStatus from './DetailsStatus';
import documentIcon from '../Icons/document.svg';

const isEmailFile = (x) => x.name.endsWith('.html') || x.name.endsWith('.txt');

const DetailsBottomContent = ({ isEmail, item }) => {
  const files = isEmail ? item.files.filter((f) => f.isAttachment) : item.files;
  const email = isEmail ? item.files.find((x) => !x.isAttachment && isEmailFile(x)) : null;

  const hasNoFiles = item?.taxonomy?.documentTags?.includes('DocFlow') || false;
  return (
    <div className={style.bottomContainer}>
      {!hasNoFiles && (
        <div className={style.attachments}>
          <MdiIcon path={mdiVectorCombine} size={ICON_SIZE} />
          <div className={style.files}>
            {files.map((file) => (
              <FileDownloadButton key={file.id} file={file} />
            ))}
          </div>
        </div>
      )}
      <div className={style.line} />
      {isEmail ? <EmailViewer id={email.id} isPlainText={item.isPlainText} /> : !hasNoFiles && <FileViewer file={files[0]} />}
      {hasNoFiles && <DetailsStatus title="No files" subtitle="The document has to be signed first" image={documentIcon} />}
    </div>
  );
};

DetailsBottomContent.propTypes = {
  isEmail: PropTypes.bool.isRequired,
  item: documentResultShape,
};

export default DetailsBottomContent;
