import { add } from 'date-fns';
import { matchPath } from 'react-router-dom';
import { sortOptions } from '../../components/Shared/sortOptions';
import {
  AllDocumentsDateFilters,
  CaseDocumentDateFilter,
  ClaimDateFilter,
  decodeQueryParams,
  KnownQueryParams,
} from '../../containers/queryParameters';
import { caseDocumentPathMatch, reinsuranceDocumentPath } from '../../containers/thunkHelpers';

const defaultPageSize = 25;

const convertFacetsToApiQueryParam = (values) => (Array.isArray(values) ? values.join('|') : values);

export const mapSearchPayload = (queryParams, isDeepLink = false) => {
  const {
    [KnownQueryParams.SEARCH_TEXT]: query,
    [KnownQueryParams.PAGE]: pageQueryParam,
    [KnownQueryParams.SORT_BY]: sortBy,
    [KnownQueryParams.SORT_DIRECTION]: sortDirection,
    // eslint-disable-next-line no-unused-vars
    [KnownQueryParams.CONTEXT]: context, // throw away to remove it from the facets spread
    // eslint-disable-next-line no-unused-vars
    [KnownQueryParams.DETAILS]: details, // throw away to remove it from the facets spread
    [`${AllDocumentsDateFilters}From`]: sortDateFromUtc,
    [`${AllDocumentsDateFilters}To`]: startOfToDateUtc,
    [AllDocumentsDateFilters]: sortDateUtc,
    ...facets
  } = queryParams;

  const currentPage = (isDeepLink ? 1 : pageQueryParam) || 1;
  const pageSize = isDeepLink ? (pageQueryParam || currentPage) * defaultPageSize : defaultPageSize;
  // Datepicker only gives us the start of "to" date, we need the end of it
  const sortDateToUtc = startOfToDateUtc && endOfDayOffsetUTC(startOfToDateUtc);

  const payload = {
    query,
    page: currentPage,
    pageSize,
    sortBy,
    sortDirection,
    ...{
      sortDateFromUtc,
      sortDateToUtc,
      sortDateUtc,
    },
    ...{ includeAllFacets: isDeepLink ? true : undefined },
  };

  Object.entries(facets).forEach(([key, value]) => {
    payload[key] = convertFacetsToApiQueryParam(value);
  });

  return payload;
};

export const mapSearchClaimsPayload = (queryParams, isDeepLink = false) => {
  const {
    [KnownQueryParams.SEARCH_TEXT]: query,
    [KnownQueryParams.PAGE]: pageQueryParam,
    // eslint-disable-next-line no-unused-vars
    [KnownQueryParams.CONTEXT]: context, // throw away to remove it from the facets spread
    // eslint-disable-next-line no-unused-vars
    [KnownQueryParams.DETAILS]: details, // throw away to remove it from the facets spread
    [ClaimDateFilter]: dateOfLoss,
    ...facets
  } = queryParams;

  const currentPage = (isDeepLink ? 1 : pageQueryParam) || 1;
  const pageSize = isDeepLink ? (pageQueryParam || currentPage) * defaultPageSize : defaultPageSize;

  const payload = {
    query,
    page: currentPage,
    pageSize,
    ...{ dateOfLoss },
    ...{ includeAllFacets: isDeepLink ? true : undefined },
  };

  Object.entries(facets).forEach(([key, value]) => {
    payload[`${key}Facets`] = convertFacetsToApiQueryParam(value);
  });

  return payload;
};

/** Used for Covers and Reinsurance */
export const mapSearchCasePayload = (queryParams, isDeepLink = false) => {
  const {
    [KnownQueryParams.SEARCH_TEXT]: query,
    [KnownQueryParams.PAGE]: pageQueryParam,
    // eslint-disable-next-line no-unused-vars
    [KnownQueryParams.CONTEXT]: context, // throw away to remove it from the facets spread
    // eslint-disable-next-line no-unused-vars
    [KnownQueryParams.DETAILS]: details, // throw away to remove it from the facets spread
    ...facets
  } = queryParams;

  const currentPage = (isDeepLink ? 1 : pageQueryParam) || 1;
  const pageSize = isDeepLink ? (pageQueryParam || currentPage) * defaultPageSize : defaultPageSize;

  const payload = {
    query,
    page: currentPage,
    pageSize,
    ...{ includeAllFacets: isDeepLink ? true : undefined },
  };

  Object.entries(facets).forEach(([key, value]) => {
    payload[`${key}Facets`] = convertFacetsToApiQueryParam(value);
  });

  return payload;
};

export const mapCoverDocumentPayload = (location, isDeepLink = false) => {
  const queryParams = decodeQueryParams(location.search);
  const { riskNumber, coverNumber, insuranceYear } = caseDocumentPathMatch(location.pathname).params;

  const {
    [KnownQueryParams.SEARCH_TEXT]: query,
    // eslint-disable-next-line no-unused-vars
    [KnownQueryParams.DETAILS]: details, // throw away to remove it from the facets spread
    ...restQueryParams
  } = queryParams;

  const documentQuery = `risk: ${riskNumber} and cover: ${coverNumber}${query ? ` ${query}` : ''}`;
  return {
    query: documentQuery,
    ContextTypes: 'CoverContext',
    ...mapCaseDocumentPayload(insuranceYear, restQueryParams, isDeepLink),
  };
};

export const mapClaimDocumentPayload = (location, isDeepLink = false) => {
  const queryParams = decodeQueryParams(location.search);
  const { claimNumber, insuranceYear } = caseDocumentPathMatch(location.pathname).params;

  const {
    [KnownQueryParams.SEARCH_TEXT]: query,
    // eslint-disable-next-line no-unused-vars
    [KnownQueryParams.DETAILS]: details, // throw away to remove it from the facets spread
    ...restQueryParams
  } = queryParams;

  const documentQuery = `claim: ${claimNumber}${query ? ` ${query}` : ''}`;
  return {
    query: documentQuery,
    ContextTypes: 'ClaimContext',
    ...mapCaseDocumentPayload(insuranceYear, restQueryParams, isDeepLink),
  };
};

export const mapReinsuranceDocumentPayload = (location, isDeepLink = false) => {
  const queryParams = decodeQueryParams(location.search);
  const { insuranceYear, companyNumber, businessAccountNumber } = matchPath(location.pathname, reinsuranceDocumentPath).params;

  const {
    [KnownQueryParams.SEARCH_TEXT]: query,
    // eslint-disable-next-line no-unused-vars
    [KnownQueryParams.DETAILS]: details, // throw away to remove it from the facets spread
    ...restQueryParams
  } = queryParams;

  const documentQuery = `companyNumber: ${companyNumber} and businessAccountNumber: ${businessAccountNumber}${query ? ` ${query}` : ''}`;
  return {
    query: documentQuery,
    ...mapCaseDocumentPayload(insuranceYear, restQueryParams, isDeepLink),
  };
};

const mapCaseDocumentPayload = (insuranceYear, queryParams, isDeepLink = false) => {
  const {
    // eslint-disable-next-line no-unused-vars
    [KnownQueryParams.DETAILS]: details, // throw away to remove it from the facets spread
    [`${CaseDocumentDateFilter}From`]: sortDateFromUtc,
    [`${CaseDocumentDateFilter}To`]: startOfToDateUtc,
    [CaseDocumentDateFilter]: sortDateUtc,
    ...facets
  } = queryParams;

  const { sortBy, sortDirection } = sortOptions.find((o) => o.name === 'Newest');
  // Datepicker only gives us the start of "to" date, we need the end of it
  const sortDateToUtc = startOfToDateUtc && endOfDayOffsetUTC(startOfToDateUtc);

  const payload = {
    pageSize: 999,
    // If count is not set azure search defaults it to 10.
    // As discussed with Kenneth, we set the count to 200 as this should probably cover what we need.
    facetGroups: 'documentType,count:200|documentTagsFilter,count:200',
    insuranceYear,
    sortBy,
    sortDirection,
    ...{
      sortDateFromUtc,
      sortDateToUtc,
      sortDateUtc,
    },
    ...{ includeAllFacets: isDeepLink ? true : undefined },
  };

  Object.entries(facets).forEach(([key, value]) => {
    payload[key] = convertFacetsToApiQueryParam(value);
  });

  return payload;
};

export const endOfDayOffsetUTC = (date) => {
  const endOfDayUtc = add(new Date(date), { days: 1, seconds: -1 });
  return endOfDayUtc.toISOString();
};
