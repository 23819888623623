import { mdiChevronRight } from '@mdi/js';
import MdiIcon from '@mdi/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SearchContextType } from '../../containers/queryParameters';
import { coverSearchResultShape } from '../../lib/customProptypes';
import { getFormatedPeriod } from '../../lib/helpers';
import { BLUE_ICON_COLOR } from '../constVariables';
import DescriptionItem from '../Shared/DescriptionItem';
import style from './CaseSearch.module.scss';

const CoverResult = ({ item }) => {
  const location = useLocation();
  const riskNumber = item.riskNumber ? item.riskNumber : 0;
  return (
    <Link
      className={style.case}
      to={{
        pathname: `/cases/${SearchContextType.COVER}/${riskNumber}/${item.coverNoteNumber}/${item.insuranceYear}`,
        state: { backUrl: location.pathname + location.search },
      }}
    >
      <div className={style.content}>
        <dl className={style.descriptionList}>
          <DescriptionItem term="Interest code" detail={item.interest} />
          <DescriptionItem term="Risk number" detail={item.riskNumber} />
          <DescriptionItem term="Cover note" detail={item.coverNoteNumber} />
          <DescriptionItem term="Our share" detail={item.share ? `${Number(item.share).toFixed(2)}%` : null} />
          <DescriptionItem term="Fleet name" detail={item.fleetName} />
          <DescriptionItem term="Client" detail={item.client} />
          <DescriptionItem term="Risk status" detail={item.status} />
          <DescriptionItem term="Insurance period" detail={getFormatedPeriod(item.insurancePeriod)} />
        </dl>
      </div>

      <div className={style.caseIcon}>
        <MdiIcon path={mdiChevronRight} color={BLUE_ICON_COLOR} />
      </div>
    </Link>
  );
};

CoverResult.propTypes = {
  item: PropTypes.shape(coverSearchResultShape),
};

export default CoverResult;
