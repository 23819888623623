import { createReducer } from '../../lib';
import { getUpdatedFacets } from '../../lib/facets';
import { RESET_SEARCH } from '../Search/constants';
import * as a from './constants';

const initialState = {
  facets: [],
};

const filtersReducer = createReducer(initialState)({
  [a.SET_FILTERS]: (state, { payload: facets }) => ({
    ...state,
    facets,
  }),

  [a.UPDATE_FILTERS]: (state, { payload: facets }) => {
    const updatedFacets = getUpdatedFacets(state.facets, facets);
    return {
      ...state,
      facets: updatedFacets,
    };
  },

  [a.TOGGLE_EXPANDED_FACETS]: (state, { payload: facetGroupLabel }) => {
    const newFilters = state.facets.map((facetGroup) =>
      facetGroup.label === facetGroupLabel ? { ...facetGroup, expanded: !facetGroup.expanded } : facetGroup
    );
    return {
      ...state,
      facets: newFilters,
    };
  },

  [RESET_SEARCH]: (_, __) => ({
    ...initialState,
  }),
});

export default filtersReducer;
