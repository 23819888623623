import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import mapContext from '../../../apis/mappers/mapContext';
import { getRoleByContextType } from '../../../containers/queryParameters';
import { claimContextShape, coverContextShape } from '../../../lib/customProptypes';
import InsuranceContextsSelector from '../../InsuranceContextsSelector';
import FormErrorMessage from '../../Shared/FormErrorMessage';
import styles from './upload-files-form.module.scss';

const ContextStep = ({ currentContext, setSearchMode }) => {
  const { caseType } = useParams();
  const activeRole = getRoleByContextType(caseType);
  const fieldname = 'insuranceContexts';
  // eslint-disable-next-line no-unused-vars
  const [_, __, helpers] = useField({ name: fieldname });
  const preSelectedContexts = useMemo(() => [mapContext(currentContext)], [currentContext]);

  const setSelectedContexts = (selectedContexts) => {
    helpers.setValue(selectedContexts);
  };

  return (
    <div className={styles.contextStep}>
      <InsuranceContextsSelector
        setSelectedInsuranceContexts={setSelectedContexts}
        activeRole={activeRole}
        infoText="The document(s) will be archived under the selected context(s):"
        preSelectedContexts={preSelectedContexts}
        setIsSearchMode={setSearchMode}
      />
      <FormErrorMessage name={fieldname} className={styles.error} />
    </div>
  );
};

ContextStep.propTypes = {
  currentContext: PropTypes.oneOfType([claimContextShape, coverContextShape]),
  setSearchMode: PropTypes.func.isRequired,
};

export default ContextStep;
