import axios from 'axios';

const client = axios.create({
  baseURL: '/api/',
  timeout: 30000,
});

client.interceptors.request.use(
  (config) => {
    if (config.headers) {
      // https://docs.duendesoftware.com/identityserver/v7/bff/apis/local/#anti-forgery-header
      config.headers.set('X-CSRF', '1');
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default client;

export const cancellableGet = () => {
  let cancelToken;

  return async (url, params) => {
    if (cancelToken) {
      cancelToken();
    }

    try {
      const result = await client.get(url, {
        params,
        cancelToken: new axios.CancelToken((token) => {
          cancelToken = token;
        }),
      });
      return result.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Previous request was cancelled - needs to be handled by the calling thunk
        return '';
      }

      throw error;
    }
  };
};
