import { useField } from 'formik';
import { PiiClassifier } from 'its-react-ui';
import { func } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { hasRole } from '../../../containers/User';
import { useCurrentUser, useSections } from '../../../hooks/userProfile';
import { RoleCategoryEnum } from '../../../lib';
import { PageSpinner } from '../../Shared';

const PiiSelector = ({ updateValidationSchema }) => {
  const [showPiiSectionSelector, setShowPiiSectionSelector] = useState(false);
  const { user, isLoading, isError } = useCurrentUser();
  const { user: accountInfo } = useSelector((state) => ({ user: state.user }));
  const { sections, isLoading: sectionsIsLoading, isError: sectionsIsError } = useSections();
  const [field, meta, helpers] = useField('piiSection');
  const [selectionList, setSelectionList] = useState([]);
  const isComplianceOfficer = hasRole(accountInfo, RoleCategoryEnum.COMPLIANCE_OFFICER);

  useEffect(() => {
    // Updates the selection list if the pii section is prepopulated in the form.
    if (!showPiiSectionSelector && !!field.value && selectionList.length > 0) {
      setShowPiiSectionSelector(true);
      setSelectionList(
        selectionList.map((section) => ({
          ...section,
          selected: field.value === section.value,
        }))
      );
    }
  }, [field.value, selectionList, showPiiSectionSelector]);

  useEffect(() => {
    if (user && user.sections) {
      setSelectionList([
        { value: '', displayName: 'Select section', selected: true },
        ...((isComplianceOfficer ? sections : user.sections) || []).map((section) => ({
          value: section.mdmExternalId,
          displayName: `${section.code} - ${section.name}`,
        })),
      ]);
    }
  }, [isComplianceOfficer, sections, sectionsIsError, user]);

  useEffect(() => {
    updateValidationSchema(showPiiSectionSelector);
  }, [showPiiSectionSelector, updateValidationSchema]);

  const toggleSectionSelector = () => {
    if (showPiiSectionSelector) {
      // Reset state before toggling off pii selector.
      helpers.setValue('');
      helpers.setError(null);
      helpers.setTouched(false);
      setSelectionList([
        { value: '', displayName: 'Select section', selected: true },
        ...user.sections.map((section) => ({
          value: section.mdmExternalId,
          displayName: `${section.code} - ${section.name}`,
        })),
      ]);
    }

    setShowPiiSectionSelector(!showPiiSectionSelector);
  };

  const selectPiiSection = useCallback(
    (selected) => {
      helpers.setValue(selected.value);
      setSelectionList(
        selectionList.map((section) => ({
          ...section,
          selected: selected.value === section.value,
        }))
      );
    },
    [helpers, selectionList]
  );

  if (isLoading || sectionsIsLoading) {
    return <PageSpinner />;
  }

  if (isError || sectionsIsError) {
    // Was unable to get the sections so we don't render anything.
    // User can still upload documents.
    return null;
  }

  return (
    <PiiClassifier
      isChecked={showPiiSectionSelector}
      onToggle={toggleSectionSelector}
      dropDownValues={selectionList}
      onSelectDropDownValue={selectPiiSection}
      validation={{
        show: meta.error && meta.touched,
        valid: !meta.error,
        message: meta.error,
      }}
    />
  );
};

PiiSelector.propTypes = {
  updateValidationSchema: func.isRequired,
};

export default PiiSelector;
