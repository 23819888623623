import React from 'react';
import PropTypes from 'prop-types';
import lighthouse from '../Icons/lighthouse.svg';
import styles from './SearchResult.module.scss';

const { emptyResults, text, bold } = styles;

const EmptyResult = ({ message = "Sorry we didn't find any results for ", searchText, hintText = 'Try checking your spelling for mistakes' }) => (
  <div className={emptyResults}>
    <img src={lighthouse} alt="No results" />
    <div>
      <span className={text}>
        {message}
        <span className={bold}>{searchText}</span>
      </span>
      <span>{hintText}</span>
    </div>
  </div>
);

EmptyResult.propTypes = {
  message: PropTypes.string,
  searchText: PropTypes.string,
  hintText: PropTypes.string,
};

export default EmptyResult;
