import { generateYearOptions } from '../lib/reinsuranceContext';
import client from './client';
import mapContext from './mappers/mapContext';
import mapContextGroups from './mappers/mapContextGroups';
import mapContexts from './mappers/mapContexts';

export const getCover = (contextId) => client.get(`insuranceContext/cover/${contextId}`).then((response) => response.data);

export const searchCoverContexts = (searchString, role) =>
  client
    .get(`insuranceContext/cover/search`, {
      params: {
        searchString,
        role,
      },
    })
    .then((response) => mapContextGroups(response.data));

export const searchClaimContexts = (searchString, searchInFinished, role) =>
  client
    .get(`insuranceContext/claim/search`, {
      params: {
        searchString,
        searchInFinished,
        role,
      },
    })
    .then((response) => mapContexts(response.data));

export const searchReinsuranceContexts = (text) =>
  client
    .get(`insuranceContext/reinsurance/search`, {
      params: {
        text,
      },
    })
    .then((response) =>
      response.data.map((x) => ({
        ...mapContext(x, false),
        yearOptions: generateYearOptions(),
        selected: false,
      }))
    );

export const getClaimByClaimNumberAndInsuranceYear = (claimNumber, insuranceYear) =>
  client.get(`insuranceContext/claim/${claimNumber}/${insuranceYear}`).then((response) => response.data);

export const getCoverByRiskNumberCoverNumberAndInsuranceYear = (riskNumber, coverNumber, insuranceYear) =>
  client.get(`insuranceContext/cover/${riskNumber}/${coverNumber}/${insuranceYear}`).then((response) => response.data);

export const getReinsuranceContext = (companyNumber, businessAccountNumber, insuranceYear) =>
  client.get(`insuranceContext/reinsurance/${companyNumber}/${businessAccountNumber}/${insuranceYear}`).then((response) => response.data);
