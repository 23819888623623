/* eslint-disable no-underscore-dangle */
/* eslint import/no-extraneous-dependencies: 0 */
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';
import { createBrowserHistory } from 'history';
import reducers from '../containers/reducers';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
export const history = createBrowserHistory({ basename: baseUrl });

export default function configureStore(initialState) {
  const middleware = [thunk, routerMiddleware(history)];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];

  if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);

    if (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
      enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  const rootReducer = combineReducers({
    router: connectRouter(history),
    ...reducers,
  });

  return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}
