import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { Checkbox } from '../../Shared';
import style from './ModalDeleteDocument.module.scss';
import { getContextIsSelected, useContextId, useInitialContextToggle } from './utils';
import { useDeleteDocumentSelection } from './DeleteDocumentSelectionProvider';
import { documentResultShape } from '../../../lib/customProptypes';

const Table = React.memo(({ headers, rows, allSelected, toggleSelectAll, selectedDocument, toggleContextSelection }) => (
  <table className={style.contextTable}>
    <thead>
      <tr className={style.row}>
        <th>
          <Checkbox data-test-id="YCBISbEms20Ryio9IeZ7D" isSelected={allSelected} onClick={toggleSelectAll} />
        </th>
        {headers.map((header) => (
          <th key={uuid()}>{header}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map((row) => (
        <tr key={uuid()} className={style.row}>
          <td>
            <Checkbox
              data-test-id="3ho18r2vag4VJz3ZK35hm"
              isSelected={getContextIsSelected(row.contextId, selectedDocument)}
              onClick={() => toggleContextSelection(row.contextId)}
            />
          </td>
          {row.content.map((x) => (
            <td key={uuid()}>{x}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
));

Table.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.array.isRequired,
      contextId: PropTypes.string.isRequired,
    })
  ).isRequired,
  allSelected: PropTypes.bool,
  toggleSelectAll: PropTypes.func.isRequired,
  selectedDocument: documentResultShape.isRequired,
  toggleContextSelection: PropTypes.func.isRequired,
};

const ContextTable = ({ documentId, headers, rows, availableContexts }) => {
  const { toggleForDeletionSelection, selectAllContextsForDeletion, unselectAllContextsForDeletion, selectedForDeletion } =
    useDeleteDocumentSelection();
  const currentContextId = useContextId();

  const toggleContextSelection = useCallback(
    (contextId) => {
      toggleForDeletionSelection({ documentId, contextId });
    },
    [documentId, toggleForDeletionSelection]
  );
  const selectedDocument = selectedForDeletion[documentId];

  // In case of stale search results, we check that the context actually exist on the document before selection it
  const contextExists = rows.some((row) => row.contextId === currentContextId);

  useInitialContextToggle(currentContextId, selectedDocument, toggleContextSelection, contextExists);

  const allSelected = selectedDocument && availableContexts.length === selectedDocument.length;

  const selectAll = () => selectAllContextsForDeletion({ documentId });
  const unselectAll = () => unselectAllContextsForDeletion({ documentId });

  const toggleSelectAll = allSelected ? unselectAll : selectAll;

  return (
    <Table
      headers={headers}
      rows={rows}
      allSelected={allSelected}
      toggleSelectAll={toggleSelectAll}
      selectedDocument={selectedDocument}
      toggleContextSelection={toggleContextSelection}
    />
  );
};

ContextTable.propTypes = {
  documentId: PropTypes.string,
  headers: PropTypes.array.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.array.isRequired,
      contextId: PropTypes.string.isRequired,
    })
  ).isRequired,
  availableContexts: PropTypes.array.isRequired,
};

export default ContextTable;
