import useSWR from 'swr';
import { StringParam, useQueryParam } from 'use-query-params';
import client from '../apis/client';
import { KnownQueryParams } from '../containers/queryParameters';

export type DocumentTag = {
  name: string;
  displayName: string;
  description: string;
};

export type DocumentType = {
  id: number;
  applicationRoleName: string;
  tags: DocumentTag[];
  keywords: string[];
  disabled: boolean;
  name: string;
  displayName: string;
  description: string;
};

export const useDocumentTypes = (role: string) => {
  const { data, error } = useSWR<DocumentType[]>(`metadata/${role}/documentTypes`, {
    revalidateOnFocus: false,
  });

  return {
    documentTypes: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export type Classification = {
  id: number;
  name: string;
  default: boolean;
  priority: number;
};

export const useClassifications = () => {
  const { data, error } = useSWR<Classification[]>('metadata/classifications');
  return {
    classifications: data,
    isLoading: !error && !data,
    error,
  };
};

type Taxonomy = {
  applicationRoleName: string;
  documentType: string;
  documentTags: string[];
};

export type InsuranceContext = {
  id: string;
  businessAccount: string;
  businessAccountId?: string;
  $type: string;
};

type FileItem = {
  id: string;
  externalName: string;
  name: string;
  isAttachment: boolean;
};

type VersionUser = {
  name: string;
  email: string;
  subjectId: string;
};

type VersionHistory = {
  date?: string;
  user: VersionUser;
  method: string;
};

type ContextIdentityMapping = {
  contextId: string;
  email: string;
  insuranceYear: number;
};

type DocumentMetadata = {
  id: string;
  archived: string;
  sortDate: string;
  notes: string;
  taxonomy: Taxonomy;
  classification: string;
  insuranceContexts: InsuranceContext[];
  files: FileItem[];
  user: string;
  archivedUsing: string;
  isDeleted: boolean;
  history: VersionHistory[];
  contextIdentities: ContextIdentityMapping[];
  timeToLive?: number;
  piiSection?: string;
};

export const useDocumentMetadata = (documentId: string) => {
  const key = `metadata/${documentId}`;
  const { data, error } = useSWR<DocumentMetadata>(key);
  return {
    documentMetadata: data,
    isLoading: !error && !data,
    error,
  };
};

const getBlob = (url: string, asBytes = true) =>
  client.get(url, { responseType: 'blob' }).then((response) => (asBytes ? response.data.arrayBuffer() : response.data.text()));

export const useFile = (fileId: string, asBytes = true) => {
  const key = `blob/${fileId}`;
  const { data, error } = useSWR(key, (url) => getBlob(url, asBytes), {
    shouldRetryOnError: (err) => err?.code !== 'ECONNABORTED',
  });
  return {
    data,
    isLoading: !error && !data,
    error,
  };
};

const getUrl = (response: any) => {
  const blob = new Blob([response], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);
  return url;
};

const getBlobUrl = (url: string) => client.get(url, { responseType: 'blob' }).then((response) => getUrl(response.data));

export const useImageUrl = (fileId: string) => {
  const key = `blob/${fileId}/downloadBlob`;
  const { data, error } = useSWR(key, (url) => getBlobUrl(url));
  return {
    data,
    isLoading: !error && !data,
    error,
  };
};

export const useDetailsQueryParam = () => {
  const [detailsQueryParam, setDetailsQueryParam] = useQueryParam(KnownQueryParams.DETAILS, StringParam);

  const clearQueryParam = () => {
    setDetailsQueryParam(undefined, 'replaceIn');
  };

  const toggleQueryParam = (documentId: string) => {
    if (documentId === detailsQueryParam) {
      clearQueryParam();
    } else {
      setDetailsQueryParam(documentId, 'replaceIn');
    }
  };

  return {
    detailsQueryParam,
    clearQueryParam,
    toggleQueryParam,
  };
};
