import { mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiMinusBox } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { CustomCheckbox } from '@reach/checkbox';
import '@reach/checkbox/styles.css';
import React, { forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as constants from '../constVariables';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  const title = rest.disabled ? 'Disabled, the document has to be signed first.' : 'Toggle Row Selected';
  return (
    // Associated by nesting @reach/checkbox
    <label data-test-id="o-sbEMiWRfr5UZOD6ugBy" style={{ margin: '0px' }} onClick={(e) => e.stopPropagation()}>
      <CustomCheckbox ref={resolvedRef} {...rest} title={title}>
        <MdiIcon path={rest.disabled ? mdiMinusBox : rest.checked ? mdiCheckboxMarked : mdiCheckboxBlankOutline} size={constants.SMALL_ICON_SIZE} />
      </CustomCheckbox>
    </label>
  );
});

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool.isRequired,
};

export default IndeterminateCheckbox;
