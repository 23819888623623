import { useEffect } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useAccount } from '../providers/authenticationProvider';
import { getAppInsightsKey } from '../apis';

function useAppInsights() {
  const { isAuthenticated, user } = useAccount();

  useEffect(() => {
    const fetchAppInsightsKey = async () => {
      try {
        const key = await getAppInsightsKey();
        const appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: key,
            maxBatchInterval: 30000,
            accountId: user.email,
            enableAutoRouteTracking: true,
          },
        });
        appInsights.loadAppInsights();
        const telemetryInitializer = (envelope) => {
          envelope.data.application = 'Dms.Document.Web';
        };
        appInsights.addTelemetryInitializer(telemetryInitializer);
      } catch (error) {
        // Fail silently
      }
    };

    if (isAuthenticated) {
      fetchAppInsightsKey();
    }
  }, [isAuthenticated]);
}

export default useAppInsights;
