import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cn from 'classnames';
import { Close, Load } from '@instech/icons';
import { useCurrentUser, useSaveUserSections, useSections } from '../../hooks/userProfile';
import XHR_STATUS from '../../lib/xhrStatus';
import check from '../Icons/check.svg';
import cry from '../Icons/cry.svg';
import ModalMessage from './ModalMessage';
import style from './ModalUserProfile.module.scss';

const Loader = styled(Load)`
  height: 16px;
`;
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10rem auto;
`;
const StyledClose = styled(Close)`
  height: 24px;
  cursor: pointer;
`;

// eslint-disable-next-line react/prop-types
const Checkbox = ({ checked, disabled, value, onChange }) => (
  <label className={style.checkbox} disabled={disabled}>
    <input type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
    <span>{value}</span>
  </label>
);

const ModalUserProfile = ({ closeModal }) => {
  const { sections, isLoading: isLoadingSections, isError: isErrorSections } = useSections();
  const { user, isLoading: isLoadingUser, isError: isErrorUser } = useCurrentUser();
  const [selectedSections, setSelectedSections] = useState([]);
  const { saveState, saveUserSections } = useSaveUserSections();

  useEffect(() => {
    if (user) {
      setSelectedSections(user.sections.map((x) => x.mdmExternalId));
    }
  }, [user]);

  const selectSection = (sectionId) => {
    setSelectedSections([...selectedSections, sectionId]);
  };

  const unselectSection = (sectionId) => {
    const newSelections = selectedSections.filter((x) => x !== sectionId);
    setSelectedSections(newSelections);
  };

  if (saveState === XHR_STATUS.SUCCESS) {
    return (
      <ModalMessage iconPath={check} header="Success" text="User profile saved">
        <button data-test-id="dllPpkEwUsyGI92SZEDHi" type="button" className={style.closeButton} onClick={closeModal}>
          Close
        </button>
      </ModalMessage>
    );
  }

  if (saveState === XHR_STATUS.ERROR || isErrorSections || isErrorUser) {
    return (
      <ModalMessage iconPath={cry} header="That didn't go well" text="Unable to save user profile">
        <button data-test-id="XXNN9tKgRZ0d5D5sujMxI" type="button" className={style.closeButton} onClick={closeModal}>
          Close
        </button>
      </ModalMessage>
    );
  }

  return (
    <div className={style.wrap}>
      <div className={style.header}>
        <h1>User profile</h1>
        <StyledClose data-test-id="-wv6c2G7UB1jL69mZMxDU" onClick={() => closeModal()} />
      </div>
      <div className={style.scrollableContent}>
        {isLoadingUser || isLoadingSections ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <>
            <h3>Edit sections:</h3>
            <div>Primary section {user.sectionCode}</div>
            <div className={style.sections}>
              {sections.map((section) => {
                const checked = selectedSections.includes(section.mdmExternalId);
                return (
                  <Checkbox
                    key={section.mdmExternalId}
                    value={`${section.code} - ${section.name}`}
                    checked={checked}
                    disabled={user.sectionCode === section.code}
                    onChange={() => (checked ? unselectSection(section.mdmExternalId) : selectSection(section.mdmExternalId))}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
      <div className={style.saveButtonWrap}>
        {!isLoadingUser && !isLoadingSections && (
          <button
            data-test-id="8L8JfLdGGYYfnMw9yjlEy"
            type="button"
            className={cn(style.saveButton, {
              [style.cursorNotAllowed]: saveState === XHR_STATUS.LOADING,
            })}
            onClick={() => saveUserSections(selectedSections)}
          >
            {saveState === XHR_STATUS.LOADING ? <Loader /> : <span>Save</span>}
          </button>
        )}
      </div>
    </div>
  );
};

ModalUserProfile.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalUserProfile;
