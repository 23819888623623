import { useRef } from 'react';
import { throttle } from 'throttle-debounce';
import useEventListener from './useEventListener';

const scrollHeightLoadThreshold = 300;

const fetchMoreSearchResults = ({ target }, fetchMoreFunc) => {
  const { scrollHeight, scrollTop, clientHeight } = target;
  const scrollPosition = clientHeight + scrollTop;

  const shouldLoadMore = scrollTop > 0 && scrollHeight - scrollHeightLoadThreshold < scrollPosition;
  if (shouldLoadMore) {
    fetchMoreFunc();
  }
};

const fetchMoreSearchResultsMobile = (fetchMoreFunc) => {
  const { scrollHeight, clientHeight } = document.body;
  const scrollPosition = clientHeight + window.scrollY;

  const shouldLoadMore = window.scrollY > 0 && scrollHeight - scrollHeightLoadThreshold < scrollPosition;
  if (shouldLoadMore) {
    fetchMoreFunc();
  }
};

const scrollHandler = (fetchMoreFunc, hasMoreItems, isMobile = false) =>
  throttle(
    300,
    false,
    (evnt) => hasMoreItems && (isMobile ? fetchMoreSearchResultsMobile(fetchMoreFunc) : fetchMoreSearchResults(evnt, fetchMoreFunc)),
    false
  );

/**
 * Attaches scroll listener to `scrollableRef` and runs callback when needed.
 *
 * If `scrollableRef` isn't set, it will listen to window scroll
 * @param {boolean} hasMoreItems
 * @param {function} fetchMoreFunc
 * @param {boolean} isMobile
 * @returns {object} {scrollableRef, scrollToTop}
 */
const useInfiniteScroll = (hasMoreItems, fetchMoreFunc, isMobile = false) => {
  const scrollableRef = useRef(window);
  const scrollToTop = () => {
    if (isMobile) {
      window.scrollTo(0, 0);
      return;
    }
    scrollableRef.current.scrollTop = 0;
  };

  useEventListener(scrollableRef, 'scroll', scrollHandler(fetchMoreFunc, hasMoreItems, isMobile));

  return { scrollableRef, scrollToTop };
};

export default useInfiniteScroll;
