import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQueryParam } from 'use-query-params';
import { KnownQueryParams, mapRoleToSearchContext, SearchContextType } from '../../containers/queryParameters';
import useWindowWidth from '../../hooks/useWindowWidth';
import { isMobile } from '../../lib/helpers';
import { useFeatureFlags } from '../../providers/FeatureFlagProvider';
import { PageSpinner } from '../Shared';
import styles from './App.module.scss';
import Desktop from './Desktop';
import Mobile from './Mobile';

const App = () => {
  const width = useWindowWidth();
  const [searchContext, setSearchContext] = useQueryParam(KnownQueryParams.CONTEXT);
  const user = useSelector((state) => state.user);
  const featureFlags = useFeatureFlags();

  // There should always be a search context in the query parameters when we hit this component
  useEffect(() => {
    if (!searchContext && user.remoteDataState.isSuccess()) {
      const roleSearchContext = mapRoleToSearchContext(user.roles, featureFlags);
      setSearchContext(roleSearchContext, 'replaceIn');
      return;
    }

    if (!searchContext && user.remoteDataState.error) {
      setSearchContext(SearchContextType.ALL, 'replaceIn');
    }
  }, [searchContext, setSearchContext, user.remoteDataState, user.roles, featureFlags]);

  // Guard against app rendering before required data has been loaded
  if (!searchContext || !(user.remoteDataState.isSuccess() || user.remoteDataState.error)) {
    return <PageSpinner />;
  }

  if (user.remoteDataState.error && user.remoteDataState.error.response.status === 403) {
    return <div className={styles.errorMessage}>Access forbidden</div>;
  }

  return isMobile(width) ? <Mobile /> : <Desktop />;
};

export default App;
