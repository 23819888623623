import { getClaimSearchResult, getCoverSearchResult, getReinsuranceSearchResult, getSearchResult } from '../../apis';
import {
  mapClaimDocumentPayload,
  mapCoverDocumentPayload,
  mapReinsuranceDocumentPayload,
  mapSearchCasePayload,
  mapSearchClaimsPayload,
  mapSearchPayload,
} from '../../apis/mappers/mapSearchPayload';
import { toAction, RoleCategoryEnum } from '../../lib';
import { createClaimFacetGroups, createFacetGroupVms } from '../../lib/facets';
import { FETCH_CASE_SEARCH, FETCH_CASE_SEARCH_FAILURE, FETCH_CASE_SEARCH_SUCCESS } from '../CaseSearch';
import { FETCH_INITIAL_CASE_DOCUMENTS, FETCH_INITIAL_CASE_DOCUMENTS_FAILURE, FETCH_INITIAL_CASE_DOCUMENTS_SUCCESS } from '../CaseSearch/constants';
import { SET_FILTERS } from '../Filters/constants';
import { decodeQueryParams, KnownQueryParams, SearchContextType } from '../queryParameters';
import { FETCH_SEARCH_RESULT, FETCH_SEARCH_RESULT_FAILURE, FETCH_SEARCH_RESULT_SUCCESS } from '../SearchResult/constants';
import { caseDocumentPathMatch, keepFetchingDocuments } from '../thunkHelpers';
import { hasRole } from '../User';
import { RESET_SEARCH } from './constants';

const searchAll = async (dispatch, queryParams) => {
  dispatch(toAction(FETCH_SEARCH_RESULT));

  const searchPayload = mapSearchPayload(queryParams, true);
  try {
    const searchResult = await getSearchResult(searchPayload);
    if (!searchResult) {
      // result is undefined if the request was cancelled
      return null;
    }

    const facets = createFacetGroupVms(searchResult.facetGroups);
    dispatch(toAction(SET_FILTERS, facets));

    return dispatch(toAction(FETCH_SEARCH_RESULT_SUCCESS, searchResult));
  } catch (err) {
    return dispatch(toAction(FETCH_SEARCH_RESULT_FAILURE, err));
  }
};

const searchClaims = async (dispatch, state, queryParams) => {
  dispatch(toAction(FETCH_CASE_SEARCH));

  const { user } = state;
  const userIsClaimshandler = hasRole(user, RoleCategoryEnum.CLAIMS_HANDLER);
  const searchPayload = mapSearchClaimsPayload(queryParams, true);

  try {
    const results = await getClaimSearchResult(searchPayload);
    if (!results) {
      // result is undefined if the request was cancelled
      return null;
    }

    const facetGroups = createClaimFacetGroups(results.facetGroups, userIsClaimshandler, user.email, queryParams.claimsHandlerEmail);

    dispatch(toAction(SET_FILTERS, facetGroups));

    return dispatch(toAction(FETCH_CASE_SEARCH_SUCCESS, results));
  } catch (err) {
    return dispatch(toAction(FETCH_CASE_SEARCH_FAILURE, err));
  }
};

const searchCovers = async (dispatch, queryParams) => {
  dispatch(toAction(FETCH_CASE_SEARCH));
  const searchPayload = mapSearchCasePayload(queryParams, true);

  try {
    const results = await getCoverSearchResult(searchPayload);
    if (!results) {
      // result is undefined if the request was cancelled
      return null;
    }

    const facets = createFacetGroupVms(results.facetGroups);
    dispatch(toAction(SET_FILTERS, facets));

    return dispatch(toAction(FETCH_CASE_SEARCH_SUCCESS, results));
  } catch (err) {
    return dispatch(toAction(FETCH_CASE_SEARCH_FAILURE, err));
  }
};

const searchReinsurance = async (dispatch, queryParams) => {
  dispatch(toAction(FETCH_CASE_SEARCH));

  const searchPayload = mapSearchCasePayload(queryParams, true);

  try {
    const results = await getReinsuranceSearchResult(searchPayload);
    if (!results) {
      // result is undefined if the request was cancelled
      return null;
    }

    const facets = createFacetGroupVms(results.facetGroups);
    dispatch(toAction(SET_FILTERS, facets));

    return dispatch(toAction(FETCH_CASE_SEARCH_SUCCESS, results));
  } catch (err) {
    return dispatch(toAction(FETCH_CASE_SEARCH_FAILURE, err));
  }
};

export const fetchSearchResult = () => async (dispatch, getState) => {
  const state = getState();
  const {
    router: { location },
  } = state;

  const queryParams = decodeQueryParams(location.search);

  const searchContext = queryParams[KnownQueryParams.CONTEXT];

  switch (searchContext) {
    case SearchContextType.ALL:
      await searchAll(dispatch, queryParams);
      break;
    case SearchContextType.CLAIM:
      await searchClaims(dispatch, state, queryParams);
      break;
    case SearchContextType.COVER:
      await searchCovers(dispatch, queryParams);
      break;
    case SearchContextType.REINSURANCE:
      await searchReinsurance(dispatch, queryParams);
      break;
    default:
      throw Error(`Fetching results for context '${searchContext}' has not been implemented`);
  }
};

export const fetchAllCaseDocuments =
  (isDeepLink = false) =>
  async (dispatch, getState) => {
    dispatch(toAction(FETCH_INITIAL_CASE_DOCUMENTS));
    const {
      router: { location },
    } = getState();

    const { caseType } = caseDocumentPathMatch(location.pathname).params;
    let payload;
    switch (caseType) {
      case SearchContextType.CLAIM:
        payload = mapClaimDocumentPayload(location, isDeepLink);
        break;
      case SearchContextType.COVER:
        payload = mapCoverDocumentPayload(location, isDeepLink);
        break;
      case SearchContextType.REINSURANCE:
        payload = mapReinsuranceDocumentPayload(location, isDeepLink);
        break;
      default:
        throw Error(`Document search for ${caseType} is unsupported`);
    }

    try {
      const searchResult = await getSearchResult(payload);
      if (!searchResult) {
        // result is undefined if the request was cancelled
        return null;
      }

      if (searchResult.hasMoreItems) {
        // Azure search has a limitation of 1000 documents, but here we want all results
        await keepFetchingDocuments(payload, searchResult);
      }

      dispatch(toAction(FETCH_INITIAL_CASE_DOCUMENTS_SUCCESS));

      const facets = createFacetGroupVms(searchResult.facetGroups, true);
      dispatch(toAction(SET_FILTERS, facets));

      return dispatch(toAction(FETCH_SEARCH_RESULT_SUCCESS, searchResult));
    } catch (err) {
      return dispatch(toAction(FETCH_INITIAL_CASE_DOCUMENTS_FAILURE, err));
    }
  };

export const reset = () => (dispatch, _) => {
  dispatch(toAction(RESET_SEARCH));
};
