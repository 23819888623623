import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './Main.module.scss';

const Main = ({ children, className }) => <main className={cn('themeNhc', styles.root, className)}>{children}</main>;

Main.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Main;
