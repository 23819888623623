import { mdiInformationOutline } from '@mdi/js';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { bool, array, func } from 'prop-types';
import { SearchContextType } from '../../../containers/queryParameters';
import { SMALL_ICON_SIZE } from '../../constVariables';
import { IconButton } from '../../Shared';
import ContextTable, { ContextRow } from './ContextTable';

const CoverContexts = ({ showAll = true, contexts, navigateToContext, showDetails }) => {
  const headers = (
    <tr>
      <th>Interest</th>
      {showAll && <th>Risk number</th>}
      <th>Cover note</th>
      {showAll && <th>Fleet name</th>}
      <th />
    </tr>
  );

  const rows = contexts.map((context) => {
    const { interest: { shortName } = {}, riskNumber, coverNote, fleetName, insuranceYear } = context;
    const pathname = `/cases/${SearchContextType.COVER}/${riskNumber}/${coverNote}/${insuranceYear}`;

    const onRowClick = () => {
      navigateToContext(pathname);
    };

    return (
      <ContextRow key={uuid()} onRowClick={onRowClick}>
        <td>{shortName}</td>
        {showAll && <td>{riskNumber}</td>}
        <td>{coverNote}</td>
        {showAll && <td>{fleetName}</td>}
        <td>
          <IconButton
            data-test-id="ALSfN8PMVlWsG4QoZQLNf"
            icon={mdiInformationOutline}
            iconSize={SMALL_ICON_SIZE}
            typeStyle="primary"
            aria-label="Open context details"
            onClick={(event) => showDetails(event, context)}
          />
        </td>
      </ContextRow>
    );
  });

  return <ContextTable headers={headers} rows={rows} />;
};

CoverContexts.propTypes = {
  showAll: bool,
  contexts: array.isRequired,
  navigateToContext: func.isRequired,
  showDetails: func.isRequired,
};

export default CoverContexts;
