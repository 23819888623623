import Dialog from '@reach/dialog';
import React from 'react';
import cn from 'classnames';
import PropTypes, { func, string } from 'prop-types';
import CloseModalButton from './CloseModalButton';
import styles from './ItsDialog.module.scss';

const ItsDialog = ({ isOpen, onDismiss, children, className, ...rest }) => (
  <Dialog isOpen={isOpen} onDismiss={onDismiss} className={cn('themeNhc', className)} {...rest}>
    {children}
  </Dialog>
);

ItsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ItsDialog;

export const DialogHeader = ({ title, close }) => (
  <header className={styles.dialogHeader}>
    <h1>{title}</h1>
    {close && <CloseModalButton close={close} />}
  </header>
);

DialogHeader.propTypes = {
  title: string.isRequired,
  close: func,
};
