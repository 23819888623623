import { mdiArchive, mdiEmailOutline } from '@mdi/js';
import MdiIcon from '@mdi/react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { getOriginalDownloadBlobAsync, getOriginalDownloadBlobMetadataAsync } from '../../apis';
import { getFormatedDate, getFormatedTime } from '../../lib/helpers';
import IconButton from './IconButton';
import style from './ArchiveInfo.module.scss';

const ArchiveInfo = ({ id, user, date, useCustomMobileStyle = false, isEmail }) => (
  <div className={cn([style.wrap, useCustomMobileStyle ? style.mobile : null])}>
    <div className={style.archivedBy}>
      <MdiIcon path={mdiArchive} />
      <span>
        Archived by {user} {getFormatedTime(date)}&nbsp;&nbsp;
        {getFormatedDate(date)}
      </span>
    </div>
    {isEmail && <OpenEmailButton id={id} />}
  </div>
);

ArchiveInfo.propTypes = {
  date: PropTypes.string,
  id: PropTypes.string,
  user: PropTypes.string,
  useCustomMobileStyle: PropTypes.bool,
  isEmail: PropTypes.bool,
};

export default ArchiveInfo;

const invokeDownload = (url, subject) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', subject ?? '');
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const createUrl = (downloadBlob, metadata) => {
  const blob = new Blob([downloadBlob], { type: metadata.contentType });
  const url = URL.createObjectURL(blob);
  invokeDownload(url, metadata.fileName);
};

const downloadFile = (id) => {
  getOriginalDownloadBlobMetadataAsync(id).then((metadata) =>
    getOriginalDownloadBlobAsync(id).then((downloadBlob) => createUrl(downloadBlob, metadata))
  );
};

const OpenEmailButton = ({ id }) => (
  <IconButton
    data-test-id="VeWKnoeMWBP6CVOCukxxq"
    icon={mdiEmailOutline}
    className={style.openEmail}
    typeStyle="primary"
    text="Open in Outlook"
    onClick={() => id && downloadFile(id)}
  />
);

OpenEmailButton.propTypes = {
  id: PropTypes.string,
};
