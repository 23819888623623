import React from 'react';
import { v4 as uuid } from 'uuid';
import { bool, array, func } from 'prop-types';
import { SearchContextType } from '../../../containers/queryParameters';
import ContextTable, { ContextRow } from './ContextTable';
import { sortReinsuranceContexts } from '../../../lib/reinsuranceContext';

const ReinsuranceContexts = ({ contexts, showAll, navigateToContext }) => {
  const headers = (
    <tr>
      <th>Customer name</th>
      {showAll && <th>Customer number</th>}
      <th>Business account</th>
      {showAll && <th>Business account number</th>}
      <th>Underwriting year</th>
    </tr>
  );

  const rows = contexts.sort(sortReinsuranceContexts).map((context) => {
    const { companyName, companyNumber, businessAccount, businessAccountNumber, insuranceYear } = context;

    const pathname = `/cases/${SearchContextType.REINSURANCE}/${companyNumber}/${businessAccountNumber}/${insuranceYear}`;

    const onRowClick = () => {
      navigateToContext(pathname);
    };

    return (
      <ContextRow key={uuid()} onRowClick={onRowClick}>
        <td>{companyName}</td>
        {showAll && <td>{companyNumber}</td>}
        <td>{businessAccount}</td>
        {showAll && <td>{businessAccountNumber}</td>}
        <td>{insuranceYear}</td>
      </ContextRow>
    );
  });

  return <ContextTable headers={headers} rows={rows} />;
};

ReinsuranceContexts.propTypes = {
  showAll: bool,
  contexts: array.isRequired,
  navigateToContext: func.isRequired,
};

export default ReinsuranceContexts;
