import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import mapMetaDocument from '../../apis/mappers/mapMetaDocument';
import { useDocumentMetadata } from '../../hooks/documents';
import { isEmailMetadata } from '../../lib/helpers';
import HttpStatus from '../../lib/HttpStatus';
import { ArchiveInfo, PageSpinner } from '../Shared';
import { useTitle } from '../../hooks/useTitle';
import style from './Details.module.scss';
import DetailsBottomContent from './DetailsBottomContent';
import DetailsMiddleContent from './DetailsMiddleContent';
import DetailsTopContent from './DetailsTopContent';
import DetailsStatus from './DetailsStatus';
import documentAccessDeniedIcon from '../Icons/document_access_denied.svg';

const Details = ({ showAll, showContext, className, documentId }) => {
  const { documentMetadata, isLoading, error } = useDocumentMetadata(documentId);
  const item = documentMetadata && mapMetaDocument(documentMetadata);
  const isEmail = documentMetadata && isEmailMetadata(documentMetadata);
  const [tabTitle, setTabTitle] = useState(document.title);
  useTitle(tabTitle);

  useEffect(() => {
    if (!documentMetadata) return;
    const documentTitle = isEmail ? documentMetadata.documentMetadata.subject : documentMetadata.files[0]?.name;
    setTabTitle(`Document - ${documentTitle}`);
  }, [documentMetadata, isEmail]);

  if (isLoading) {
    return <PageSpinner />;
  }

  if (error) {
    let title = 'Unable to load document details.';
    let subtitle;

    if (error.response.status === HttpStatus.FORBIDDEN && error.response.data.Message.includes('PII')) {
      title = 'Access denied for PII document';
      subtitle = 'Only users within the document section can access this document';
    }

    return <DetailsStatus title={title} subtitle={subtitle} image={documentAccessDeniedIcon} />;
  }

  return (
    <div className={cn(style.wrap, className)}>
      <ArchiveInfo id={item.id} user={item.user} date={item.archivedDate} isEmail={isEmail} />
      {showContext && <DetailsTopContent item={item} showAll={showAll} />}
      <div className={style.scrollable}>
        <DetailsMiddleContent isEmail={isEmail} item={item} showAll={showAll} />
        <DetailsBottomContent isEmail={isEmail} item={item} />
      </div>
    </div>
  );
};

Details.propTypes = {
  documentId: PropTypes.string.isRequired,
  showAll: PropTypes.bool,
  showContext: PropTypes.bool,
  className: PropTypes.string,
};

Details.defaultProps = {
  showAll: true,
  showContext: true,
  className: null,
};

export default Details;
