import { mdiDownload, mdiEye } from '@mdi/js';
import MdiIcon from '@mdi/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getDownloadBlobAsync, getThumbnailAsync } from '../../apis';
import { ICON_SIZE } from '../constVariables';
import loaderIcon from '../Icons/loader.svg';
import thumbnailMissing from '../Icons/thumbnail_missing.svg';
import ModalInfo from '../Modal/ModalInfo';
import { useModalContext } from '../Modal/ModalProvider';
import styles from './FileDownloadButton.module.scss';
import FileExtension from './FileExtension';
import FileViewer from './FileViewer';

const invokeDownload = (url, name) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const Thumbnail = ({ id }) => {
  const [imgSource, setImgSource] = useState(loaderIcon);

  useEffect(() => {
    async function setThumbnail(thumbnailId) {
      try {
        const thumbnail = await getThumbnailAsync(thumbnailId);
        if (thumbnail.size > 0) {
          const blob = new Blob([thumbnail], { type: 'image/jpeg' });
          const url = URL.createObjectURL(blob);
          setImgSource(url);
        } else {
          setImgSource(thumbnailMissing);
        }
      } catch (error) {
        setImgSource(thumbnailMissing);
      }
    }

    setThumbnail(id);
  }, [id]);
  return <img className={styles.thumbnail} src={imgSource} alt="Thumbnail" />;
};
Thumbnail.propTypes = {
  id: PropTypes.string.isRequired,
};

const FileViewerModal = ({ closeModal, file }) => (
  <ModalInfo title="File Viewer" closeModal={closeModal}>
    <FileViewer file={file} openInModal />
  </ModalInfo>
);

FileViewerModal.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  closeModal: PropTypes.func,
};

const createUrl = (downloadBlob, name) => {
  const blob = new Blob([downloadBlob], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);
  invokeDownload(url, name);
};

const FileDownloadButton = ({ file }) => {
  const { openModal } = useModalContext();
  const extension = file.name.split('.').pop().toLowerCase();
  const downloadFile = ({ id, name }) => {
    getDownloadBlobAsync(id).then((downloadBlob) => createUrl(downloadBlob, name));
  };

  return (
    <div className={styles.wrap}>
      <FileExtension type={extension} className={styles.extension} />
      <Thumbnail id={file.id} />
      <div className={styles.label} title={file.name}>
        {file.name}
      </div>
      <MdiIcon data-test-id="jlpvJ5PIUTKlA_tIstyMq" path={mdiEye} size={ICON_SIZE} onClick={() => openModal({ component: FileViewerModal, file })} />
      <MdiIcon data-test-id="XKUvIVd2P7aJOkEkUAcln" path={mdiDownload} size={ICON_SIZE} onClick={() => downloadFile(file)} />
    </div>
  );
};

FileDownloadButton.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default FileDownloadButton;
