import { getUserRoles } from '../../apis';
import { toAction } from '../../lib';
import * as actionTypes from './constants';

export const setUser = (user) => (dispatch) => dispatch(toAction(actionTypes.SET_USER, user));

export const fetchUserRoles = () => async (dispatch) => {
  dispatch(toAction(actionTypes.FETCH_USER_ROLES));

  try {
    const result = await getUserRoles();

    return dispatch(toAction(actionTypes.FETCH_USER_ROLES_SUCCESS, result));
  } catch (error) {
    return dispatch(toAction(actionTypes.FETCH_USER_ROLES_FAILURE, error));
  }
};
