import React from 'react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { childrenShape } from '../../lib/customProptypes';
import s from './ModalInfo.module.scss';

const ModalInfo = ({ title, children, closeModal, isCloseVisible = true }) => (
  <div className={s.wrap}>
    <div className={s.header}>
      <h1>{title}</h1>
      <MdiIcon data-test-id="8fZSiPjd2dblglImdbv4g" path={mdiClose} onClick={() => closeModal()} />
    </div>
    {children}
    {isCloseVisible && (
      <div className={s.closeButton}>
        <button data-test-id="ABtEsoKc9YvHHgKUkuJE5" type="button" onClick={() => closeModal()}>
          CLOSE
        </button>
      </div>
    )}
  </div>
);

ModalInfo.propTypes = {
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: childrenShape.isRequired,
  isCloseVisible: PropTypes.bool,
};

export default ModalInfo;
