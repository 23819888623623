import { useField } from 'formik';
import { DropdownField } from 'its-react-ui';
import { array, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styles from './ForcedPiiNotifier.module.scss';

const DocumentPiiSelector = ({ forcedPii, sections }) => {
  const [field, meta, helpers] = useField({ name: forcedPii.id });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!meta.touched && !field.value) {
      helpers.setValue(forcedPii.forcedPiiSection);
      // Need to manually set touched as Formik doesn't know automatically,
      // due to our custom DropdownField.
      helpers.setTouched(true);
    }
  });

  useEffect(() => {
    setOptions(
      [
        {
          value: null,
          displayName: 'Does not contain PII',
          selected: field.value === null,
        },
      ].concat(
        sections.map((section) => ({
          value: section.mdmExternalId,
          displayName: section.code,
          selected: field.value === section.mdmExternalId,
        }))
      )
    );
  }, [field.value, sections]);

  const onSelect = (selected) => {
    helpers.setValue(selected.value);
  };

  return (
    <>
      <td className={styles.name}>{forcedPii.name}</td>

      <td className={styles.dropdown}>
        <DropdownField values={options} onSelect={onSelect} />
      </td>
    </>
  );
};

DocumentPiiSelector.propTypes = {
  forcedPii: shape({
    name: string.isRequired,
    id: string.isRequired,
    forcedPiiSection: string.isRequired,
  }),
  sections: array.isRequired,
};

export default DocumentPiiSelector;
