import { useEffect } from 'react';

function useLongPress(elemRef, longPressHandler, clickHandler) {
  useEffect(() => {
    let timeoutId;
    let isPressed = false;
    let isLongPressFired = false;
    const longPressed = () => {
      if (!isPressed) return;

      isLongPressFired = true;
      longPressHandler();
    };
    const pressStarted = () => {
      isPressed = true;
      isLongPressFired = false;
      timeoutId = setTimeout(longPressed, 300);
    };
    const pressCancelled = () => {
      isPressed = false;
      clearTimeout(timeoutId);
    };
    const pressFinished = () => {
      if (!isLongPressFired && isPressed) clickHandler();
      isPressed = false;
      clearTimeout(timeoutId);
    };

    if (elemRef.current) elemRef.current.addEventListener('mousedown', pressStarted);
    if (elemRef.current) elemRef.current.addEventListener('mouseup', pressFinished);
    if (elemRef.current) elemRef.current.addEventListener('mouseleave', pressCancelled);
    if (elemRef.current) elemRef.current.addEventListener('touchstart', pressStarted);
    if (elemRef.current) elemRef.current.addEventListener('touchend', pressFinished);
    return () => {
      clearTimeout(timeoutId);

      if (elemRef.current) elemRef.current.removeEventListener('mousedown', pressStarted);
      if (elemRef.current) elemRef.current.removeEventListener('mouseup', pressFinished);
      if (elemRef.current) elemRef.current.removeEventListener('mouseleave', pressCancelled);
      if (elemRef.current) elemRef.current.removeEventListener('touchstart', pressStarted);
      if (elemRef.current) elemRef.current.removeEventListener('touchend', pressFinished);
    };
  }, [elemRef.current]);
}

export default useLongPress;
