import { mdiClose } from '@mdi/js';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { EmptyResult } from '..';
import { fetchNextCasePage } from '../../containers/CaseSearch';
import { caseFilterChanged } from '../../containers/Filters/actions';
import { countActiveFilters, KnownQueryParams, SearchContextType } from '../../containers/queryParameters';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import RemoteDataComponent from '../../lib/RemoteData';
import RootSearchBar from '../App/RootSearchBar';
import { ICON_SIZE } from '../constVariables';
import { IconButton, InfiniteScrollContainer, SearchView, PageSpinner } from '../Shared';
import SearchGrid from '../Shared/SearchGrid';
import CaseFilters from './CaseFilters';
import styles from './CaseSearch.module.scss';
import { renderCaseResult, renderSearchHelper } from './helpers';

const CaseSearch = () => {
  const [searchContext] = useQueryParam(KnownQueryParams.CONTEXT);
  const { remoteDataState } = useSelector((state) => ({ ...state.caseSearch }));

  return (
    <>
      <div>
        <RootSearchBar />
      </div>

      <RemoteDataComponent
        type={remoteDataState}
        notAsked={() => renderSearchHelper(searchContext)}
        loading={() => <PageSpinner />}
        failure={(_) => <h1>Search failed</h1>}
        success={(_) => <CaseSearchSuccess caseContext={searchContext} />}
      />
    </>
  );
};

export default CaseSearch;

export const CaseSearchSuccess = ({ caseContext }) => {
  const dispatch = useDispatch();
  const {
    caseSearch: { caseResults, remoteNextPageDataState, remoteDataState },
    location,
    filters: { facets },
  } = useSelector(({ caseSearch, router, filters }) => ({
    caseSearch,
    location: router.location,
    filters,
  }));
  const { results: cases, hasMoreItems } = caseResults;
  const activeFiltersCount = countActiveFilters(location.search);
  const [pageQueryParam, setPageQueryParam] = useQueryParam([KnownQueryParams.PAGE], NumberParam);

  const [queryParamsToKeep, setQueryParamsToKeep] = useQueryParams({
    [KnownQueryParams.CONTEXT]: StringParam,
    [KnownQueryParams.SEARCH_TEXT]: StringParam,
    [KnownQueryParams.SORT_BY]: StringParam,
    [KnownQueryParams.SORT_DIRECTION]: StringParam,
  });

  const fetchNextPage = useCallback(() => {
    setPageQueryParam((pageQueryParam || 1) + 1, 'replaceIn');
    dispatch(fetchNextCasePage());
  }, [dispatch, pageQueryParam, setPageQueryParam]);

  const { scrollableRef, scrollToTop } = useInfiniteScroll(hasMoreItems, fetchNextPage);

  const clearAllFilters = useCallback(() => {
    scrollToTop();
    setQueryParamsToKeep(queryParamsToKeep, 'replace');
    dispatch(caseFilterChanged());
  }, [scrollToTop, setQueryParamsToKeep, queryParamsToKeep, dispatch]);

  const hasItems = cases.length > 0;
  const hasFilters = remoteDataState.isSuccess() && facets.length > 0;

  return (
    <SearchView>
      <SearchGrid
        leftActions={
          activeFiltersCount > 0 ? (
            <IconButton
              data-test-id="UVFPiGEvtMvMA-LQjDmWq"
              text={`Clear filters (${activeFiltersCount})`}
              icon={mdiClose}
              iconSize={ICON_SIZE}
              onClick={clearAllFilters}
            />
          ) : null
        }
        left={hasFilters ? <CaseFilters scrollToTop={scrollToTop} /> : <div />}
        middleActions={<div>{hasItems && <h1 className={styles.header}>Select a case to continue</h1>}</div>}
        middle={
          <InfiniteScrollContainer
            ref={scrollableRef}
            items={cases}
            remoteDataState={remoteNextPageDataState}
            hasMoreItems={hasMoreItems}
            fetchNextSearchResult={fetchNextPage}
            scrollToTop={scrollToTop}
          >
            {hasItems ? (
              cases.map((item) => renderCaseResult(caseContext, item))
            ) : (
              <EmptyResult searchText={queryParamsToKeep[KnownQueryParams.SEARCH_TEXT]} />
            )}
          </InfiniteScrollContainer>
        }
      />
    </SearchView>
  );
};

CaseSearchSuccess.propTypes = {
  caseContext: PropTypes.oneOf([SearchContextType.CLAIM, SearchContextType.COVER, SearchContextType.REINSURANCE]),
};
