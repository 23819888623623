export const generateYearOptions = (selectedValues = []) => {
  const thisYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = thisYear - 10; i <= thisYear + 2; i += 1) {
    yearOptions.push({
      value: i,
      displayName: i,
      selected: selectedValues.includes(i),
    });
  }
  return yearOptions;
};

export const sortReinsuranceContexts = (a, b) =>
  a.companyName.localeCompare(b.companyName) || a.businessAccount.localeCompare(b.businessAccount) || b.insuranceYear - a.insuranceYear;
