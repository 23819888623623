import { mdiMagnify } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { Contexts, ContextSearch, Navigator } from 'its-react-ui';
import { Just } from 'monet';
import PropTypes from 'prop-types';
import { prop, uniqBy, uniqWith } from 'ramda';
import React, { useEffect, useReducer } from 'react';
import SimpleBar from 'simplebar-react';
import RemoteData from '../../lib/remote-data';
import { useIsMobile } from '../../hooks/useIsMobile';
import { RoleCategoryEnum } from '../../lib';
import {
  closeModal,
  contextSelected,
  contextSelectedInSearch,
  getCoverInfo,
  hideSearch,
  openModal,
  searchContexts,
  setSelectedContextsGroups,
  showSearch,
  unselectReinsuranceContext,
} from './actions';
import {
  CLOSE_MODAL,
  GET_COVER_INFO,
  GET_COVER_INFO_ERROR,
  GET_COVER_INFO_SUCCESS,
  HIDE_SEARCH,
  NAVIGATE,
  OPEN_MODAL,
  SET_CONTEXTS_IN_SEARCH,
  SET_SEARCH_TEXT,
  SET_SELECTED_CONTEXTS,
  SET_SELECTED_CONTEXTS_GROUPS,
  SET_SELECTED_CLAIM_CONTEXTS_IN_SEARCH,
  UNSELECT_REINSURANCE_CONTEXT,
  SHOW_SEARCH,
  TOGGE_VERTICAL_LISTING,
  SET_SELECTED_REINSURANCE_CONTEXTS_IN_SEARCH,
  SET_SELECTED_COVER_CONTEXTS_IN_SEARCH,
} from './constants';
import InsuranceContextsContainer from './InsuranceContextsContainer';
import style from './InsuranceContextsSelector.module.scss';
import { generateYearOptions, sortReinsuranceContexts } from '../../lib/reinsuranceContext';

const initialState = {
  isSingleView: null, // set it during initialization
  contexts: [],
  isSearchMode: false,
  contextsInSearch: RemoteData.NotAsked,
  selectedContextsInSearch: [],
  selectedContexts: [],
  searchText: '',
  verticalListing: true,
  showModal: false,
  coversInfo: { data: {} }, // { data: { 'contextId': { isLoading: true, error: false } } }
  selectedContextId: null,
  navigatorActiveIndex: 0,
  activeRole: null,
};

/**
 * Get selected groups.
 *
 * @param {[[]]} groups groups
 * @param {[]} selectedContexts contexts
 * @returns {[]} selected groups
 */
const filterGroups = (groups, selectedContexts) =>
  groups.filter((contexts) => contexts.some((context) => selectedContexts.some((x) => x.id === context.id)));

/**
 * Concatenates two groups of covers.
 *
 * @param {[[]]} oldGroups
 * @param {[[]]} newGroups
 * @returns {[[]]} unique groups
 */
const concatGroups = (oldGroups, newGroups) => {
  const oldGroupsIds = oldGroups.flat().map((x) => x.id);
  const newGroupsIds = newGroups.flat().map((x) => x.id);
  const uniqueNewGroupsIds = newGroupsIds.filter((id) => !oldGroupsIds.includes(id));
  const uniqueNewGroups = newGroups.filter((contexts) => contexts.some((context) => uniqueNewGroupsIds.includes(context.id)));
  return oldGroups.concat(uniqueNewGroups);
};

const concatReinsuranceContexts = (previousSelectedContexts, newSelectedContexts) => {
  let flatContexts = [];
  newSelectedContexts.forEach((context) => {
    const { yearOptions, ...restOfContext } = context;

    const contexts = yearOptions
      .filter((x) => x.selected)
      .map(({ value }) => ({
        ...restOfContext,
        insuranceYear: value,
        selected: true,
      }));

    flatContexts = flatContexts.concat(contexts);
  });

  return uniqWith(
    (a, b) => a.companyNumber === b.companyNumber && a.businessAccountNumber === b.businessAccountNumber && a.insuranceYear === b.insuranceYear
  )(previousSelectedContexts.concat(flatContexts));
};

/**
 * Get updated contexts.
 *
 * @param {[]|[[]]} contexts claims or covers
 * @param {[]} selectedContexts selected items
 * @param {{ data: {} }} contextsInSearch items in search of daggy type
 * @param {[]} selectedContextsInSearch selected items in search - only used by covers
 * @param {string} activeRole context role type
 * @returns {{contexts, selectedContexts}} updated contexts and selected contexts
 */
const getUpdatedContexts = (contexts, selectedContexts, contextsInSearch, selectedContextsInSearch, activeRole) => {
  switch (activeRole.role) {
    case RoleCategoryEnum.CLAIMS_HANDLER: {
      const uniqSelectedContexts = uniqBy(prop('id'))(selectedContexts.concat(contextsInSearch.data.filter((x) => x.selected)));

      return {
        selectedContexts: uniqSelectedContexts,
        contexts: uniqSelectedContexts,
      };
    }

    case RoleCategoryEnum.UNDERWRITER: {
      const uniqSelectedContexts = uniqBy(prop('id'))(selectedContexts.concat(selectedContextsInSearch));
      return {
        selectedContexts: uniqSelectedContexts,
        contexts: concatGroups(contexts, filterGroups(contextsInSearch.data, selectedContextsInSearch)),
      };
    }

    case RoleCategoryEnum.REINSURANCE: {
      const reinsuranceContexts = concatReinsuranceContexts(
        contexts,
        contextsInSearch.data.filter((x) => x.selected)
      ).sort(sortReinsuranceContexts);

      return {
        selectedContexts: reinsuranceContexts,
        contexts: reinsuranceContexts,
      };
    }

    default:
      throw Error(`[${activeRole}] not implemented`);
  }
};

const reducer = (state, action) => {
  let updatedContexts;
  switch (action.type) {
    case HIDE_SEARCH:
      updatedContexts =
        state.contextsInSearch !== RemoteData.NotAsked
          ? getUpdatedContexts(state.contexts, state.selectedContexts, state.contextsInSearch, state.selectedContextsInSearch, state.activeRole)
          : {};
      return {
        ...state,
        ...updatedContexts,
        isSearchMode: false,
        navigatorActiveIndex: 0,
      };
    case SHOW_SEARCH:
      return {
        ...state,
        isSearchMode: true,
        searchText: '',
        contextsInSearch: RemoteData.NotAsked,
        selectedContextsInSearch: [],
      };
    case SET_SEARCH_TEXT:
      return { ...state, searchText: action.text };
    case TOGGE_VERTICAL_LISTING:
      return { ...state, verticalListing: !state.verticalListing };
    case CLOSE_MODAL:
      return { ...state, showModal: false };
    case OPEN_MODAL:
      return {
        ...state,
        showModal: true,
        selectedContextId: action.selectedContext.id,
      };
    case GET_COVER_INFO:
      state.coversInfo.data[action.contextId] = {
        isLoading: true,
        error: false,
      };
      return { ...state };
    case GET_COVER_INFO_SUCCESS:
      state.coversInfo.data[action.contextId] = {
        isLoading: false,
        error: false,
        ...action.context,
      };
      return { ...state };
    case GET_COVER_INFO_ERROR:
      state.coversInfo.data[action.contextId] = {
        isLoading: false,
        error: true,
      };
      return { ...state };
    case SET_CONTEXTS_IN_SEARCH: {
      updatedContexts =
        action.value === RemoteData.Loading && state.contextsInSearch !== RemoteData.NotAsked
          ? getUpdatedContexts(state.contexts, state.selectedContexts, state.contextsInSearch, state.selectedContextsInSearch, state.activeRole)
          : {};
      const newState = { ...state, ...updatedContexts };
      return { ...newState, contextsInSearch: action.value };
    }
    case SET_SELECTED_CLAIM_CONTEXTS_IN_SEARCH: {
      const index = state.contextsInSearch.data.findIndex((x) => x.id === action.context.id);
      const contexts = [...state.contextsInSearch.data];
      const clickedContext = contexts[index];

      contexts[index] = {
        ...clickedContext,
        selected: !clickedContext.selected,
      };

      return {
        ...state,
        contextsInSearch: RemoteData.Success(contexts),
      };
    }
    case SET_SELECTED_COVER_CONTEXTS_IN_SEARCH: {
      const newSelectedContextsInSearch = (
        action.isSelected
          ? state.selectedContextsInSearch.filter((x) => x.id !== action.context.id)
          : state.selectedContextsInSearch.concat(action.context)
      ).filter((x) => x.userHasAccess === true);

      return {
        ...state,
        selectedContextsInSearch: newSelectedContextsInSearch,
      };
    }
    case SET_SELECTED_CONTEXTS: {
      const needToSelect = !(state.isSingleView || state.selectedContexts.some((x) => x.id === action.context.id));
      const newSelectedContexts = needToSelect
        ? state.selectedContexts.concat(action.context)
        : state.selectedContexts.filter((x) => x.id !== action.context.id);
      const activeNavigatorIndex =
        state.contexts.length - 1 <= state.navigatorActiveIndex && state.navigatorActiveIndex > 0
          ? state.navigatorActiveIndex - 1
          : state.navigatorActiveIndex;
      return {
        ...state,
        contexts: state.isSingleView ? state.contexts.filter((x) => x.id !== action.context.id) : filterGroups(state.contexts, newSelectedContexts),
        selectedContexts: newSelectedContexts,
        navigatorActiveIndex: activeNavigatorIndex,
      };
    }
    case SET_SELECTED_REINSURANCE_CONTEXTS_IN_SEARCH: {
      const { context, selectedOptions } = action;
      const indexOfContextInState = state.contextsInSearch.data.findIndex((x) => x.id === context.id);
      let contextInState = state.contextsInSearch.data[indexOfContextInState];
      const isSelected = contextInState.selected;

      // if not selected -> add context with every or selected years
      if (!isSelected) {
        const hasSelectedOptions = selectedOptions && selectedOptions.length > 0;

        contextInState = {
          ...contextInState,
          selected: true,
          yearOptions: hasSelectedOptions
            ? generateYearOptions(selectedOptions.map((x) => x.value))
            : generateYearOptions(context.yearOptions.map((x) => x.value)),
        };

        const newSelection = state.contextsInSearch.data;
        newSelection[indexOfContextInState] = contextInState;
        return {
          ...state,
          contextsInSearch: RemoteData.Success(newSelection),
        };
      }

      // is selected but no selected years -> remove
      if (!selectedOptions || selectedOptions.length === 0) {
        // filter it out
        const newSelection = state.contextsInSearch.data.filter((x) => x.id !== context.id);
        return {
          ...state,
          contextsInSearch: RemoteData.Success(newSelection),
        };
      }

      // if it is selected and user pressed on one of the selected options
      const newContextList = state.contextsInSearch.data.map((c) => ({
        ...c,
        yearOptions: c.id === context.id ? generateYearOptions(selectedOptions.map((x) => x.value)) : c.yearOptions,
      }));

      return {
        ...state,
        contextsInSearch: RemoteData.Success(newContextList),
      };
    }
    case UNSELECT_REINSURANCE_CONTEXT: {
      const { context } = action;

      const contexts = state.contexts.filter((x) => !(x.id === context.id && x.insuranceYear === context.insuranceYear));

      return { ...state, selectedContexts: contexts, contexts };
    }
    case SET_SELECTED_CONTEXTS_GROUPS: {
      // this is called for covers only when user [un]select or toggle a group
      // If the last context was unselected, update the selected index to be the second last
      const activeNavigatorIndex =
        state.contexts.length - 1 <= state.navigatorActiveIndex && state.navigatorActiveIndex > 0
          ? state.navigatorActiveIndex - 1
          : state.navigatorActiveIndex;

      return {
        ...state,
        contexts: state.isSearchMode ? state.contexts : filterGroups(state.contexts, action.newSelectedContextList),
        selectedContexts: state.isSearchMode ? state.selectedContexts : action.newSelectedContextList,
        selectedContextsInSearch: state.isSearchMode ? action.newSelectedContextList : state.selectedContextsInSearch,
        navigatorActiveIndex: activeNavigatorIndex,
      };
    }
    case NAVIGATE:
      return {
        ...state,
        navigatorActiveIndex: action.direction.cata({
          Prev: () => state.navigatorActiveIndex - 1,
          Next: () => state.navigatorActiveIndex + 1,
        }),
      };
    default:
      return state;
  }
};

const getPreSelectedState = (preSelectedContexts, role) => {
  // Underwriting contexts has grouped view and we therfor return a list of the preSelectedContexts list
  if (role.includes('Underwriter')) {
    return [preSelectedContexts];
  }

  return preSelectedContexts.map((x) => ({ ...x, selected: true }));
};

const getHelpText = (role) => {
  switch (role) {
    case 'ClaimsHandler':
      return 'Search by text or numbers. You can search with vessel name, claim number or IMO.';
    case 'Underwriter':
      return 'Search by text or numbers. You can search with vessel name, risk number, cover note, fleet name or IMO';
    case 'Reinsurance':
      return 'Search by text. You can search with company name or business account';
    default:
      throw Error(`Finding help text for [${role}] has not been implemented`);
  }
};

const InsuranceContextsSelector = ({ setIsSearchMode, setSelectedInsuranceContexts, activeRole, infoText, preSelectedContexts = [] }) => {
  const showOpenClosedSearch = activeRole.role === RoleCategoryEnum.CLAIMS_HANDLER;
  const showGroupedView = activeRole.role === RoleCategoryEnum.UNDERWRITER;
  const isMobile = useIsMobile();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    isSingleView: !showGroupedView,
    activeRole,
    contexts: preSelectedContexts.length > 0 ? getPreSelectedState(preSelectedContexts, activeRole.role) : initialState.contexts,
    selectedContexts: preSelectedContexts.map((x) => ({
      ...x,
      selected: true,
    })),
  });

  const helpText = getHelpText(activeRole.role);
  const validation = {
    fields: { contexts: { valid: true } },
    showValidationResult: false,
  };

  const selectionAction = (context) => {
    if (activeRole.role === RoleCategoryEnum.REINSURANCE) {
      unselectReinsuranceContext(dispatch)(context);
      return;
    }

    contextSelected(dispatch)(context);
  };

  useEffect(() => {
    setSelectedInsuranceContexts(state.selectedContexts);
    // Setting the dependencies that the linter suggests will give an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedContexts.length]);

  useEffect(() => {
    if (setIsSearchMode) setIsSearchMode(state.isSearchMode);
  }, [state.isSearchMode]);

  const tryGetCoverInfo = (contextId) => {
    if (state.coversInfo.data[contextId]) return;
    getCoverInfo(dispatch, contextId);
  };

  return (
    <SimpleBar autoHide={false} style={{ maxHeight: isMobile ? '348px' : '600px' }}>
      <div className={style.wrap}>
        <div className={style.infoText}>{infoText}</div>
        <div className={style.scrollable}>
          {state.isSearchMode && (
            <ContextSearch
              helpText={helpText}
              activeRole={activeRole}
              isClaimHandler={showOpenClosedSearch}
              forceListResult={showGroupedView}
              onBackButtonClicked={hideSearch(dispatch)}
              searchTextChanged={(text) => dispatch({ type: SET_SEARCH_TEXT, text })}
              searchText={state.searchText}
              fetchSearchResult={searchContexts(dispatch)}
              searchResult={state.contextsInSearch}
              renderHelpText={(_) => <></>}
              renderContextList={(contexts) => {
                return (
                  <InsuranceContextsContainer
                    isGrouped={showGroupedView}
                    selectedContexts={Just(state.selectedContextsInSearch)}
                    verticalListing
                    contexts={!showGroupedView ? contexts : Just(contexts.map((x) => Just(x)))}
                    selectedContextId={state.selectedContextId}
                    contextSelected={contextSelectedInSearch(dispatch)}
                    closeModal={closeModal(dispatch)}
                    openModal={openModal(dispatch)}
                    showModal={state.showModal}
                    renderAsSearchResult
                    setSelectedContextsGroups={setSelectedContextsGroups(dispatch)}
                    coversInfo={state.coversInfo}
                    getCoverInfo={tryGetCoverInfo}
                    activeContextIndex={0}
                  />
                );
              }}
            />
          )}
          {!state.isSearchMode && (
            <Contexts
              contextList={Just(state.contexts)}
              toggleContextListing={() => dispatch({ type: TOGGE_VERTICAL_LISTING })}
              verticalListing={state.verticalListing}
              validation={validation}
              iconLinkToSearch={
                <MdiIcon data-test-id="F9_iokhCvL24m3pB5yyuZ" path={mdiMagnify} size="20px" color="black" onClick={showSearch(dispatch)} />
              }
              textLinkToSearch={<></>}
              renderContextsAndNavigator={(contexts) => (
                <>
                  <InsuranceContextsContainer
                    isGrouped={showGroupedView}
                    selectedContexts={Just(state.selectedContexts)}
                    verticalListing={state.verticalListing}
                    contexts={!showGroupedView ? contexts : Just(contexts.map((x) => Just(x)))}
                    selectedContextId={state.selectedContextId}
                    contextSelected={selectionAction}
                    closeModal={closeModal(dispatch)}
                    openModal={openModal(dispatch)}
                    showModal={state.showModal}
                    renderAsSearchResult={false}
                    setSelectedContextsGroups={setSelectedContextsGroups(dispatch)}
                    coversInfo={state.coversInfo}
                    getCoverInfo={tryGetCoverInfo}
                    activeContextIndex={state.navigatorActiveIndex}
                  />
                  {state.selectedContexts.length === 0 && <div className={style.helpText}>Search for contexts.</div>}
                  {!state.verticalListing && (
                    <Navigator
                      activeIndex={state.navigatorActiveIndex}
                      selectableIds={contexts.map((_, i) => i.toString())}
                      selectedIds={[]}
                      activeContextChanged={(direction) => dispatch({ type: NAVIGATE, direction })}
                    />
                  )}
                </>
              )}
            />
          )}
        </div>
      </div>
    </SimpleBar>
  );
};

InsuranceContextsSelector.propTypes = {
  setIsSearchMode: PropTypes.func,
  setSelectedInsuranceContexts: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  activeRole: PropTypes.object.isRequired,
  infoText: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  preSelectedContexts: PropTypes.array,
};

export default InsuranceContextsSelector;
