import React from 'react';
import { Link } from 'react-router-dom';
import s from './LoggedOutPage.module.scss';

const LoggedOutPage = () => (
  <div className={s.logOutPage}>
    <div className={s.text}>
      <h1>You are now logged out</h1>
    </div>
    <div className={s.loginLink}>
      <Link to="/login">Login</Link>
    </div>
  </div>
);

export default LoggedOutPage;
