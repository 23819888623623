import PropTypes from 'prop-types';
import React from 'react';
import { childrenShape } from '../../lib/customProptypes';
import style from './ModalMessage.module.scss';

const ModalMessage = ({ iconPath, header, text = '', children }) => (
  <div className={style.grid}>
    {iconPath && <img className={style.icon} src={iconPath} alt="Icon" />}
    <div className={style.header}>{header}</div>
    {text.trim().length > 0 && <div className={style.text}>{text}</div>}
    {children}
  </div>
);

ModalMessage.propTypes = {
  iconPath: PropTypes.string,
  header: PropTypes.string.isRequired,
  text: PropTypes.string,
  children: childrenShape,
};

export default ModalMessage;
