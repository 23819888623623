import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAccount } from './authenticationProvider';
import { getFeatureFlags } from '../apis';

export const FeatureEnum = Object.freeze({
  REINSURANCE: 'enableReinsurance',
  HELPANDNOTIFICATIONS: 'enableHelpAndNotifications',
  PDFSEARCH: 'enableDocumentPreviewSearch',
  PORTALSELECTOR: 'enablePortalSelector',
  LINKTOCOVERNOTEDOCS: 'enableLinkToCoverNoteDocs',
});

const FeatureFlagContext = createContext({});

export const useFeatureFlag = (featureEnum) => {
  const featureFlagContext = useContext(FeatureFlagContext);

  return !!featureFlagContext[featureEnum];
};

export const useFeatureFlags = () => useContext(FeatureFlagContext);

export const FeatureFlagProvider = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState({
    flagsLoaded: false,
  });
  const { isAuthenticated } = useAccount();

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      try {
        const flags = await getFeatureFlags();
        setFeatureFlags({ ...flags, flagsLoaded: true });
      } catch (error) {
        // Fail silently, all toggleable features disabled
      }
    };

    if (isAuthenticated) {
      fetchFeatureFlags();
    }
  }, [isAuthenticated]);

  return <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>;
};

FeatureFlagProvider.propTypes = {
  children: PropTypes.node,
};
