import { reducers as caseSearch } from './CaseSearch';
import { reducers as filters } from './Filters';
import searchResult from './SearchResult/reducer';
import { reducers as user } from './User';

const reducers = {
  filters,
  caseSearch,
  searchResult,
  user,
};

export default reducers;
