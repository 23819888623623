import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import worker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import { EventBus, PDFFindController, PDFLinkService } from 'pdfjs-dist/legacy/web/pdf_viewer';
import 'pdfjs-dist/legacy/web/pdf_viewer.css';
import { pdfHasOcrLayer } from '../../../lib/PdfUtils';
import { startOcrScan, getOcrStatus, getSavedOcrResult, saveOcrResult } from '../../../apis';
import PdfViewerSearchBar from './PdfViewerSearchBar';
import { buildPdfViewer, Paging, pdfDocumentProxy, showLoadingStatus, sleep } from './PdfViewerUtils';
import style from './PdfViewer.module.scss';

GlobalWorkerOptions.workerSrc = worker;

const PdfViewer = ({ src, fileId, isSinglePageViewer }) => {
  const [{ loading, doOcr, operationId, error }, setOcrStatus] = useState({ loading: true, doOcr: false, operationId: null, error: false });
  const ocrResultsRef = useRef(null);

  useEffect(() => {
    const getOcrText = async () => {
      try {
        const data = await getSavedOcrResult(fileId);
        ocrResultsRef.current = data.analyzeResult.readResults;
        setOcrStatus({ loading: false, doOcr: true, operationId: null, error: false });
      } catch (err) {
        try {
          const oId = await startOcrScan(fileId);
          setOcrStatus({ loading: true, doOcr: true, operationId: oId, error: false });
        } catch (er) {
          setOcrStatus({ loading: false, doOcr: true, operationId: null, error: true });
        }
      }
    };

    if (doOcr) getOcrText();
  }, [doOcr, fileId]);

  useEffect(() => {
    const pollResult = async () => {
      try {
        await sleep(3000);
        const data = await getOcrStatus(operationId);

        if (data.status === 'failed') {
          setOcrStatus({ loading: false, doOcr: true, operationId, error: true });
        } else if (data.status === 'succeeded') {
          ocrResultsRef.current = data.analyzeResult.readResults;
          saveOcrResult(fileId, data);
          setOcrStatus({ loading: false, doOcr: true, operationId, error: false });
        } else if (data.status === 'running') {
          pollResult();
        }
      } catch (err) {
        setOcrStatus({ loading: false, doOcr: true, operationId, error: true });
      }
    };

    if (operationId) pollResult();
  }, [operationId, fileId]);

  const pdfViewer = useRef(null);
  const viewerContainerRef = useRef(null);
  const eventBusRef = useRef(new EventBus());
  const linkServiceRef = useRef(new PDFLinkService({ eventBus: eventBusRef.current }));
  const findControllerRef = useRef(new PDFFindController({ eventBus: eventBusRef.current, linkService: linkServiceRef.current }));
  const pdfDocumentRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pagesAmount, setPagesAmount] = useState(null);

  const [matches, setMatches] = useState({ current: 0, total: 0 });
  const [matchesNavigationVisible, setMatchesNavigationVisible] = useState(false);

  const executeSearch = (isNewSearch, findPrevious, txt) => {
    setMatchesNavigationVisible(true);
    eventBusRef.current.dispatch('find', {
      type: isNewSearch ? '' : 'again',
      query: isNewSearch ? txt : findControllerRef.current.state.query,
      findPrevious,
    });
  };

  const hideSearchResults = () => {
    setMatchesNavigationVisible(false);
    eventBusRef.current.dispatch('findbarclose');
  };

  useEffect(() => {
    const eventBus = eventBusRef.current;
    eventBus.on('pagesinit', ({ source: { _pages } }) => {
      if (isSinglePageViewer) {
        viewerContainerRef.current.style.height = `${_pages[0].height + 40}px`;
        viewerContainerRef.current.parentElement.style.height = `${_pages[0].height + 60}px`;
      } else viewerContainerRef.current.parentElement.style.width = `${_pages[0].width + 50}px`;
    });
    eventBus.on('pagechanging', ({ pageNumber, source: { _pages } }) => {
      setCurrentPage(pageNumber);
      if (isSinglePageViewer) {
        viewerContainerRef.current.style.height = `${_pages[pageNumber - 1].height + 40}px`;
        viewerContainerRef.current.parentElement.style.height = `${_pages[pageNumber - 1].height + 60}px`;
      }
    });

    if (!isSinglePageViewer) {
      eventBus.on('updatefindmatchescount', ({ matchesCount }) => {
        setMatches(matchesCount);
      });
      eventBus.on('updatefindcontrolstate', ({ matchesCount }) => {
        setMatches(matchesCount);
      });
    }

    getDocument({ data: src }).promise.then((pdfDocument) => {
      pdfDocumentRef.current = pdfDocument;

      if (!isSinglePageViewer) {
        pdfHasOcrLayer(pdfDocument).then((hasOcrLayer) => {
          setOcrStatus({ loading: !hasOcrLayer, doOcr: !hasOcrLayer, operationId: null, error: false });
          if (!hasOcrLayer) return;

          pdfViewer.current = buildPdfViewer(false, eventBus, viewerContainerRef, linkServiceRef, findControllerRef, pdfDocument);
        });
      } else {
        pdfViewer.current = buildPdfViewer(true, eventBus, viewerContainerRef, linkServiceRef, findControllerRef, pdfDocument);
      }

      setPagesAmount(pdfDocument.numPages);
    });

    return () => {
      eventBus.off('pagesinit');
      eventBus.off('pagechanging');
      eventBus.off('updatefindmatchescount');
      eventBus.off('updatefindcontrolstate');
    };
  }, [src, isSinglePageViewer]);

  if (ocrResultsRef.current && ocrResultsRef.current.length > 0 && !pdfViewer.current) {
    pdfViewer.current = buildPdfViewer(
      false,
      eventBusRef.current,
      viewerContainerRef,
      linkServiceRef,
      findControllerRef,
      pdfDocumentProxy(pdfDocumentRef.current, ocrResultsRef.current)
    );
  }

  return (
    <div className={cn(style.wrap, { [style.modal]: !isSinglePageViewer })}>
      {!isSinglePageViewer && (
        <div className={style.searchBarWrap}>
          {showLoadingStatus(loading, error)}
          <PdfViewerSearchBar
            executeSearch={executeSearch}
            hideSearchResults={hideSearchResults}
            disabled={loading}
            matches={matches}
            matchesNavigationVisible={matchesNavigationVisible}
          />
          <div />
        </div>
      )}
      {pagesAmount && isSinglePageViewer && <Paging pdfViewer={pdfViewer} currentPage={currentPage} pagesAmount={pagesAmount} />}
      <div className={style.viewerContainer} ref={viewerContainerRef}>
        <div className="pdfViewer" />
      </div>
    </div>
  );
};

PdfViewer.propTypes = {
  src: PropTypes.instanceOf(ArrayBuffer),
  fileId: PropTypes.string,
  isSinglePageViewer: PropTypes.bool,
};

export default PdfViewer;
