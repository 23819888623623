import { mdiClose, mdiFile } from '@mdi/js';
import cn from 'classnames';
import { useField } from 'formik';
import { Icon } from 'its-react-ui';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { isMobile } from '../../../lib/helpers';
import { allowedFileTypes } from '../../../lib/media';
import * as constants from '../../constVariables';
import FormErrorMessage from '../../Shared/FormErrorMessage';
import IconButton from '../../Shared/IconButton';
import styles from './upload-files-form.module.scss';

const UploadStep = () => {
  const width = useWindowWidth();
  const isMobileLayout = isMobile(width);
  const [field, form, helpers] = useField({ name: 'files' });
  const files = useMemo(() => field.value.sort((a, b) => a.name.localeCompare(b.name)), [field.value]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.filter((a) => !files.find((f) => f.name === a.name));
      helpers.setValue([...files, ...newFiles]);
    },
    [files, helpers]
  );

  const removeFile = useCallback(
    (filename) => {
      const filteredFiles = files.filter((file) => file.name !== filename);
      helpers.setValue(filteredFiles);
    },
    [files, helpers]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept: allowedFileTypes,
    maxSize: 2147483647, // 2GB
    maxFiles: 100,
  });

  const rejectedFiles = useMemo(() => fileRejections.sort((a, b) => a.file.path.localeCompare(b.file.path)), [fileRejections]);

  return (
    <>
      <div
        {...getRootProps({
          className: cn(styles.dropzone, {
            [styles.isDragActive]: isDragActive,
            [styles.invalid]: !!form.error && form.touched,
          }),
        })}
      >
        <input name={field.name} {...getInputProps()} />
        <Icon path={mdiFile} size="60px" color={constants.BLUE_ICON_COLOR} />

        {!isMobileLayout && (isDragActive ? <p>Drop the files here ...</p> : <p>Drop files here or click to upload</p>)}

        {isMobileLayout && <p>Click to select files</p>}
        <FormErrorMessage name={field.name} />
      </div>

      {files.length > 0 && (
        <>
          <h2 className={styles.title}>Files ready to upload</h2>
          <ol className={styles.files}>
            {files.map((file) => (
              <li key={file.name} className={styles.selectedFile}>
                <span>{file.name}</span>
                <IconButton data-test-id="qcY28HgN4f3EP2NVRPx2e" icon={mdiClose} iconSize="16px" onClick={() => removeFile(file.name)} />
              </li>
            ))}
          </ol>
        </>
      )}

      {rejectedFiles.length > 0 && (
        <>
          <header className={styles.header}>
            <h2 className={styles.title}>Unsupported files</h2>
            <p>These will not be included in the upload</p>
          </header>
          <ol className={styles.files}>
            {rejectedFiles.map(({ file }) => (
              <li key={file.path}>{file.path}</li>
            ))}
          </ol>
        </>
      )}
    </>
  );
};

export default UploadStep;
