import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import useDialog from '../../hooks/useDialog';
import { documentResultShape } from '../../lib/customProptypes';
import ClaimInfo from '../ClaimInfo';
import CoverInfo from '../CoverInfo';
import ItsDialog, { DialogHeader } from '../Shared/ItsDialog';
import ClaimContexts from './ContextTable/ClaimContexts';
import CoverContexts from './ContextTable/CoverContexts';
import ReinsuranceContexts from './ContextTable/ReinsuranceContexts';

const DetailsTopContent = ({ item, showAll }) => {
  const [contextDetails, setContextDetails] = useState();
  const { isShowing, show, hide } = useDialog();
  const history = useHistory();
  const { contexts = [] } = item;
  if (contexts.length === 0) return <></>;

  const contextType = contexts[0].$type;

  const navigateToContext = (pathname) => {
    if (pathname !== history.location.pathname) {
      history.push(pathname);
    }
  };

  const openContextDetails = (event, context) => {
    event.stopPropagation();
    setContextDetails(context);
    show();
  };

  const renderContexts = () => {
    switch (contextType) {
      case 'ClaimContext':
        return <ClaimContexts showAll={showAll} contexts={contexts} navigateToContext={navigateToContext} showDetails={openContextDetails} />;

      case 'CoverContext':
        return <CoverContexts showAll={showAll} contexts={contexts} navigateToContext={navigateToContext} showDetails={openContextDetails} />;

      case 'ReinsuranceContext':
        return <ReinsuranceContexts showAll={showAll} contexts={contexts} navigateToContext={navigateToContext} />;

      default:
        throw new Error(`Unsupported context type, [${contextType}]`);
    }
  };

  const renderDialog = () => {
    switch (contextType) {
      case 'ClaimContext':
        return (
          <>
            <DialogHeader title="Claim information" close={hide} />
            <ClaimInfo context={contextDetails} />
          </>
        );

      case 'CoverContext':
        return (
          <>
            <DialogHeader title="Cover information" close={hide} />
            <CoverInfo context={contextDetails} />
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {renderContexts()}
      <ItsDialog isOpen={isShowing} onDismiss={hide} aria-label="Context details">
        {renderDialog()}
      </ItsDialog>
    </>
  );
};

DetailsTopContent.propTypes = {
  showAll: PropTypes.bool.isRequired,
  item: documentResultShape,
};

export default DetailsTopContent;
