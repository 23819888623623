const HttpStatus = Object.freeze({
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  NOT_ACCEPTED: 406,
  CONFLICT: 409,
  OK: 200,
});

export default HttpStatus;
