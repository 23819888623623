import { InsuranceContext } from '../../hooks/documents';
import XHR_STATUS from '../../lib/xhrStatus';

export const initialState: State = {
  componentStatus: XHR_STATUS.INITIAL,
  error: null,
  selectedContextsDestination: [],
  isSearchMode: false,
  handledDocumentsIds: [],
  selectedDocumentsIds: [],
  selectedContextsSource: null,
};

interface State {
  componentStatus: typeof XHR_STATUS[keyof typeof XHR_STATUS];
  error: string | null;
  selectedContextsDestination: InsuranceContext[];
  isSearchMode: boolean;
  handledDocumentsIds: string[];
  selectedDocumentsIds: string[];
  selectedContextsSource: string[] | null;
}

const actions = {
  setComponentStatus: 'signalR.setComponentStatus',
  setError: 'signalR.setError',
  setSelectedContextsDestination: 'signalR.setSelectedContextsDestination',
  setIsSearchMode: 'signalR.setIsSearchMode',
  setHandledDocumentsIds: 'signalR.setHandledDocumentsIds',
};
type ActionsType = keyof typeof actions;

export const reducer = (state: State, { type, payload }: { type: ActionsType; payload: any }) => {
  switch (type) {
    case actions.setComponentStatus:
      return { ...state, componentStatus: payload };
    case actions.setError:
      return { ...state, error: payload };
    case actions.setSelectedContextsDestination:
      return { ...state, selectedContextsDestination: payload };
    case actions.setIsSearchMode:
      return { ...state, isSearchMode: payload };
    case actions.setHandledDocumentsIds:
      return {
        ...state,
        handledDocumentsIds: state.handledDocumentsIds.concat(payload),
        componentStatus: state.selectedDocumentsIds?.length === state.handledDocumentsIds.length + 1 ? XHR_STATUS.SUCCESS : state.componentStatus,
      };
    default:
      throw new Error();
  }
};

type Dispatcher = (x: any) => void;

export const setComponentStatusAction = (dispatch: Dispatcher, x: any) => dispatch({ type: actions.setComponentStatus, payload: x });
export const setErrorAction = (dispatch: Dispatcher, x: string) => dispatch({ type: actions.setError, payload: x });
export const setSelectedContextsDestinationAction = (dispatch: Dispatcher) => (x: any) =>
  dispatch({ type: actions.setSelectedContextsDestination, payload: x });
export const setIsSearchModeAction = (dispatch: Dispatcher) => (x: any) => dispatch({ type: actions.setIsSearchMode, payload: x });
export const setHandledDocumentsIdsAction = (dispatch: Dispatcher) => (event: any) =>
  dispatch({ type: actions.setHandledDocumentsIds, payload: event.data });
