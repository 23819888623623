import compareAsc from 'date-fns/compareAsc';

const mapMetadata = (meta) =>
  meta
    ? {
        recipients: meta.recipients,
        otherRecipients: meta.otherRecipients,
        sender: meta.sender,
        subject: meta.subject,
        isPlainText: (meta.bodyFileName && meta.bodyFileName.endsWith('.txt')) || false,
      }
    : {};

const mapMetaDocument = (doc) => {
  // Since this is unstructured data we might not have a history
  // even though all documents should have a history.
  const [firstHistoryEntry] = (doc.history || []).slice().sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)));

  const user = (firstHistoryEntry && firstHistoryEntry.user && firstHistoryEntry.user.name) || undefined;
  const archivedDate = (firstHistoryEntry && firstHistoryEntry.date) || undefined;

  return {
    id: doc.id,
    contexts: doc.insuranceContexts,
    classification: doc.classification,
    date: doc.sortDate,
    archivedDate,
    description: doc.insuranceContexts && doc.insuranceContexts.length > 0 ? doc.insuranceContexts[0].description : '',
    files: doc.files,
    taxonomy: doc.taxonomy,
    user,
    notes: doc.notes,
    piiSection: doc.piiSection,
    logs: doc.logs,
    ...mapMetadata(doc.documentMetadata),
  };
};

export default mapMetaDocument;
