import useSWR from 'swr';
import client from '../apis/client';
import { addFavorite, removeFavorite } from '../apis/document';

const get = (url) => client.get(url).then((response) => response.data);
export const useFavorites = () => {
  const { data, mutate } = useSWR('user/favorites', get);
  const add = (documentType) => {
    addFavorite(documentType);
    mutate([...data, documentType], false);
  };
  const remove = (documentType) => {
    removeFavorite(documentType);
    mutate(
      data.filter((removed) => removed !== documentType),
      false
    );
  };
  return [data, add, remove];
};
