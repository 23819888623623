import React from 'react';
import { useParams } from 'react-router';
import { useIsMobile } from '../../hooks/useIsMobile';
import Details from '../Details/Details';
import Header from '../Header';
import s from './index.module.scss';
import Main from '../Shared/Main';

const DocumentViewer = () => {
  const { documentId } = useParams();
  const isMobile = useIsMobile();

  return (
    <>
      <Header />
      <Main className={s.wrap}>
        <div className={s.content}>
          <h1 className={s.title}>Document Viewer</h1>
          <Details documentId={documentId} showAll={!isMobile} />
        </div>
      </Main>
    </>
  );
};

export default DocumentViewer;
