import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@instech/components';
import { mdiCheck } from '@mdi/js';
import MdiIcon from '@mdi/react';
import style from './LoadingStatusMessage.module.scss';

const LoadingStatusMessage = ({ message, status }) => {
  return (
    <div className={style.loadingMessage}>
      {status === 'running' && <Loader />}
      {status === 'completed' && <MdiIcon path={mdiCheck} />}
      {status === 'failed' && <MdiIcon path={mdiCheck} />}
      <p>{message}</p>
    </div>
  );
};

LoadingStatusMessage.propTypes = {
  message: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['running', 'completed', 'failed']).isRequired,
};

export default LoadingStatusMessage;
