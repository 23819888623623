/* eslint-disable react/forbid-prop-types */
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import '@reach/tabs/styles.css';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from './Button';
import styles from './MultiStepForm.module.scss';

const StepState = Object.freeze({
  COMPLETED: 'completed',
  CURRENT: 'current',
  UPCOMING: 'upcoming',
});

const MultiStepForm = ({ initialValues = {}, steps, submitForm, navigationDisabled = false }) => {
  const [currentStepIndex, setCurrentStep] = useState(0);
  const isLastStep = () => currentStepIndex === steps.length - 1;
  const isFirstStep = () => currentStepIndex === 0;

  const { validationSchema } = steps[currentStepIndex];

  const findStepState = (index) => {
    if (index === currentStepIndex) {
      return StepState.CURRENT;
    }

    if (index < currentStepIndex) {
      return StepState.COMPLETED;
    }

    return StepState.UPCOMING;
  };

  const handleSubmit = async (values, actions) => {
    if (!isLastStep()) {
      handleNext();
      actions.setSubmitting(false);
      return;
    }

    submitForm(values, actions);
  };

  const handleNext = () => {
    const nextStep = currentStepIndex + 1;

    if (!isLastStep()) {
      setCurrentStep(nextStep);
    }
  };

  const handlePrevious = () => {
    setCurrentStep(Math.max(currentStepIndex - 1, 0));
  };

  const handleTabsChange = (index) => {
    setCurrentStep(index);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {(_) => (
        <Form>
          <Tabs index={currentStepIndex} onChange={handleTabsChange}>
            <TabList className={styles.tabLabels}>
              {steps.map((step, index) => {
                const stepState = findStepState(index);
                const tabDisabled = stepState === StepState.UPCOMING;

                return (
                  <Tab key={`${step.label}-label`} className={styles.tabLabel} disabled={tabDisabled || navigationDisabled}>
                    {step.label}
                  </Tab>
                );
              })}
            </TabList>

            <TabPanels>
              {steps.map((step) => (
                <TabPanel key={`${step.label}-panel`}>{step.component}</TabPanel>
              ))}
            </TabPanels>
          </Tabs>
          {steps.length > 0 && (
            <div className={styles.stepActions}>
              {!isFirstStep() && (
                <Button data-test-id="7CL7dlFJNVo9rXqjW33yT" typeStyle="outline" onClick={handlePrevious} disabled={navigationDisabled}>
                  Back
                </Button>
              )}

              <Button className={styles.submit} type="submit" disabled={navigationDisabled}>
                {isLastStep() ? 'Finish' : 'Next'}
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

MultiStepForm.propTypes = {
  initialValues: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
      validationSchema: PropTypes.object,
    })
  ).isRequired,
  navigationDisabled: PropTypes.bool,
};

export default MultiStepForm;
