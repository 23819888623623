import React from 'react';
import PropTypes from 'prop-types';
import { userPropType } from '../../lib/customProptypes';

const NameAndEmail = ({ person, className }) => (
  <div className={className}>
    {person && (
      <a href={`mailto:${person.address}`}>
        {person.name !== '' && <div>{person.name}</div>}
        <div>{person.address}</div>
      </a>
    )}
  </div>
);

NameAndEmail.propTypes = {
  person: userPropType,
  className: PropTypes.string,
};

export default NameAndEmail;
