import { mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { BLUE_ICON_COLOR } from '../constVariables';
import styles from './FileUploadProgress.module.scss';

const ProgressRing = ({ radius = 10, stroke = 2, progress, color = BLUE_ICON_COLOR }) => {
  const normalizedRadius = useMemo(() => radius - stroke * 2, [radius, stroke]);
  const circumference = useMemo(() => normalizedRadius * 2 * Math.PI, [normalizedRadius]);
  const strokeDashoffset = useMemo(() => circumference - (progress / 100) * circumference, [circumference, progress]);

  if (progress === 100) {
    return <Icon path={mdiCheck} size={`${radius * 2}px`} color={color} />;
  }

  return (
    <svg height={radius * 2} width={radius * 2}>
      <circle
        className={styles.progress}
        stroke={color}
        fill="transparent"
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset }}
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};

ProgressRing.propTypes = {
  radius: PropTypes.number,
  stroke: PropTypes.number,
  progress: PropTypes.number.isRequired,
  color: PropTypes.string,
};

export default ProgressRing;
