import { mdiFileDocumentBoxOutline, mdiStarOutline } from '@mdi/js';
import { useField } from 'formik';
import { ContentRow, DropdownField, InlineTextEdit, KeywordsRow } from 'its-react-ui';
import { bool, array } from 'prop-types';
import React, { useState, useMemo } from 'react';
import { toMaybe } from '../../lib';
import { useFavorites } from '../../hooks/useFavorites';

/** Requires a Formik Context */
export const DocumentTypeSelector = ({ disabled = false, fixedSize, documentTypeVMs }) => {
  const [, meta, helpers] = useField('documentType');
  const [, , keywordsHelper] = useField('keywords');
  const [favorites, add, remove] = useFavorites();

  const onSelect = (selected) => {
    keywordsHelper.setValue([]);
    helpers.setValue(selected.value);
  };

  const onFavorite = (option) => {
    if (favorites.includes(option.id)) {
      remove(option.id);
    } else {
      add(option.id);
    }
  };

  return (
    <ContentRow
      icon={mdiFileDocumentBoxOutline}
      title="Document Type"
      validation={{
        show: meta.error && meta.touched,
        valid: !meta.error,
      }}
    >
      <DropdownField
        values={documentTypeVMs}
        onSelect={onSelect}
        search={{
          enableSearch: true,
          objectFieldName: 'displayName',
          objectSelectedFieldName: undefined,
        }}
        fixedSize={fixedSize}
        fixedHeight
        disabled={disabled}
        onFavorite={onFavorite}
        withFavorites
        favorites={favorites}
      />
    </ContentRow>
  );
};

DocumentTypeSelector.propTypes = {
  disabled: bool,
  fixedSize: bool,
  documentTypeVMs: array.isRequired,
};

/** Requires a Formik Context */
export const ClassificationSelector = ({ classificationVMs }) => {
  const [, meta, helpers] = useField('classification');

  const onSelect = (selected) => {
    helpers.setValue(selected.value);
  };

  return (
    <ContentRow
      icon={mdiStarOutline}
      title="Classification"
      validation={{
        show: meta.error && meta.touched,
        valid: !meta.error,
      }}
    >
      <DropdownField values={classificationVMs} onSelect={onSelect} />
    </ContentRow>
  );
};

ClassificationSelector.propTypes = {
  classificationVMs: array.isRequired,
};

/** Requires a Formik Context */
export const NotesInput = () => {
  const [field, , helpers] = useField('notes');

  const onChange = (text) => {
    helpers.setValue(text);
  };

  return (
    <InlineTextEdit
      maxTextLength={160}
      onChange={onChange}
      placeholder="Click to add details where keywords are not sufficient"
      text={field.value || ''}
      textareaRows={2}
      title="Notes"
    />
  );
};

/** Requires a Formik Context */
export const KeywordsSelector = ({ tags }) => {
  const [field, , helpers] = useField('keywords');
  const [showAddKeywords, setShowAddKeywords] = useState(false);
  const [keywordSearchText, setKeywordSearchText] = useState('');

  const selectKeyword = (keyword) => {
    helpers.setValue([...field.value, keyword]);
  };

  const unselectKeyword = (keyword) => {
    const filteredSelectedKeywords = field.value.filter((k) => k.value !== keyword.value);
    helpers.setValue(filteredSelectedKeywords);
  };

  const keywordsVMs = useMemo(
    () =>
      tags
        .filter((k) => k.displayName.toLowerCase().includes(keywordSearchText.toLowerCase()))
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map((k) => mapKeywordsToVM(k)),
    [keywordSearchText, tags]
  );

  return (
    <KeywordsRow
      addKeyword={selectKeyword}
      addKeywords={showAddKeywords}
      deleteKeywordClicked={unselectKeyword}
      onNoKeyWords={() => null} // Not sure if this prop will ever be used, but it is marked as required
      searchResult={toMaybe(keywordsVMs)}
      searchString={keywordSearchText}
      searchStringChanged={setKeywordSearchText}
      selectedTags={toMaybe(field.value)}
      toggleAddKeywords={() => {
        setShowAddKeywords(!showAddKeywords);
        setKeywordSearchText('');
      }}
    />
  );
};

KeywordsSelector.propTypes = {
  tags: array.isRequired,
};

export const mapDocumentTypeToVM = (documentType, selected) => ({
  id: documentType.id,
  displayName: documentType.displayName,
  selected: documentType.name === selected,
  value: documentType.name,
});

export const mapClassificationToVM = (classification, selected) => ({
  displayName: classification.name,
  selected: classification.name === selected,
  value: classification.name,
  priority: classification.priority || 0,
});

const mapKeywordsToVM = (keyword) => ({
  ...keyword,
  value: keyword.name,
});
