import React from 'react';
import PropTypes from 'prop-types';
import { getFormatedDate, getFormatedTime } from '../../lib/helpers';
import styles from './DateTime.module.scss';

const DateTime = ({ date, showTime }) => (
  <div className={styles.wrap}>
    {showTime && <span>{getFormatedTime(date)}</span>}
    {getFormatedDate(date)}
  </div>
);

DateTime.propTypes = {
  date: PropTypes.string,
  showTime: PropTypes.bool.isRequired,
};

export default DateTime;
