import { useEffect } from 'react';

function useEventListener(elemRef, event, handler) {
  useEffect(() => {
    if (elemRef.current) elemRef.current.addEventListener(event, handler);
    return () => {
      if (elemRef.current) elemRef.current.removeEventListener(event, handler);
    };
  });
}

export default useEventListener;
