import React from 'react';
import PropTypes from 'prop-types';
import { mdiAlertCircleOutline, mdiSync, mdiArrowUp, mdiDownload } from '@mdi/js';
import MdiIcon from '@mdi/react';
import cn from 'classnames';
import { Icon } from 'its-react-ui';
import { ICON_SIZE } from '../constVariables';
import { remoteDataShape } from '../../lib/customProptypes';
import styles from './SearchResult.module.scss';

const { topLink, infiniteScroll, infiniteScrollClickable } = styles;

const Status = ({ isClickable, iconPath, text, onClick }) => (
  <div
    data-test-id="eWBPbj2YwY1SCmFQfE2xj"
    className={cn([infiniteScroll, isClickable ? infiniteScrollClickable : null])}
    onClick={() => isClickable && onClick()}
  >
    <MdiIcon path={iconPath} size={ICON_SIZE} />
    {text}
  </div>
);

Status.propTypes = {
  isClickable: PropTypes.bool.isRequired,
  iconPath: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

const InfiniteScroll = ({ items, searchResult, hasMoreItems, fetchNextSearchResult, scrollToTop }) => {
  const moreResultsText = hasMoreItems ? 'Fetch more results.' : 'No more results.';
  const moreResultsIcon = hasMoreItems ? mdiDownload : mdiAlertCircleOutline;
  return (
    <div>
      {searchResult.cata({
        NotAsked: () => (
          <Status
            data-test-id="w07bdCv55s_4w4ni_Uqkc"
            isClickable={hasMoreItems}
            text={moreResultsText}
            iconPath={moreResultsIcon}
            onClick={fetchNextSearchResult}
          />
        ),
        Loading: () => <Status isClickable={false} text="Hang on! Finding more results for you..." iconPath={mdiSync} />,
        Failure: () => <Status isClickable={false} text="Oops! Couldn't fetch more results. Try searching again!" iconPath={mdiAlertCircleOutline} />,
        Success: () => (
          <Status
            data-test-id="Pyb4ewiEFtZVyquMgHjMo"
            isClickable={hasMoreItems}
            text={moreResultsText}
            iconPath={moreResultsIcon}
            onClick={fetchNextSearchResult}
          />
        ),
      })}
      {items.length > 15 && (
        <div data-test-id="VuBdWnWEESUz-MIfLvVbi" className={topLink} onClick={scrollToTop}>
          <Icon path={mdiArrowUp} />
          <span>TAKE ME TO THE TOP</span>
        </div>
      )}
    </div>
  );
};

InfiniteScroll.propTypes = {
  fetchNextSearchResult: PropTypes.func.isRequired,
  hasMoreItems: PropTypes.bool.isRequired,
  searchResult: PropTypes.shape(remoteDataShape).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  scrollToTop: PropTypes.func,
};

export default InfiniteScroll;
