import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NumberParam, useQueryParam } from 'use-query-params';
import { EmptyResult, SortByMenu } from '..';
import { documentFilterChanged } from '../../containers/Filters/actions';
import { KnownQueryParams } from '../../containers/queryParameters';
import { fetchNextSearchResult as fetchNextSearchResultAction } from '../../containers/SearchResult/actions';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import RemoteDataComponent from '../../lib/RemoteData';
import RootSearchBar from '../App/RootSearchBar';
import MobileFilterControls from '../Filters/MobileFilterControls';
import { DocumentSearchHelper } from '../Search/SearchHelp';
import { SearchResult } from '../SearchResult';
import SelectionPanel from '../SelectionPanel';
import { InfiniteScrollContainer, PageSpinner } from '../Shared';
import DocumentFilters from './DocumentFilters';
import styles from './DocumentSearch.module.scss';

const DocumentSearchMobile = () => {
  const {
    searchResult: { hasMoreItems, remoteDataState },
    filters: { facets },
  } = useSelector(({ searchResult, filters }) => ({ searchResult, filters }));

  const dispatch = useDispatch();
  const [pageQueryParam, setPageQueryParam] = useQueryParam([KnownQueryParams.PAGE], NumberParam);

  const fetchNextSearchResult = useCallback(() => {
    setPageQueryParam((pageQueryParam || 1) + 1, 'replaceIn');
    dispatch(fetchNextSearchResultAction());
  }, [dispatch, pageQueryParam, setPageQueryParam]);

  const clearAllFilters = () => {
    dispatch(documentFilterChanged());
  };

  const { scrollToTop } = useInfiniteScroll(hasMoreItems, fetchNextSearchResult, true);

  const hasFilters = remoteDataState.isSuccess() && facets.length > 0;

  return (
    <>
      <RootSearchBar />
      {hasFilters && (
        <MobileFilterControls onClearFilters={clearAllFilters} additionalControls={<SortByMenu scrollToTop={scrollToTop} />}>
          <DocumentFilters />
        </MobileFilterControls>
      )}

      <RemoteDataComponent
        type={remoteDataState}
        notAsked={() => <DocumentSearchHelper className={styles.helpText} />}
        loading={() => <PageSpinner />}
        failure={(_) => <h1>Search failed</h1>}
        success={(_) => <DocumentSearchSuccessMobile fetchNextSearchResult={fetchNextSearchResult} scrollToTop={scrollToTop} />}
      />
    </>
  );
};

export default DocumentSearchMobile;

const DocumentSearchSuccessMobile = ({ scrollToTop, fetchNextSearchResult }) => {
  const {
    searchResult: { hasMoreItems, hasNoItems, currentView, remoteNextPageDataState },
  } = useSelector(({ searchResult }) => ({ searchResult }));

  const [searchText] = useQueryParam(KnownQueryParams.SEARCH_TEXT);

  return (
    <>
      {hasNoItems ? (
        <EmptyResult searchText={searchText} />
      ) : (
        <>
          <SelectionPanel />
          <InfiniteScrollContainer
            items={currentView}
            remoteDataState={remoteNextPageDataState}
            hasMoreItems={hasMoreItems}
            fetchNextSearchResult={fetchNextSearchResult}
            scrollToTop={scrollToTop}
          >
            <SearchResult isMobile />
          </InfiniteScrollContainer>
        </>
      )}
    </>
  );
};

DocumentSearchSuccessMobile.propTypes = {
  scrollToTop: PropTypes.func.isRequired,
  fetchNextSearchResult: PropTypes.func.isRequired,
};
