/* eslint-disable react/jsx-closing-tag-location */
import { mdiAlert, mdiChevronDown, mdiChevronUp, mdiClockOutline, mdiClose, mdiReload } from '@mdi/js';
import Icon from '@mdi/react';
import axios from 'axios';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { hasInvalidFilenames, upload } from '../../apis';
import useDialog from '../../hooks/useDialog';
import useUnload from '../../hooks/useUnload';
import useWindowWidth from '../../hooks/useWindowWidth';
import { isMobile } from '../../lib/helpers';
import HttpStatus from '../../lib/HttpStatus';
import { UploadStatusEnum, useFileUploader } from '../../providers/FileUploadProvider';
import { useForcedPiiNotifier } from '../../providers/ForcedPiiProvider';
import * as constants from '../constVariables';
import Button from './Button';
import styles from './FileUploadProgress.module.scss';
import IconButton from './IconButton';
import ItsDialog, { DialogHeader } from './ItsDialog';
import ProgressRing from './ProgressRing';

const MAX_FILE_UPLOADS = 5;
const FileUploadProgress = () => {
  const width = useWindowWidth();
  const isMobileLayout = isMobile(width);
  const { state, clearUploads, startUpload, uploadError, finishUpload, retryUpload, retryUploadWithModifications, setProgress } = useFileUploader();
  const items = Object.entries(state);
  const hasItems = items.length > 0;
  const filesUploading = items.filter(([_, item]) => item.status === UploadStatusEnum.UPLOADING).length;
  const filesError = items.filter(([_, item]) => item.status === UploadStatusEnum.ERROR).length;
  const filesInQueue = items.filter(([_, item]) => item.status === UploadStatusEnum.QUEUED).length;
  const { isShowing: cancelDialogIsShowing, show: showCancelDialog, hide: hideCancelDialog } = useDialog();
  const [showUploads, setShowUploads] = useState(true);
  const [cancelSource, setCancelSource] = useState(axios.CancelToken.source());

  useEffect(() => {
    if (filesInQueue > 0 && filesUploading < MAX_FILE_UPLOADS) {
      const numberToStart = MAX_FILE_UPLOADS - filesUploading;
      const queuedFiles = items.filter(([_, item]) => item.status === UploadStatusEnum.QUEUED).slice(0, numberToStart);
      queuedFiles.forEach(([trackerId, _]) => {
        startUpload(trackerId);
      });
    }
  }, [filesInQueue, filesUploading, items, startUpload]);

  useUnload((event) => {
    // User needs to confirm closing the window if there are ongoing uploads
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    if (filesUploading || filesError || filesInQueue) {
      event.preventDefault();
      event.returnValue = '';
    }
  });

  const clearFiles = () => {
    cancelSource.cancel();
    clearUploads();
    setCancelSource(axios.CancelToken.source());
  };

  const closeUploads = () => {
    if (!filesUploading && !filesError && !filesInQueue) {
      clearFiles();
      return;
    }

    showCancelDialog();
  };

  const userConfirmedCancel = () => {
    hideCancelDialog();
    clearFiles();
  };

  const toggleShowUploads = () => {
    setShowUploads(!showUploads);
  };

  if (!hasItems) {
    return null;
  }

  return (
    <>
      <div
        className={cn(styles.uploads, {
          [styles.hiddenMobile]: !showUploads && isMobileLayout,
        })}
        role="dialog"
      >
        <header className={styles.header}>
          {isMobileLayout && (
            <div className={styles.actions}>
              <IconButton
                data-test-id="9BYYFxe4Pbz_c2dZplYu5"
                className={styles.mobileChevron}
                icon={showUploads ? mdiChevronUp : mdiChevronDown}
                iconSize="20px"
                onClick={toggleShowUploads}
              />
            </div>
          )}

          <div>
            {filesUploading || filesError || filesInQueue
              ? `${filesUploading} file(s) uploading, ${filesError} failed, ${filesInQueue} queued`
              : 'All uploads completed'}
          </div>

          <div className={styles.actions}>
            {!isMobileLayout && (
              <IconButton
                data-test-id="p2pPQ24s0vjjApCy1NNMY"
                icon={showUploads ? mdiChevronUp : mdiChevronDown}
                iconSize="20px"
                onClick={toggleShowUploads}
              />
            )}
            <IconButton data-test-id="BfoYinCYLBJo5k2KoVCeb" icon={mdiClose} iconSize="20px" onClick={closeUploads} />
          </div>
        </header>

        {showUploads && (
          <SimpleBar autoHide={false} style={{ maxHeight: '320px' }}>
            <ul className={styles.items}>
              <li className={cn(styles.item, styles.info)}>Completed uploads might take a few minutes before they are shown within the case</li>
              {items.map(([trackerId, item]) => (
                <FileProgress
                  key={trackerId}
                  trackerId={trackerId}
                  item={item}
                  cancelToken={cancelSource.token}
                  finishUpload={finishUpload}
                  retryUpload={retryUpload}
                  retryUploadWithModifications={retryUploadWithModifications}
                  uploadError={uploadError}
                  setProgress={setProgress}
                />
              ))}
            </ul>
          </SimpleBar>
        )}
      </div>

      <ItsDialog isOpen={cancelDialogIsShowing} onDismiss={hideCancelDialog} aria-label="Confirm aborting file uploads">
        <DialogHeader title="Cancel unfinished uploads?" close={hideCancelDialog} />

        <div>
          <p>
            There are {filesUploading} file(s) uploading, {filesInQueue} queued and {filesError} failed. Would you like to cancel the unfinished
            uploads?
          </p>

          <div className={styles.dialogActions}>
            <Button data-test-id="dwlKrqxkUfiZ0DgXbhpMo" onClick={hideCancelDialog}>
              Continue uploading
            </Button>

            <Button data-test-id="KLCfEkzPMckeYd2M6vnQ9" onClick={userConfirmedCancel} typeStyle="light">
              Cancel unfinished uploads
            </Button>
          </div>
        </div>
      </ItsDialog>
    </>
  );
};

const ModifyDialogDetails = ({ data, cancelToken }) => {
  const [loading, setLoading] = useState(false);
  const [stateData] = useState(data);
  const [invalidList, setInvalidList] = useState(null);
  useEffect(() => {
    async function checkInvalid(formData) {
      try {
        const l = await hasInvalidFilenames(formData, cancelToken);
        setInvalidList(l.data);
      } catch (apiError) {
        if (axios.isCancel()) {
          return;
        }
        if (!apiError.response) return;

        if (apiError.response.status !== HttpStatus.OK) {
          throw new Error('fetching invalid file details');
        }
      } finally {
        setLoading(false);
      }
    }
    if (!loading && invalidList === null) {
      setLoading(true);
      checkInvalid(stateData);
    }
  }, [stateData, cancelToken, loading, invalidList]);

  const listItems = useCallback(() => {
    if (!invalidList || invalidList === undefined) return null;
    return invalidList.map((i) => <li key={i}>{i}</li>);
  }, [invalidList]);

  return loading ? <p>Loading...</p> : <ul>{listItems()}</ul>;
};

ModifyDialogDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  cancelToken: PropTypes.object.isRequired,
};

export default FileUploadProgress;

const FileProgress = ({ trackerId, item, cancelToken, uploadError, finishUpload, retryUpload, retryUploadWithModifications, setProgress }) => {
  const { status, progress, data, error } = item;
  const { addForcedPii } = useForcedPiiNotifier();
  const { isShowing: modifyDialog, show: openModifyDialog, hide: closeModifyDialog } = useDialog();
  const [loading, setLoading] = useState(false);
  const [allowRetry, setAllowRetry] = useState(true);
  const startUpload = useMemo(() => status === UploadStatusEnum.UPLOADING || status === UploadStatusEnum.MODIFY, [status]);

  useEffect(() => {
    function updateUploadProgress(requestProgress) {
      setProgress(trackerId, requestProgress);
      if (requestProgress === 100) {
        finishUpload(trackerId);
      }
    }

    async function activateUpload(formData, modify) {
      try {
        const { data: uploadResultData } = await upload(formData, cancelToken, updateUploadProgress, modify);
        if (uploadResultData.forcedPiiSection) {
          addForcedPii(uploadResultData);
        }
      } catch (apiError) {
        if (axios.isCancel(error)) {
          return;
        }
        let errorMessage = 'Unknown error';
        setAllowRetry(false);

        if (!apiError.response && apiError.message !== 'Network Error') return;

        if (!apiError.response && apiError.message === 'Network Error') {
          setAllowRetry(true);
          errorMessage = 'You need to close the file before uploading';
        } else {
          setAllowRetry(false);
          switch (apiError.response.status) {
            case HttpStatus.NOT_ACCEPTED:
              errorMessage = 'Expecting accept from user to allow modification';
              openModifyDialog();
              break;
            case HttpStatus.CONFLICT:
              errorMessage = 'The file has already been uploaded';
              break;
            case HttpStatus.BAD_REQUEST:
              errorMessage = 'The file type is not permitted or the content of the file does not match the file type, please contact support';
              break;
            case HttpStatus.FORBIDDEN:
              errorMessage = 'You do not have an access to perform this action';
              break;
            default:
              setAllowRetry(true);
              errorMessage = 'Unable to upload. Please try again, or contact support if the problem persists';
          }
        }

        uploadError(trackerId, errorMessage);
      } finally {
        setLoading(false);
      }
    }
    if (startUpload && !loading) {
      setLoading(true);
      activateUpload(data, status === UploadStatusEnum.MODIFY);
    }
  }, [cancelToken, data, error, finishUpload, loading, openModifyDialog, setProgress, startUpload, status, trackerId, uploadError]);

  const retry = useCallback(() => {
    retryUpload(trackerId);
  }, [retryUpload, trackerId]);

  const retryWithModifications = useCallback(() => {
    retryUploadWithModifications(trackerId);
    closeModifyDialog();
  }, [closeModifyDialog, retryUploadWithModifications, trackerId]);

  const renderStatus = useCallback(() => {
    switch (status) {
      case UploadStatusEnum.SUCCESS:
      case UploadStatusEnum.UPLOADING:
        return (
          <div className={styles.status}>
            <ProgressRing progress={progress} />
          </div>
        );

      case UploadStatusEnum.ERROR:
        return allowRetry ? <IconButton data-test-id="HrGdfOMNaRKZMZAXLU1OG" icon={mdiReload} iconSize="20px" onClick={retry} /> : null;

      case UploadStatusEnum.QUEUED:
        return (
          <div className={styles.status}>
            <Icon path={mdiClockOutline} size="20px" color={constants.GREY_ICON_COLOR} />
          </div>
        );

      default:
        return null;
    }
  }, [allowRetry, progress, retry, status]);

  return loading ? (
    <div>loading...</div>
  ) : (
    <>
      <li className={styles.item}>
        <div className={styles.filename}>
          <div className={styles.name}>{data.file.name}</div>
          {status === UploadStatusEnum.ERROR && (
            <div className={styles.uploadError}>
              <Icon path={mdiAlert} size="16px" color={constants.WARNING_COLOR} />
              <div>{error}</div>
            </div>
          )}
        </div>

        {renderStatus()}
      </li>
      <ItsDialog isOpen={modifyDialog} onDismiss={closeModifyDialog} aria-label="This e-mail contains attachments that might be a security risk">
        <DialogHeader title="This e-mail contains attachments that might be a security risk" close={closeModifyDialog} />

        <div>
          <p>You may only archive the e-mail without the following attachments:</p>
          <ModifyDialogDetails data={data} cancelToken={cancelToken} />

          <div className={styles.dialogActions}>
            <Button data-test-id="lZ5f41gyMCbDSvsQhCRN-" onClick={retryWithModifications}>
              Exclude files and continue
            </Button>

            <Button data-test-id="r-Ebd72ztSH77Ua308h3m" onClick={closeModifyDialog} typeStyle="light">
              Cancel archiving
            </Button>
          </div>
        </div>
      </ItsDialog>
    </>
  );
};

FileProgress.propTypes = {
  trackerId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    status: PropTypes.oneOf(Object.values(UploadStatusEnum)),
    progress: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
    error: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  cancelToken: PropTypes.object.isRequired,
  uploadError: PropTypes.func.isRequired,
  finishUpload: PropTypes.func.isRequired,
  retryUpload: PropTypes.func.isRequired,
  retryUploadWithModifications: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired,
};
