// See roles in  security-token-service/Its.IdentityServer.Constants/ApplicationRoles.cs
export const RoleCategoryEnum = Object.freeze({
  CLAIMS_HANDLER: 'ClaimsHandler', // ClaimsHandler and ClaimsHandlerPI
  UNDERWRITER: 'Underwriter', // Underwriter, UnderwriterEnergy, UnderwriterPI
  REINSURANCE: 'Reinsurance', // Reinsurance
  COMPLIANCE_OFFICER: 'ComplianceOfficer',
  BUSINESS_OPERATIONS: 'BusinessOperations',
});

export const isValidRole = (role) => Object.values(Roles).some((x) => x.value.toLowerCase() === role.toLowerCase());

export const Roles = Object.freeze({
  CLAIMS_HANDLER: { value: 'claimsHandler', name: 'Claims Handler' },
  CLAIMS_HANDLER_PI: { value: 'claimsHandlerPI', name: 'Claims Handler P&I' },
  UNDERWRITER: { value: 'underwriter', name: 'Underwriter' },
  UNDERWRITER_ENERGY: {
    value: 'underwriterEnergy',
    name: 'Underwriter Energy',
  },
  UNDERWRITER_PI: { value: 'underwriterPI', name: 'Underwriter P&I' },
  BUSINESS_OPERATIONS: {
    value: 'businessOperations',
    name: 'Business Operations',
  },
  COMPLIANCE_OFFICER: {
    value: 'complianceOfficer',
    name: 'Compliance Officer',
  },
  REINSURANCE: {
    value: 'reinsurance',
    name: 'Reinsurance',
  },
});
