import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '@mdi/react';
import { mdiAccountAlert } from '@mdi/js';
import { mapTaxonomyDisplayName } from '../../lib';
import { isEmailDocument } from '../../lib/helpers';
import { documentResultShape } from '../../lib/customProptypes';
import { emailData, fileData, getUniqueFileExtensions } from './itemBottomContent/helpers';
import styles from './itemBottomContent/ItemBottomContent.module.scss';
import Tag, { tagType } from '../Shared/Tag';
import FileExtension from '../Shared/FileExtension';
import TagTooltip from '../Shared/TagTooltip';
import FlexibleKeywordsContent from '../Shared/flexibleContent/FlexibleKeywordsContent';
import attachmentIcon from '../Icons/attachment.svg';
import { SMALL_ICON_SIZE } from '../constVariables';

const ItemBottomContent = ({ isMobile, item }) => {
  const [showFileExtensionsToolTip, toggleShowFileExtensionsToolTip] = useState(false);
  const isMail = isEmailDocument(item);
  const fileExtensions = getUniqueFileExtensions(item.files, !isMail);
  const containsPii = !!item.piiSection;

  return (
    <>
      <div className={styles.itemEmailContent}>
        <div className={styles.emailData}>{isMail ? emailData(item, isMobile) : fileData(item)}</div>
      </div>
      {isMobile && <div className={styles.mobileSubject}>{item.subject}</div>}
      <div className={styles.itemBottomLineContent}>
        <div className={styles.notes}>
          <div className={styles.notesContent}>{item.notes}</div>
        </div>
        <div className={styles.docTypeTagsAndAttachments}>
          <div className={styles.docType}>
            {item.taxonomy && item.taxonomy.documentType && <Tag text={mapTaxonomyDisplayName(item.taxonomy.documentType)} />}
          </div>
          <div className={styles.tagsAndAttachments}>
            <div>{containsPii && <Icon path={mdiAccountAlert} title="Includes personally identifiable information" size={SMALL_ICON_SIZE} />}</div>
            <div className={styles.tags}>
              <FlexibleKeywordsContent keywords={item.taxonomy ? item.taxonomy.documentTags : []} />
            </div>
            <div className={styles.attachments}>
              {fileExtensions.length > 0 && (
                <img
                  src={attachmentIcon}
                  alt="Attachments"
                  className={cn({
                    [styles.showAttachments]: fileExtensions.length > 0,
                  })}
                  onMouseEnter={() => toggleShowFileExtensionsToolTip(true)}
                  onMouseLeave={() => toggleShowFileExtensionsToolTip(false)}
                  type={tagType.icon}
                />
              )}
            </div>
            {fileExtensions.length > 0 && (
              <div
                className={cn(styles.tooltip, {
                  [styles.showExtensionsTooltip]: showFileExtensionsToolTip,
                })}
              >
                {fileExtensions.map((type) => (
                  <FileExtension key={type} type={type} className={styles.extension} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <TagTooltip />
    </>
  );
};

ItemBottomContent.propTypes = {
  isMobile: PropTypes.bool,
  item: documentResultShape,
};

export default ItemBottomContent;
