import { mdiCheck, mdiChevronDown, mdiChevronUp } from '@mdi/js';
import cn from 'classnames';
import Downshift from 'downshift';
import { Icon } from 'its-react-ui';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryParam } from 'use-query-params';
import { KnownQueryParams, SearchContextType, SearchContextTypeProperties } from '../../containers/queryParameters';
import { reset } from '../../containers/Search';
import { FeatureEnum, useFeatureFlag } from '../../providers/FeatureFlagProvider';
import * as constants from '../constVariables';
import styles from './SearchContextSelector.module.scss';

const {
  checkIconWrapper,
  highlighted,
  sectionTitle,
  selectContextOption,
  selectContextOptionList,
  selectContextSection,
  selectContextSectionOptions,
  selectContextTogglebutton,
  selectedSectionTitle,
} = styles;

/**
 * Groups objects from an array in a Map based on given property.
 * `key` is the value of the given property, and the `value` is a list of objects.
 * @param {object} arr
 * @param {string} prop
 */
export const mapBy = (arr, prop) => {
  const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
  arr.forEach((obj) => map.get(obj[prop]).push(obj));
  return map;
};

const sectionList = (options, getItemProps, highlightedIndex, selectedItem) => {
  const sectionMap = mapBy(options, 'section');
  const content = [...sectionMap.entries()].reduce(
    (result, [sectionName, sectionOptions]) => {
      result.sections.push(
        <div key={sectionName} className={selectContextSection}>
          <div className={sectionTitle}>
            <div>{sectionName}</div>
          </div>
          <ul className={selectContextSectionOptions}>
            {sectionOptions.map((option) => {
              const { itemIndex } = result;
              const isHighlighted = itemIndex === highlightedIndex;
              const isSelected = selectedItem === option.type;

              result.itemIndex += 1;
              return (
                <li
                  key={option.title}
                  className={cn(selectContextOption, {
                    [highlighted]: isHighlighted,
                  })}
                  {...getItemProps({
                    item: option.type,
                    index: itemIndex,
                  })}
                >
                  <div className={checkIconWrapper}>{isSelected && <Icon path={mdiCheck} size="20px" />}</div>
                  <span>{option.title}</span>
                </li>
              );
            })}
          </ul>
        </div>
      );
      return result;
    },
    { sections: [], itemIndex: 0 }
  );

  return content.sections;
};

const SearchContextSelector = ({ ...rest }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useQueryParam(KnownQueryParams.CONTEXT);
  const enableReinsurance = useFeatureFlag(FeatureEnum.REINSURANCE);
  let enabledContextTypes = [...SearchContextTypeProperties];

  if (!enableReinsurance) {
    enabledContextTypes = enabledContextTypes.filter((x) => x.type !== SearchContextType.REINSURANCE);
  }

  const { title: searchContextTitle, section: searchContextSection } = SearchContextTypeProperties.find((context) => context.type === query) ?? {
    title: '',
    section: '',
  };

  const changeSearchContext = useCallback(
    (contextType) => {
      setQuery(contextType, 'replace');
      dispatch(reset());
    },
    [dispatch, setQuery]
  );

  return (
    <Downshift initialSelectedItem={query} onChange={changeSearchContext}>
      {({ isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps }) => (
        <div {...rest}>
          {/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for  */}
          {/* label association is handled by Downshift through getLabelProps */}
          <label className="visually-hidden" {...getLabelProps()}>
            Choose context for your search
          </label>
          {/* eslint-enable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for  */}

          <button type="button" className={selectContextTogglebutton} {...getToggleButtonProps()}>
            <div>
              {isOpen ? (
                'Search for ...'
              ) : (
                <>
                  <div className={selectedSectionTitle}>{searchContextSection}</div>
                  <div>{searchContextTitle}</div>
                </>
              )}
            </div>

            <Icon path={isOpen ? mdiChevronUp : mdiChevronDown} size={constants.ICON_SIZE} color={constants.BLUE_ICON_COLOR} />
          </button>

          <div className={selectContextOptionList} {...getMenuProps()} style={{ padding: isOpen ? null : '0' }}>
            {isOpen && sectionList(enabledContextTypes, getItemProps, highlightedIndex, selectedItem)}
          </div>
        </div>
      )}
    </Downshift>
  );
};

export default SearchContextSelector;
