export const createClaimFacetGroups = (facetGroups, userIsClaimsHandler, userEmail, claimsHandlerEmailQueryParam) => {
  if (!facetGroups || !facetGroups.length) return [];

  // We remove claimsHandlerEmail group as it will be handled explicit based on query params.
  const claimFacetGroups = facetGroups.filter((x) => x.propertyName !== 'claimsHandlerEmail');

  const claimsHandlerEmailFacetGroup = facetGroups.find((f) => f.propertyName === 'claimsHandlerEmail');

  // Need this to support deep linking between users that has the 'Show my cases' filter checked in the url
  const otherClaimsHandlers = (
    Array.isArray(claimsHandlerEmailQueryParam)
      ? [...claimsHandlerEmailQueryParam]
      : claimsHandlerEmailQueryParam
      ? [claimsHandlerEmailQueryParam]
      : []
  ).filter((email) => email !== userEmail);

  const claimsHandlerEmailGroup = {
    label: 'User cases',
    propertyName: 'claimsHandlerEmail',
    facets: [],
  };

  if (userIsClaimsHandler) {
    const userFacet = claimsHandlerEmailFacetGroup && claimsHandlerEmailFacetGroup.facets.find((facet) => facet.value === userEmail);
    const facet = {
      label: 'Show my cases',
      count: userFacet ? userFacet.count : 0,
      value: userEmail,
    };
    claimsHandlerEmailGroup.facets.push(facet);
  }

  if (otherClaimsHandlers.length > 0) {
    otherClaimsHandlers.forEach((email) => {
      const userFacet = claimsHandlerEmailFacetGroup.facets.find((facet) => facet.value === email);
      const facet = {
        label: email,
        count: userFacet ? userFacet.count : 0,
        value: email,
      };
      claimsHandlerEmailGroup.facets.push(facet);
    });
  }

  if (claimsHandlerEmailGroup.facets.length > 0) {
    return createFacetGroupVms([claimsHandlerEmailGroup, ...claimFacetGroups]);
  }

  return createFacetGroupVms([...claimFacetGroups]);
};

export const createFacetGroupVms = (facetGroups, expanded = false) => {
  const groups = facetGroups.map((group) => ({
    ...group,
    expanded,
  }));

  return groups;
};

export const getUpdatedFacets = (currentFacetGroups, payloadFacets) => {
  const facets = [...currentFacetGroups];
  currentFacetGroups.forEach((facetGroup, index) => {
    const payloadGroup = payloadFacets.find((fg) => fg.propertyName === facetGroup.propertyName);

    const updatedFacets = facetGroup.facets.map((facet) => {
      const payloadFacet = payloadGroup && payloadGroup.facets.find((pf) => pf.value === facet.value);
      return {
        ...facet,
        count: payloadFacet ? payloadFacet.count : 0,
      };
    });

    facets[index] = { ...facetGroup, facets: updatedFacets };
  });

  return facets;
};
