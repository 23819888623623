import '@reach/tabs/styles.css';
import { useCallback, useEffect, useState } from 'react';
import XHR_STATUS from '../lib/xhrStatus';

const useHttpRequest = (requestFunction) => {
  const [contentState, setContentState] = useState(XHR_STATUS.INITIAL);
  const [data, setData] = useState();
  const [error, setError] = useState();

  const refetch = useCallback(async () => {
    setContentState(XHR_STATUS.LOADING);
    try {
      const result = await requestFunction();
      setData(result);
      setContentState(XHR_STATUS.SUCCESS);
    } catch (_) {
      setError('Unable to load resource');
      setContentState(XHR_STATUS.ERROR);
    }
  }, [requestFunction]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return { refetch, contentState, data, error };
};

export default useHttpRequest;
