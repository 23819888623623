import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosError, AxiosRequestConfig, isAxiosError } from 'axios';
import qs from 'qs';

const config: AxiosRequestConfig = {
  headers: {
    // https://docs.duendesoftware.com/identityserver/v7/bff/apis/local/#anti-forgery-header
    'X-CSRF': '1',
  },
};

export interface Claim {
  type: string;
  value: string;
}
export interface UserClaims {
  isAuthenticated: boolean;
  claims?: Claim[];
}

const addQueryParams = (endpoint: string, query: any) => {
  if (!query) return endpoint;
  const queryString = qs.stringify(query, { arrayFormat: 'repeat', format: 'RFC1738', skipNulls: true });
  return queryString ? `${endpoint}?${queryString}` : endpoint;
};

export const useClaims = () => {
  const [data, setData] = useState<UserClaims>();
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState(true);

  const fetchClaims = useCallback(async (params?: { slide?: boolean }): Promise<Claim[]> => {
    try {
      const result = await axios.get<Claim[]>(addQueryParams('/management/user', params), config);
      setData({ isAuthenticated: true, claims: result.data });
      return result?.data ?? [];
    } catch (err) {
      if (isAxiosError(err) && (<AxiosError>err).response?.status === 401) {
        setData({ isAuthenticated: false });
      } else {
        setError(err);
      }
    } finally {
      setLoading(false);
    }
    return [];
  }, []);

  const slideUserSession = useCallback(() => fetchClaims({ slide: true }), [fetchClaims]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchClaims();
  }, [fetchClaims]);

  return { data, error, loading, slideUserSession };
};
