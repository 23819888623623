import { addNamespace } from '../../lib/helpers';

const addNs = addNamespace('user');
export const FETCH_USER_ROLES = addNs('FETCH_USER_ROLES');
export const FETCH_USER_ROLES_SUCCESS = addNs('FETCH_USER_ROLES_SUCCESS');
export const FETCH_USER_ROLES_FAILURE = addNs('FETCH_USER_ROLES_FAILURE');
export const SET_USER = addNs('SET_USER');

export const hasRole = (user, roleName) => !!(user && user.roles && user.roles.find((role) => role.name.startsWith(roleName)));

export const hasAnyRole = (user) => !!(user && user.roles && user.roles.length > 0);
