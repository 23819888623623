import React, { useCallback, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { mdiClose } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { mutate } from 'swr';
import { WarningNotification } from '@instech/components';
import { copyDocuments as copyDocumentsRequest } from '../../apis/document';
import check from '../Icons/check.svg';
import cry from '../Icons/cry.svg';
import loaderModal from '../Icons/loader_modal.svg';
import InsuranceContextsSelector from '../InsuranceContextsSelector';
import ModalMessage from './ModalMessage';
import { unselectAllDocuments } from '../../containers/SearchResult/actions';
import { getRoleByContextType } from '../../containers/queryParameters';
import useSignalR from '../../hooks/useSignalR';
import HttpStatus from '../../lib/HttpStatus';
import XHR_STATUS from '../../lib/xhrStatus';
import {
  reducer,
  initialState,
  setComponentStatusAction,
  setErrorAction,
  setSelectedContextsDestinationAction,
  setIsSearchModeAction,
  setHandledDocumentsIdsAction,
} from './modalCopyMoveDocument';
import style from './ModalCopyDocument.module.scss';

const getErrorMessage = (resp) => {
  switch (resp.status) {
    case HttpStatus.BAD_REQUEST:
      return resp.data;
    case HttpStatus.FORBIDDEN:
      return 'You do not have an access to perform this action';
    default:
      return 'Something went wrong';
  }
};

const ModalCopyDocument = ({ closeModal }) => {
  const { selectedContexts, selectedDocumentsIds } = useSelector((state) => state.searchResult);

  const globalDispatch = useDispatch();

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    selectedDocumentsIds,
    selectedContextsSource: selectedContexts.map((x) => x.id),
  });
  const [getCorrelationId] = useSignalR(setHandledDocumentsIdsAction(dispatch));

  const copyDocuments = useCallback(async () => {
    setComponentStatusAction(dispatch, XHR_STATUS.LOADING);

    try {
      const correlationId = await getCorrelationId();
      await copyDocumentsRequest({
        contextsIdsSource: state.selectedContextsSource,
        insuranceContextsDestination: state.selectedContextsDestination,
        documentsIds: state.selectedDocumentsIds,
        correlationId,
      });

      state.selectedDocumentsIds.forEach((id) => {
        mutate(`metadata/${id}`);
      });
    } catch (error) {
      setErrorAction(dispatch, getErrorMessage(error.response));
      setComponentStatusAction(dispatch, XHR_STATUS.ERROR);
    } finally {
      globalDispatch(unselectAllDocuments);
    }
  }, [getCorrelationId, globalDispatch, state.selectedContextsDestination, state.selectedContextsSource, state.selectedDocumentsIds]);

  const areContextsHaveSameType = selectedContexts.length > 0 ? selectedContexts.every((x) => x.$type === selectedContexts[0].$type) : true;
  if (!areContextsHaveSameType) {
    return (
      <ModalMessage iconPath={cry} header="That didn't go well" text="All selected documents must have the same insurance context type.">
        <button data-test-id="mPsAPTkOYVHsAVlq2hER_" type="button" className={style.closeButton} onClick={() => closeModal()}>
          Close
        </button>
      </ModalMessage>
    );
  }

  const activeRole = selectedContexts.length > 0 ? getRoleByContextType(selectedContexts[0].$type) : null;
  const operationStatus = `Copied ${state.handledDocumentsIds.length} of ${state.selectedDocumentsIds.length} document(s)`;

  switch (state.componentStatus) {
    case XHR_STATUS.SUCCESS:
      return (
        <ModalMessage iconPath={check} header="Success" text="Document(s) copied.">
          <WarningNotification size="small" headingText="Usually it takes between 5 and 60 minutes to update data in the system." />
          <button data-test-id="ZDJ54S4pPkXtRjRice5Z1" type="button" className={style.closeButton} onClick={() => closeModal()}>
            Close
          </button>
        </ModalMessage>
      );
    case XHR_STATUS.ERROR:
      return (
        <ModalMessage iconPath={cry} header="That didn't go well" text={state.error}>
          <button data-test-id="Vl34mWBFHvTy9Mz5hCfhn" type="button" className={style.closeButton} onClick={() => closeModal()}>
            Close
          </button>
        </ModalMessage>
      );
    case XHR_STATUS.LOADING:
      return <ModalMessage iconPath={loaderModal} header="Please wait ..." text={operationStatus} />;
    default:
      return (
        <div className={style.wrap}>
          <div className={style.header}>
            <h1>Copy document(s)</h1>
            <MdiIcon data-test-id="IkyT05F3NlApBYFuOvIV1" path={mdiClose} onClick={() => closeModal()} />
          </div>
          <InsuranceContextsSelector
            setSelectedInsuranceContexts={setSelectedContextsDestinationAction(dispatch)}
            setIsSearchMode={setIsSearchModeAction(dispatch)}
            activeRole={activeRole}
            infoText="The document(s) will be copied to and archived under the selected context(s):"
          />
          {!state.isSearchMode && state.selectedContextsDestination.length > 0 && (
            <div className={style.buttons}>
              <button data-test-id="F4naYKdVEPRC5EhbflY_0" type="button" onClick={() => closeModal()}>
                Cancel
              </button>
              <button data-test-id="YcDaLeEG9u-Khqskgs8G-" type="button" onClick={copyDocuments}>
                Copy
              </button>
            </div>
          )}
        </div>
      );
  }
};

ModalCopyDocument.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalCopyDocument;
