import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { mdiCheckAll, mdiLayersOff } from '@mdi/js';
import { WarningNotification } from '@instech/components';
import { deleteDocuments as deleteDocumentsRequest } from '../../../apis/document';
import { deleteSelectedDocuments } from '../../../containers/SearchResult/actions';
import { useDetailsQueryParam } from '../../../hooks/documents';
import XHR_STATUS from '../../../lib/xhrStatus';
import check from '../../Icons/check.svg';
import cry from '../../Icons/cry.svg';
import loaderModal from '../../Icons/loader_modal.svg';
import removeFile from '../../Icons/remove_file.svg';
import style from './ModalDeleteDocument.module.scss';
import ModalMessage from '../ModalMessage';
import { IconButton } from '../../Shared';
import ContextSelector from './ContextSelector';
import { useDeleteDocumentSelection } from './DeleteDocumentSelectionProvider';

const ModalContent = ({ closeModal, deleteDocuments }) => {
  const { selectedDocumentsIds, currentView } = useSelector((state) => state.searchResult);
  const { selectAllDocumentsForDeletion, unselectAllDocumentsForDeletion, hasSelectedDocuments } = useDeleteDocumentSelection();

  const selectedDocuments = useMemo(() => currentView.filter((doc) => selectedDocumentsIds.includes(doc.id)), [currentView, selectedDocumentsIds]);

  const allowDelete = hasSelectedDocuments;

  const selectAll = () => selectAllDocumentsForDeletion();
  const unselectAll = () => unselectAllDocumentsForDeletion();

  return (
    <ModalMessage
      iconPath={removeFile}
      header="Select the context(s) to delete the document(s) from"
      text="Deleting a document from all contexts removes it from the system entirely."
    >
      <div className={style.header}>The document(s) will be deleted from the following context(s):</div>
      <div className={style.selectionActions}>
        <IconButton data-test-id="nljWs7x5148fp4JVPa5iH" icon={mdiCheckAll} text="Select all" onClick={selectAll} />
        <IconButton data-test-id="9l9cVLl_vp8hlGwqxx-nl" icon={mdiLayersOff} text="Clear all" onClick={unselectAll} />
      </div>
      <div className={style.documentsWrap}>
        {selectedDocuments.map((document) => (
          <ContextSelector key={document.id} document={document} />
        ))}
      </div>
      <div className={style.buttons}>
        <button data-test-id="qKJVfzrRH0XR71Et5V3ir" type="button" onClick={closeModal}>
          Cancel
        </button>
        <button data-test-id="-ObW3zXtal6ZHRefM9ecd" type="button" onClick={allowDelete ? deleteDocuments : undefined} disabled={!allowDelete}>
          Delete
        </button>
      </div>
    </ModalMessage>
  );
};

ModalContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteDocuments: PropTypes.func.isRequired,
};

const ModalDeleteDocument = ({ closeModal }) => {
  const { selectedDocumentsIds } = useSelector((state) => state.searchResult);
  const dispatch = useDispatch();

  const { unselectAllDocumentsForDeletion, selectedForDeletion } = useDeleteDocumentSelection();

  const [status, setStatus] = useState(XHR_STATUS.INITIAL);
  const [error, setError] = useState('Contact support or try again later.');
  const { detailsQueryParam, clearQueryParam } = useDetailsQueryParam();

  const deleteDocuments = useCallback(async () => {
    setStatus(XHR_STATUS.LOADING);

    try {
      await deleteDocumentsRequest(selectedForDeletion);
      if (selectedDocumentsIds.includes(detailsQueryParam)) {
        clearQueryParam();
      }

      unselectAllDocumentsForDeletion();
      dispatch(deleteSelectedDocuments());

      setStatus(XHR_STATUS.SUCCESS);
    } catch (apiError) {
      if (apiError.response && apiError.response.status === 403) {
        setError('You are not the owner of all the selected documents. Documents must be deleted by the owner');
      }
      setStatus(XHR_STATUS.ERROR);
    }
  }, [clearQueryParam, detailsQueryParam, dispatch, unselectAllDocumentsForDeletion, selectedDocumentsIds, selectedForDeletion]);

  switch (status) {
    case XHR_STATUS.SUCCESS:
      return (
        <ModalMessage iconPath={check} header="Success" text="Document(s) deleted.">
          <WarningNotification size="small" headingText="Usually it takes between 5 and 60 minutes to update data in the system." />
          <button data-test-id="1j4SkuVwJHZZqgtcVef8F" type="button" className={style.closeButton} onClick={closeModal}>
            Close
          </button>
        </ModalMessage>
      );
    case XHR_STATUS.ERROR:
      return (
        <ModalMessage iconPath={cry} header="That didn't go well" text={error}>
          <button data-test-id="D5BkSFdZwsGrSMviBqwCS" type="button" className={style.closeButton} onClick={closeModal}>
            Close
          </button>
        </ModalMessage>
      );
    case XHR_STATUS.LOADING:
      return <ModalMessage iconPath={loaderModal} header="Please wait ..." text="Deleting document(s)." />;
    default:
      return <ModalContent closeModal={closeModal} deleteDocuments={deleteDocuments} />;
  }
};

ModalDeleteDocument.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalDeleteDocument;
