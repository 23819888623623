import React, { useState } from 'react';
import cn from 'classnames';
import MdiIcon from '@mdi/react';
import { mdiArrowUp, mdiArrowDown, mdiMagnify, mdiClose } from '@mdi/js';
import PropTypes from 'prop-types';
import { IconButton } from '@instech/components';
import style from './PdfViewerSearchBar.module.scss';

const PdfViewerSearchBar = ({ executeSearch, hideSearchResults, disabled, matches, matchesNavigationVisible }) => {
  const [currentText, setCurrentText] = useState('');

  const onBackButtonClick = (e) => {
    e.preventDefault();
    executeSearch(false, true);
  };

  const onNextButtonClick = (e) => {
    e.preventDefault();
    executeSearch(false, false);
  };

  const handleSearch = () => {
    if (disabled) return;
    executeSearch(true, false, currentText);
  };

  const clearSearch = () => {
    if (disabled) return;

    setCurrentText('');
    hideSearchResults();
  };

  const keyUpHandler = (event) => {
    switch (event.key) {
      case 'Enter':
        if (currentText !== '') {
          handleSearch();
        } else {
          clearSearch();
        }
        break;
      case 'ArrowUp':
        onBackButtonClick(event);
        break;
      case 'ArrowDown':
        onNextButtonClick(event);
        break;
      case 'Escape':
        clearSearch();
        break;
      default:
        break;
    }
  };

  return (
    <div className={style.searchContainer}>
      <div className={style.inputSearch}>
        <input
          aria-label="search in document"
          type="search"
          id="search"
          value={currentText}
          onChange={(event) => setCurrentText(event.target.value)}
          onKeyUp={keyUpHandler}
          placeholder="Search in document"
          disabled={disabled}
        />
        <MdiIcon
          data-test-id="5puBncydUQZTGTicgl4oj"
          aria-label="clear"
          path={mdiClose}
          className={cn(style.clear, { [style.disabled]: disabled })}
          onClick={clearSearch}
        />
      </div>
      <MdiIcon
        data-test-id="OTWjlYOK5BsA34JkzwEdO"
        aria-label="search"
        path={mdiMagnify}
        className={cn(style.searchButton, { [style.disabled]: disabled })}
        onClick={handleSearch}
      />
      {matchesNavigationVisible && (
        <>
          <p className={style.searchResultCount}>
            <span>{matches.current}</span>/<span>{matches.total}</span>
          </p>
          <IconButton
            data-test-id="OYCbvaiGaCkrTwJR9NIdm"
            icon={<MdiIcon className={style.navigationArrow} aria-label="previous" path={mdiArrowUp} />}
            width="20px"
            height="20px"
            onClick={onBackButtonClick}
          />
          <IconButton
            data-test-id="w-blBgvHo8pa7fB-0PXPy"
            icon={<MdiIcon className={style.navigationArrow} aria-label="next" path={mdiArrowDown} />}
            width="20px"
            height="20px"
            onClick={onNextButtonClick}
          />
        </>
      )}
    </div>
  );
};

PdfViewerSearchBar.propTypes = {
  executeSearch: PropTypes.func,
  hideSearchResults: PropTypes.func,
  disabled: PropTypes.bool,
  matches: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number,
  }),
  matchesNavigationVisible: PropTypes.bool,
};

export default PdfViewerSearchBar;
