/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { getBlobAsText, getDownloadBlobAsync } from '../../apis';
import { useFile } from '../../hooks/documents';
import PageSpinner from './Spinner';
import style from './EmailViewer.module.scss';
import { PreformattedText } from './PreformattedText';
import { ScrollContainer } from './ScrollContainer';

interface EmailFrameProms {
  html: string;
}

export const EmailIFrame = ({ html }: EmailFrameProms) => {
  const [processedHtml, setProcessedHtml] = useState('');
  useEffect(() => {
    reinsertImages(HydrateHtml(html)).then((res) => {
      setProcessedHtml(res);
    });
  }, [html]);

  return (
    <iframe
      title="email"
      id="emailViewerIframe"
      className={style.emailViewer}
      style={{ border: 0 }}
      sandbox="allow-same-origin"
      srcDoc={processedHtml}
      onLoad={(f: any) => {
        if (f === null || f.target === null || f.target.contentDocument === null) return;
        f.target.style.height = `${f.target.contentDocument.body.scrollHeight + 150}px`;
        f.target.contentDocument.body.style.overflow.y = 'hidden';
      }}
    />
  );
};

const reinsertImages = async (html: string) => {
  // Find all image tags in email
  const images = [...html.matchAll(/<img.*?(?=src)src=["']fileid:([^'"]+)["'][^>]*>/g)];
  if (!images) return html;

  const imagePromises = [];
  for (const match of images) {
    imagePromises.push(getDownloadBlobAsync(match[1]));
  }

  const blobs = await Promise.all(imagePromises);

  let imageEmail = html;
  for (const blob of blobs) {
    const imageBlob = new Blob([blob]);
    const url = URL.createObjectURL(imageBlob);
    // Replace image tags with blob image url
    imageEmail = imageEmail.replace(/<img(.*?)(?=src)src=["']fileid:([^'"]+)["']([^>]*)>/, `<img$1 src="${url}"$3>`);
  }

  return imageEmail;
};

const HydrateHtml = (html: string) => html.replace(/<span>([^[<]*)\[(https?[^\]]*)\]<\/span>/gi, '<a target="_top" href="$2">$1</a>');

interface EmailViewerProms {
  id: string;
  isPlainText: boolean;
}

const EmailViewer = ({ id, isPlainText }: EmailViewerProms) => {
  const { data, isLoading, error } = useFile(id, false);
  if (isLoading) return <PageSpinner className={style.loader} />;
  if (error) return <div>Unexpected error</div>;
  if (isPlainText) return <pre>{data}</pre>;
  return <EmailIFrame html={data} />;
};

interface EmailLoaderProms {
  id: string;
  name: string;
}

interface IMail {
  body: string;
  bodyIsHtml: boolean;
}

export const EmailLoader = ({ id, name }: EmailLoaderProms) => {
  const [mail, setMail] = useState<IMail>();
  useEffect(() => {
    (async function fetchMail(mailId) {
      const b = await getBlobAsText(mailId)();
      const mailJson = JSON.parse(b);
      setMail({ body: mailJson.Body, bodyIsHtml: mailJson.BodyIsHtml });
    })(id);
  }, [id, name]);

  try {
    return (
      <ScrollContainer>{mail?.bodyIsHtml ? <EmailIFrame html={mail.body} /> : <PreformattedText>{mail?.body}</PreformattedText>}</ScrollContainer>
    );
  } catch (error) {
    return <pre> Failed loading mail preview </pre>;
  }
};

export default EmailViewer;
