import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { caseDocumentFilterChanged } from '../../containers/Filters/actions';
import { CaseDocumentDateFilter } from '../../containers/queryParameters';
import Filters from '../Filters';
import { DateRangeFilter } from '../Filters/DateFilters';
import styles from './CaseDocumentFilters.module.scss';

const CaseDocumentFilters = ({ scrollToTop }) => {
  const dispatch = useDispatch();
  const onFilterChanged = () => {
    dispatch(caseDocumentFilterChanged());
  };

  return (
    <Filters scrollToTop={scrollToTop} onChangeHandler={onFilterChanged} className={styles.filters}>
      <DateRangeFilter name={CaseDocumentDateFilter} onChangeHandler={onFilterChanged} />
    </Filters>
  );
};

CaseDocumentFilters.propTypes = {
  scrollToTop: PropTypes.func,
};

export default CaseDocumentFilters;
