/* eslint-disable no-multi-spaces */
import React from 'react';
import PropTypes from 'prop-types';
import { InsuranceContexts } from 'its-react-ui';
import { Nothing } from 'monet';

const InsuranceContextsContainer = ({
  isGrouped,
  activeContextIndex,
  selectedContexts,
  verticalListing,
  contexts,
  selectedContextId,
  contextSelected,
  closeModal,
  openModal,
  showModal,
  renderAsSearchResult,
  setSelectedContextsGroups,
  coversInfo,
  getCoverInfo,
}) => (
  <InsuranceContexts
    isGrouped={isGrouped}
    activeContextIndex={activeContextIndex} // is used for claims and covers
    selectedContexts={selectedContexts} // is used for claims and covers :: Maybe [ { id } ]
    verticalListing={verticalListing} // is used for claims and covers
    contextList={contexts} // is used for claims only :: [ { id } ]
    contextId={selectedContextId} // is used for claims only
    contextClicked={contextSelected} // is used for claims only
    closeModal={closeModal} // is used for claims only
    openModal={openModal} // is used for claims only
    showModal={showModal} // is used for claims only
    contextGroups={contexts} // is used for covers only :: Maybe [ Maybe [ { id } ] ]
    renderAsSearchResult={renderAsSearchResult} // is used for covers only
    contextGroupClicked={contextSelected} // is used for covers only
    setSelectedContexts={setSelectedContextsGroups} // is used for covers only
    coversInfo={coversInfo} // is used for covers only
    getCoverInfo={getCoverInfo} // is used for covers only
    coverInfo={null} // is used for covers that have been arhived when isGrouped=false
    initialContextGroups={Nothing()} // is used for covers to compare search results with analysis results
  />
); // updateAnalysisContextList is used for covers only to update analysis results

InsuranceContextsContainer.propTypes = {
  isGrouped: PropTypes.bool.isRequired,
  activeContextIndex: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedContexts: PropTypes.object.isRequired,
  verticalListing: PropTypes.bool.isRequired,
  contexts: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]).isRequired,
  selectedContextId: PropTypes.string,
  contextSelected: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  renderAsSearchResult: PropTypes.bool.isRequired,
  setSelectedContextsGroups: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  coversInfo: PropTypes.object.isRequired,
  getCoverInfo: PropTypes.func.isRequired,
};

export default InsuranceContextsContainer;
