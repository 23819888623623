import React, { createContext, useContext, useReducer, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';

const initialState = {};

const FileUploadContext = createContext(initialState);

export const UploadStatusEnum = Object.freeze({
  QUEUED: 'QUEUED',
  UPLOADING: 'UPLOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  MODIFY: 'MODIFY',
});

const QUEUE_FILE_UPLOADS = 'QUEUE_FILE_UPLOADS';
const SET_FILE_STATUS = 'SET_FILE_STATUS';
const SET_FILE_ERROR = 'SET_FILE_ERROR';
const SET_FILE_PROGRESS = 'SET_FILE_PROGRESS';
const CLEAR_UPLOADS = 'CLEAR_UPLOADS';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case QUEUE_FILE_UPLOADS: {
      const { queue } = payload;
      const newQueueItems = {};
      queue.forEach((file) => {
        const trackerId = uuid();
        newQueueItems[trackerId] = {
          data: file,
          status: UploadStatusEnum.QUEUED,
          progress: 0,
        };
      });

      return {
        ...state,
        ...newQueueItems,
      };
    }

    case SET_FILE_STATUS: {
      const item = state[payload.trackerId];
      if (!item) return state;
      return {
        ...state,
        [payload.trackerId]: {
          ...item,
          status: payload.status,
        },
      };
    }

    case SET_FILE_ERROR: {
      const item = state[payload.trackerId];
      if (!item) return state;
      return {
        ...state,
        [payload.trackerId]: {
          ...item,
          status: UploadStatusEnum.ERROR,
          error: payload.error,
          progress: 0,
        },
      };
    }

    case SET_FILE_PROGRESS: {
      const item = state[payload.trackerId];
      if (!item) return state;
      return {
        ...state,
        [payload.trackerId]: {
          ...item,
          progress: payload.progress,
        },
      };
    }

    case CLEAR_UPLOADS: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export const useFileUploader = () => {
  const { state, dispatch } = useContext(FileUploadContext);

  const queueUploads = (filesWithMeta) => {
    dispatch({
      type: QUEUE_FILE_UPLOADS,
      payload: {
        queue: filesWithMeta,
      },
    });
  };

  const setUploadStatus = (trackerId, status) => {
    dispatch({
      type: SET_FILE_STATUS,
      payload: {
        trackerId,
        status,
      },
    });
  };

  const startUpload = (trackerId) => {
    setUploadStatus(trackerId, UploadStatusEnum.UPLOADING);
  };

  const uploadError = (trackerId, errorData) => {
    dispatch({
      type: SET_FILE_ERROR,
      payload: { trackerId, error: errorData },
    });
  };

  const finishUpload = (trackerId) => {
    setUploadStatus(trackerId, UploadStatusEnum.SUCCESS);
  };

  const retryUpload = (trackerId) => {
    setUploadStatus(trackerId, UploadStatusEnum.QUEUED);
  };

  const retryUploadWithModifications = (trackerId) => {
    setUploadStatus(trackerId, UploadStatusEnum.MODIFY);
  };

  const clearUploads = () => {
    dispatch({ type: CLEAR_UPLOADS });
  };

  const setProgress = (trackerId, progress) => {
    dispatch({
      type: SET_FILE_PROGRESS,
      payload: {
        trackerId,
        progress,
      },
    });
  };

  return {
    state,
    clearUploads,
    finishUpload,
    queueUploads,
    retryUpload,
    retryUploadWithModifications,
    setProgress,
    startUpload,
    uploadError,
  };
};

export const FileUploadProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );

  return <FileUploadContext.Provider value={value}>{children}</FileUploadContext.Provider>;
};

FileUploadProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
