import React from 'react';
import { useLocation, useParams } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { countActiveFilters, KnownQueryParams } from '../../containers/queryParameters';
import { EmptyResult } from '../SearchResult';

const CaseDocumentEmptyResults = () => {
  const { caseType } = useParams();
  const location = useLocation();
  const [searchQueryParam] = useQueryParam([KnownQueryParams.SEARCH_TEXT], StringParam);
  const activeFiltersCount = countActiveFilters(location.search);

  const hasSearched = searchQueryParam;
  if (hasSearched) {
    return <EmptyResult searchText={searchQueryParam} hintText="Try checking your spelling for mistakes or adjust your filters" />;
  }

  const hasFiltered = activeFiltersCount > 0;
  if (hasFiltered) {
    return <EmptyResult message="Sorry we didn't find any results based on" searchText="the selected filters" hintText="Try adjusting the filters" />;
  }

  return <EmptyResult message={`This ${caseType} has no documents`} hintText="" />;
};

export default CaseDocumentEmptyResults;
