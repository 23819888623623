import PropTypes from 'prop-types';

const isDefined = (props, propName, componentName) => {
  if (!Object.prototype.hasOwnProperty.call(props, propName)) {
    return new Error(`A maybe has to have a val prop: ${propName} - ${componentName}`);
  }
  return null;
};

export const maybeShape = {
  '@@type': PropTypes.string.isRequired,
  isValue: PropTypes.bool.isRequired,
  val: isDefined,
};
