import { useEffect, useRef } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

const useSignalR = (eventHandler) => {
  const connection = useRef(null);

  const getCorrelationId = () => {
    return connection.current.invoke('DmsGetCorrelationId');
  };

  useEffect(() => {
    connection.current = new HubConnectionBuilder().withUrl('/hub').configureLogging(LogLevel.Warning).build();
    connection.current.start().then(() => {
      connection.current.on('DmsEvent', (event) => {
        eventHandler(event);
      });
    });

    return () => {
      connection.current.stop().then(() => {});
    };
  }, []);

  return [getCorrelationId];
};

export default useSignalR;
