import React, { useRef, useState } from 'react';
import { RovingTabIndexProvider, useFocusEffect, useRovingTabIndex } from 'react-roving-tabindex';
import SimpleBar from 'simplebar-react';
import { func, node, array } from 'prop-types';
import { ENTER, SPACE } from '../../constVariables';
import Button from '../../Shared/Button';
import styles from './ContextTable.module.scss';

const rowLimit = 5;

const ContextTable = ({ headers, rows }) => {
  const [showAll, setShowAll] = useState(false);
  const rowsToShow = showAll ? rows : rows.slice(0, rowLimit);
  const toggleShowAll = () => setShowAll(!showAll);

  return (
    <>
      <div id="context-table-description" className="visually-hidden">
        The document is archived on the following documents
      </div>

      <div id="click-row-description" className="visually-hidden">
        Click to navigate to context
      </div>

      <RovingTabIndexProvider options={{ direction: 'vertical' }}>
        <SimpleBar autoHide={false} className={styles.scrollWrap}>
          <table className={styles.contexts} role="grid" aria-labelledby="context-table-description">
            <thead>{headers}</thead>
            <tbody>{rowsToShow}</tbody>
          </table>
        </SimpleBar>
      </RovingTabIndexProvider>

      {rows.length > rowLimit && (
        <Button data-test-id="mFfcJs3o69u_Eexexvs4z" className={styles.toggleMore} onClick={toggleShowAll}>
          {!showAll && <>Show {rows.length - rowLimit} more context(s)</>}
          {showAll && <>Show less contexts</>}
        </Button>
      )}
    </>
  );
};

ContextTable.propTypes = {
  rows: array.isRequired,
  headers: node.isRequired,
};

export default ContextTable;

export const ContextRow = ({ children, onRowClick }) => {
  const ref = useRef(null);
  const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(ref);

  useFocusEffect(focused, ref);

  const onClick = (event) => {
    handleClick(event);
    if (onRowClick) onRowClick();
  };

  const onKeyDown = (event) => {
    handleKeyDown(event);

    if (event.keyCode === ENTER || event.keyCode === SPACE) {
      if (onRowClick) onRowClick();
    }
  };

  return (
    <tr
      data-test-id="z5CQi_qyW0cdcU25URUj9"
      ref={ref}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
      aria-describedby="click-row-description"
    >
      {children}
    </tr>
  );
};

ContextRow.propTypes = {
  children: node.isRequired,
  onRowClick: func,
};
