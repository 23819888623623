import { getClaimSearchResult, getCoverSearchResult, getReinsuranceSearchResult } from '../../apis';
import { mapSearchCasePayload, mapSearchClaimsPayload } from '../../apis/mappers/mapSearchPayload';
import { toAction } from '../../lib';
import { decodeQueryParams, KnownQueryParams, SearchContextType } from '../queryParameters';
import { FETCH_CASE_NEXT_PAGE, FETCH_CASE_NEXT_PAGE_FAILURE, FETCH_CASE_NEXT_PAGE_SUCCESS } from './constants';

const fetchNextCasePageTemp = (fetchCallback) => async (dispatch) => {
  try {
    const results = await fetchCallback();
    dispatch(toAction(FETCH_CASE_NEXT_PAGE_SUCCESS, results));
  } catch (error) {
    dispatch(toAction(FETCH_CASE_NEXT_PAGE_FAILURE, error));
  }
};

export const fetchNextCasePage = () => (dispatch, getState) => {
  const {
    router: { location },
  } = getState();

  dispatch(toAction(FETCH_CASE_NEXT_PAGE));

  const queryParams = decodeQueryParams(location.search);
  const searchContext = queryParams[KnownQueryParams.CONTEXT];

  let fetchCallback;
  switch (searchContext) {
    case SearchContextType.CLAIM: {
      const payload = mapSearchClaimsPayload(queryParams);
      fetchCallback = () => getClaimSearchResult(payload);
      break;
    }
    case SearchContextType.COVER: {
      const payload = mapSearchCasePayload(queryParams);
      fetchCallback = () => getCoverSearchResult(payload);
      break;
    }
    case SearchContextType.REINSURANCE: {
      const payload = mapSearchCasePayload(queryParams);
      fetchCallback = () => getReinsuranceSearchResult(payload);
      break;
    }
    default:
      throw Error(`The selected context is unhandled, context was: ${searchContext}`);
  }

  dispatch(fetchNextCasePageTemp(fetchCallback));
};
