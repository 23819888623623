import { mdiAccount, mdiChevronDown, mdiExitToApp, mdiFaceAgent } from '@mdi/js';
import { UserMenu } from 'insify-remote-component-loader';
import { Icon } from 'its-react-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useAccount } from '../../providers/authenticationProvider';
import { userPropType } from '../../lib/customProptypes';
import { FeatureEnum, useFeatureFlag } from '../../providers/FeatureFlagProvider';
import * as constants from '../constVariables';
import { useModalContext } from '../Modal/ModalProvider';
import ModalUserProfile from '../Modal/ModalUserProfile';
import styles from './Header.module.scss';
import variables from '../../scss/variables.scss';

const { maincolor, white } = variables;

const LogoutButton = ({ logout }) => (
  <div data-test-id="BG0v6q32At_T3mdYLR9gl" className={`${styles.logout} ${styles.userMenuItem}`} onClick={logout}>
    <div className={styles.logoutButton}>
      <Icon path={mdiExitToApp} size={constants.SMALL_ICON_SIZE} color={maincolor} />
      SIGN OUT
    </div>
  </div>
);

LogoutButton.propTypes = {
  logout: PropTypes.func,
};

const UserMenuTooltip = ({ user, logout }) => {
  const { openModal } = useModalContext();
  const openUserProfile = () => openModal({ component: ModalUserProfile });

  return (
    <div className={styles.userMenuTooltip}>
      <div className={styles.userName}>{user.name}</div>
      <div data-test-id="k9tPEmmvc9SsXaRDjDDoB" className={styles.userMenuItem} onClick={openUserProfile}>
        User profile
      </div>
      <LogoutButton logout={logout} />
    </div>
  );
};

UserMenuTooltip.propTypes = {
  user: userPropType,
  logout: PropTypes.func,
};

const UserFields = () => {
  const { user, logout, ...account } = useAccount();
  const accountInfo = useSelector((state) => state.user);
  const helpAndNotifcationsEnabled = useFeatureFlag(FeatureEnum.HELPANDNOTIFICATIONS);
  const { openModal } = useModalContext();
  const openUserProfile = () => openModal({ component: ModalUserProfile });
  const history = useHistory();

  if (!user) {
    return null;
  }

  const role = accountInfo.roles.map((x) => x.displayName).join(', ');
  if (helpAndNotifcationsEnabled) {
    const links = [
      {
        href: '/announcements',
        title: 'Announcements',
      },
      {
        title: 'Edit My Sections',
        onClick: () => openUserProfile(),
      },
      {
        title: 'Support',
        onClick: () => {
          window.open('https://instech.atlassian.net/servicedesk/customer/portal/9', '_blank');
        },
      },
    ];
    const portal = {
      name: 'document',
      displayName: 'Document',
    };

    return (
      <div className={styles.remoteUserMenu}>
        {role && (
          <UserMenu
            account={{ user, logout, ...account }}
            history={history}
            links={links}
            portal={portal}
            role={{
              displayName: role,
              name: role,
            }}
          />
        )}
      </div>
    );
  }

  return (
    <>
      <div className={styles.userMenu} href="#">
        <div className={styles.userIcon}>
          <Icon path={mdiAccount} size={constants.ICON_SIZE} color={white} />
        </div>
        <div className={styles.userMenuParts}>
          <div className={styles.userNameRole}>
            <div>{user.name}</div>
            <div className={styles.userRole}>{role}</div>
          </div>
          <Icon path={mdiChevronDown} size={constants.LOGIN_ICON_SIZE} color={white} />
        </div>
        <UserMenuTooltip user={user} logout={logout} />
      </div>
      <a
        href="https://instech.atlassian.net/servicedesk/customer/portal/9"
        target="_blank"
        title="Support"
        rel="noreferrer"
        className={styles.support}
      >
        <Icon path={mdiFaceAgent} size={constants.ICON_SIZE} />
        <span>Support</span>
      </a>
    </>
  );
};

export default UserFields;
