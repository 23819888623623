import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Button.module.scss';

const ButtonTypeStyle = Object.freeze({
  PRIMARY: 'primary',
  OUTLINE: 'outline',
  LIGHT: 'light',
  TRANSPARENT: 'transparent',
  LINK: 'link',
});

const Button = ({ children, className, typeStyle = ButtonTypeStyle.PRIMARY, ...rest }) => (
  <button type="button" className={cn(styles.nhcButton, styles[typeStyle], className)} {...rest}>
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  typeStyle: PropTypes.oneOf(Object.values(ButtonTypeStyle)),
};

export default Button;
