import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { useAccount } from '../../providers/authenticationProvider';

const redirectRoute = (location) => {
  const { state } = location;
  if (state && state.from) return state.from.pathname + state.from.search;
  return '/';
};

const Login = styled.div`
  background-color: #003c71;
  height: 100%;
`;

const LoginPage = ({ location }) => {
  const { login } = useAccount();
  const route = redirectRoute(location);
  login({ state: { route } });

  return (
    <Login>
      <h1>Please log in to continue</h1>
    </Login>
  );
};

LoginPage.propTypes = {
  location: PropTypes.shape({}),
};

export default withRouter(LoginPage);
