import PropTypes from 'prop-types';
import React from 'react';
import styles from './flexibleContent/FlexibleContent.module.scss';

// Based on https://github.com/theKashey/react-reflexible
// The component is very dependant on explicit height,
// the content height should not be less than the height property.
const FlexibleContent = ({ content, height = 22 }) =>
  content.reduceRight((acc, item) => (acc ? <FlexibleRender display={item} failback={acc} height={height} /> : item), null);

FlexibleContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.node).isRequired,
  height: PropTypes.number,
};

export default FlexibleContent;

const FlexibleRender = ({ display, failback, height }) => {
  const nodes = [
    <span key="failback" className={styles.reflexibleFailback} aria-hidden>
      {failback}
    </span>,
    <span key="try" className={styles.reflexibleTry}>
      {display}
    </span>,
  ];

  return (
    <span className={styles.reflexible} style={{ height: `${height}px` }}>
      {nodes}
    </span>
  );
};

FlexibleRender.propTypes = {
  display: PropTypes.node.isRequired,
  failback: PropTypes.node.isRequired,
  height: PropTypes.number.isRequired,
};
