import { mdiArrowLeft } from '@mdi/js';
import { Icon } from 'its-react-ui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { SearchContextType } from '../../containers/queryParameters';
import { fetchAllCaseDocuments as fetchAllCaseDocumentsAction, reset } from '../../containers/Search';
import useWindowWidth from '../../hooks/useWindowWidth';
import RemoteDataComponent from '../../lib/RemoteData';
import * as constants from '../constVariables';
import Header from '../Header';
import { PageStatus, PageSpinner } from '../Shared';
import Main from '../Shared/Main';
import CaseDocumentSearchBar from './CaseDocumentSearchBar';
import CaseDocumentSearchDesktop from './CaseDocumentSearchDesktop';
import CaseDocumentSearchMobile from './CaseDocumentSearchMobile';
import styles from './CaseDocumentSearchPage.module.scss';

const CaseDocumentSearchPage = () => {
  const {
    caseSearch: { fetchCaseDocumentsState },
  } = useSelector(({ caseSearch }) => ({ caseSearch }));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { caseType } = useParams();
  const validCaseTypes = [SearchContextType.CLAIM, SearchContextType.COVER, SearchContextType.REINSURANCE];
  const isValidCasetype = validCaseTypes.includes(caseType);

  const goBack = async () => {
    if (location.state && location.state.backUrl) {
      history.push(location.state.backUrl);
    } else {
      dispatch(reset());
      history.push('/');
    }
  };

  useEffect(() => {
    if (isValidCasetype) {
      dispatch(fetchAllCaseDocumentsAction(true));
    }
  }, [isValidCasetype, dispatch, location.pathname]);
  const width = useWindowWidth();
  // Use custom breakpoint to break earlier to mobile as the table is not usefull on small screens.
  const renderMobileView = width < 1360;

  return (
    <>
      <Header>
        {renderMobileView && (
          <button data-test-id="roe9RhNWUfjLFLYmX3y_U" type="button" className={styles.back} onClick={goBack}>
            <Icon path={mdiArrowLeft} size={constants.ICON_SIZE} color={constants.WHITE} />
          </button>
        )}
      </Header>
      <Main className={styles.content}>
        {isValidCasetype ? (
          <>
            <CaseDocumentSearchBar goBack={goBack} isMobile={renderMobileView} />

            <RemoteDataComponent
              type={fetchCaseDocumentsState}
              loading={() => <PageSpinner />}
              failure={({ status }) => <PageStatus statusCode={status} />}
              success={(_) => (renderMobileView ? <CaseDocumentSearchMobile /> : <CaseDocumentSearchDesktop />)}
            />
          </>
        ) : (
          <PageStatus
            statusCode={404}
            message={`Invalid case type in the url: '${caseType}'. The following case types are valid: '${validCaseTypes.join(', ')}'.`}
          />
        )}
      </Main>
    </>
  );
};

export default CaseDocumentSearchPage;
