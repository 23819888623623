import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './FileExtension.module.scss';

const FileExtension = ({ type, className }) => {
  const background = styles[type.toLowerCase()];
  const styling = cn(styles.extension, background, className);
  return <div className={styling}>{type}</div>;
};

FileExtension.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default FileExtension;
