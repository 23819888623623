import { string } from 'prop-types';
import React from 'react';
import styles from './DetailsStatus.module.scss';

const DetailsStatus = ({ title, subtitle, image }) => {
  return (
    <div className={styles.root}>
      <img src={image} alt={title} />
      <div className={styles.title}>{title}</div>
      <div>{subtitle}</div>
    </div>
  );
};

DetailsStatus.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  image: string.isRequired,
};

export default DetailsStatus;
