export const sortOptions = [
  {
    name: 'Oldest',
    value: false,
    sortBy: 'SortDate',
    sortDirection: 'Asc',
  },
  {
    name: 'Newest',
    value: true,
    sortBy: 'SortDate',
    sortDirection: 'Desc',
  },
  {
    name: 'Relevance',
    value: false,
    sortBy: 'Relevance',
    sortDirection: 'Desc',
  },
];

export const defaultSort = sortOptions.find((option) => option.name === 'Newest');
