import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './SearchHelp.module.scss';

export const DocumentSearchHelper = ({ className }) => (
  <div className={cn(styles.helpWrapper, className)}>
    <h2>Search like a pro</h2>

    <section>
      <p>Use prefix and a query to find what you are looking for:</p>
    </section>

    <section>
      <PrefixHelper prefix="vessel" word="name" />
      <PrefixHelper prefix="fleet" word="name" />
      <PrefixHelper prefix="imo" word="number" />
      <PrefixHelper prefix="claim" word="number" />
      <PrefixHelper prefix="cover" word="number" />
      <PrefixHelper prefix="risk" word="number" />
    </section>

    <section>
      <PrefixHelper prefix="sender" word="name" />
      <PrefixHelper prefix="sender email" word="address" />
      <PrefixHelper prefix="recipient" word="name" />
      <PrefixHelper prefix="recipient email" word="address" />
    </section>

    <section>
      <p>
        Combine multiple search prefixes with <span className="bold">and</span>, and multiple search words that is required with &quot;+&quot; (an
        and-search, as default is an or-search)
      </p>
      <p>Use quotes &quot;...&quot; for search terms containing multiple words</p>
    </section>

    <section>
      <p>
        Example - <span className="bold">vessel: spar hydra and sender email: arild@sparshipping.com &quot;damage clause&quot; + advice</span>
      </p>
    </section>

    <section>
      <p>Wildcard search can be used, for example &quot;lohen*&quot; to search for any word starting with &quot;lohen&quot;.</p>
    </section>

    <section>
      <p>Searches are not case sensitive.</p>
    </section>
  </div>
);

DocumentSearchHelper.propTypes = {
  className: PropTypes.string,
};

export const ClaimSearchHelper = ({ className }) => (
  <div className={cn(styles.helpWrapper, className)}>
    <h2>Search for claims</h2>

    <section>
      <p>Here you can search for claims by vessel name, fleet name, claim number, IMO or content in the claim information text.</p>
    </section>

    <section>
      <p>Use &quot;...&quot; for search terms containing multiple words.</p>
      <p>
        Example - <span className="bold">&quot;bow thruster&quot;</span>
      </p>
    </section>

    <section>
      <p>
        Note to Claims Handlers: Default search results will only display your pending cases. To view additional claims, use the Status and Claims
        Handler facet filters.
      </p>
    </section>
  </div>
);

ClaimSearchHelper.propTypes = {
  className: PropTypes.string,
};

export const CoverSearchHelper = ({ className }) => (
  <div className={cn(styles.helpWrapper, className)}>
    <h2>Search for covers</h2>

    <section>
      <p>Here you can search for covers by searching for fleet name, client name, risk number or cover note number.</p>
    </section>

    <SearchExample example="capital insurance" />
  </div>
);

CoverSearchHelper.propTypes = {
  className: PropTypes.string,
};

export const ReinsuranceSearchHelper = ({ className }) => (
  <div className={cn(styles.helpWrapper, className)}>
    <h2>Search for reinsurance</h2>

    <section>
      <p>Here you can search for reinsurance by searching company name or business account name.</p>
    </section>

    <SearchExample example="seaboard atlantic" />
  </div>
);

ReinsuranceSearchHelper.propTypes = {
  className: PropTypes.string,
};

const PrefixHelper = ({ prefix, word }) => (
  <p>
    <span className="bold">{prefix}</span>: <span>{word}</span>
  </p>
);

PrefixHelper.propTypes = {
  prefix: PropTypes.string.isRequired,
  word: PropTypes.string.isRequired,
};

const SearchExample = ({ example }) => (
  <section>
    <p>Use &quot;...&quot; for search terms containing multiple words.</p>
    <p>
      Example - <span className="bold">&quot;{example}&quot;</span>
    </p>
  </section>
);

SearchExample.propTypes = {
  example: PropTypes.string.isRequired,
};
