import { mdiDotsHorizontal } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import React from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { hasRole, hasAnyRole } from '../../containers/User';
import useDialog from '../../hooks/useDialog';
import EditDocument from '../EditDocument/EditDocument';
import ModalCopyDocument from '../Modal/ModalCopyDocument';
import ModalDeleteDocument from '../Modal/ModalDeleteDocument';
import ModalMoveDocument from '../Modal/ModalMoveDocument';
import ModalMergeDocuments from '../Modal/ModalMergeDocuments';
import ItsDialog from '../Shared/ItsDialog';
import { RoleCategoryEnum } from '../../lib';
import styles from './Actions.module.scss';
import { DeleteDocumentSelectionProvider, useDeleteDocumentSelection } from '../Modal/ModalDeleteDocument/DeleteDocumentSelectionProvider';
import { fetchAllCaseDocuments, fetchSearchResult } from '../../containers/Search';
import { useFeatureFlags } from '../../providers/FeatureFlagProvider';

const Actions = () => {
  const { user } = useSelector((state) => ({ user: state.user }));
  const isComplianceOfficer = hasRole(user, RoleCategoryEnum.COMPLIANCE_OFFICER);
  const { selectedDocumentsIds } = useSelector((state) => state.searchResult);
  const disabled = selectedDocumentsIds.length === 0 || !hasAnyRole(user);
  const { enableMergeDocuments } = useFeatureFlags();

  return (
    <Menu>
      <MenuButton
        aria-label="Document actions"
        className={styles.menuButton}
        disabled={disabled}
        title={disabled ? 'Select at least one document' : 'Document actions'}
      >
        <MdiIcon path={mdiDotsHorizontal} />
      </MenuButton>

      <MenuList className={styles.menuList}>
        <DeleteDocumentSelectionProvider>
          <DeleteDocumentButton />
        </DeleteDocumentSelectionProvider>
        {!isComplianceOfficer && <CopyDocumentButton />}
        <EditDocumentButton disabled={selectedDocumentsIds.length !== 1} />
        {!isComplianceOfficer && <MoveDocumentButton />}
        {enableMergeDocuments && <MergeDocumentsButton />}
      </MenuList>
    </Menu>
  );
};

export default Actions;

const DeleteDocumentButton = () => {
  const { isShowing, show, hide } = useDialog();
  const { unselectAllDocumentsForDeletion } = useDeleteDocumentSelection();
  const dispatch = useDispatch();

  const { caseType } = useParams();

  const fetchSearchResults = () => dispatch(fetchSearchResult());
  const fetchCaseResults = () => dispatch(fetchAllCaseDocuments());
  const resetAction = caseType ? fetchCaseResults : fetchSearchResults;

  const onHide = (shouldReset) => {
    unselectAllDocumentsForDeletion();
    hide();
    resetAction();
  };
  return (
    <>
      <MenuItem onSelect={show}>Delete</MenuItem>

      <ItsDialog isOpen={isShowing} onDismiss={onHide} aria-label="Delete document" className={styles.wideDialog}>
        <ModalDeleteDocument closeModal={onHide} />
      </ItsDialog>
    </>
  );
};

const MoveDocumentButton = () => {
  const { isShowing, show, hide } = useDialog();
  return (
    <>
      <MenuItem onSelect={show}>Move</MenuItem>

      <ItsDialog isOpen={isShowing} onDismiss={hide} aria-label="Move document" className={styles.dialog}>
        <ModalMoveDocument closeModal={hide} />
      </ItsDialog>
    </>
  );
};

// eslint-disable-next-line react/prop-types
const EditDocumentButton = ({ disabled }) => {
  const { isShowing, show, hide } = useDialog();

  return (
    <>
      <MenuItem onSelect={show} className={disabled ? styles.menuItemDisabled : null} disabled={disabled}>
        Edit details
      </MenuItem>

      <ItsDialog isOpen={isShowing} onDismiss={hide} aria-label="Edit document details" className={styles.dialog}>
        <h1 className={styles.title}>Edit document</h1>

        <EditDocument close={hide} />
      </ItsDialog>
    </>
  );
};

const CopyDocumentButton = () => {
  const { isShowing, show, hide } = useDialog();
  return (
    <>
      <MenuItem onSelect={show}>Copy</MenuItem>

      <ItsDialog isOpen={isShowing} onDismiss={hide} aria-label="Copy document" className={styles.dialog}>
        <ModalCopyDocument closeModal={hide} />
      </ItsDialog>
    </>
  );
};

const MergeDocumentsButton = () => {
  const { isShowing, show, hide } = useDialog();
  return (
    <>
      <MenuItem onSelect={show}>Save as PDF</MenuItem>

      <ItsDialog isOpen={isShowing} onDismiss={hide} aria-label="Save as PDF" className={styles.dialog}>
        <ModalMergeDocuments closeModal={hide} />
      </ItsDialog>
    </>
  );
};
