import { useEffect } from 'react';

const useUnload = (callback) => {
  useEffect(() => {
    const onUnload = (...args) => callback(...args);

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [callback]);
};

export default useUnload;
