import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Locked } from '@instech/icons';
import ItemContent from './ItemContent';
import useLongPress from '../../hooks/useLongPress';
import { documentResultShape } from '../../lib/customProptypes';
import style from './SearchResult.module.scss';

const StyledLocked = styled(Locked)`
  width: 16px;
`;

const MobileItem = ({ document, isSelected, toggleDocumentSelection, hasNoFiles }) => {
  const history = useHistory();
  const itemRef = useRef(null);
  const itemStyle = hasNoFiles ? style.boxDisabled : isSelected ? style.boxSelected : '';
  const onLongPress = () => {
    if (!hasNoFiles) toggleDocumentSelection(document.id);
  };
  const onClick = () => {
    history.push(`details/${document.id}`);
  };
  useLongPress(itemRef, onLongPress, onClick);
  return (
    <div ref={itemRef} className={`${style.box} ${itemStyle}`}>
      {hasNoFiles && (
        <div className={style.notification}>
          <StyledLocked />
          <span>Not selectable, the document has to be signed first.</span>
        </div>
      )}
      <ItemContent isMobile item={document} />
    </div>
  );
};

MobileItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  hasNoFiles: PropTypes.bool,
  document: documentResultShape.isRequired,
  toggleDocumentSelection: PropTypes.func.isRequired,
};

export default MobileItem;
