import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSavePiiSections } from '../../hooks/piiSections';
import useDialog from '../../hooks/useDialog';
import { useCurrentUser } from '../../hooks/userProfile';
import XHR_STATUS from '../../lib/xhrStatus';
import { UploadStatusEnum, useFileUploader } from '../../providers/FileUploadProvider';
import { useForcedPiiNotifier } from '../../providers/ForcedPiiProvider';
import { PageSpinner } from '../Shared';
import Button from '../Shared/Button';
import ItsDialog from '../Shared/ItsDialog';
import DocumentPiiSelector from './DocumentPiiSelector';
import styles from './ForcedPiiNotifier.module.scss';

const ForcedPiiNotifier = () => {
  const { state } = useFileUploader();
  const { forcedPiiSections, clearForcedPiiSections } = useForcedPiiNotifier();
  const { isShowing, show, hide } = useDialog();
  const { user, isLoading, isError } = useCurrentUser();
  const { saveState, savePiiSections } = useSavePiiSections();
  const isSaving = saveState === XHR_STATUS.LOADING;
  const hasNoActiveUploads = Object.values(state).every((item) => item.status === UploadStatusEnum.SUCCESS || item.status === UploadStatusEnum.ERROR);

  const initialValues = useMemo(() => {
    const documentIdPiiMap = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const forcedPii of forcedPiiSections) {
      documentIdPiiMap[forcedPii.id] = forcedPii.forcedPiiSection;
    }

    return documentIdPiiMap;
  }, [forcedPiiSections]);

  useEffect(() => {
    if (forcedPiiSections && forcedPiiSections.length > 0 && !isShowing && hasNoActiveUploads) {
      show();
    }
  }, [forcedPiiSections, hasNoActiveUploads, isShowing, show, state]);

  useEffect(() => {
    if (saveState === XHR_STATUS.SUCCESS) {
      close();
    }
  }, [close, saveState]);

  const onSubmit = (values) => {
    // Only submit values that changed
    const payload = Object.entries(values)
      .filter(([documentId, piiSection]) => {
        const forced = forcedPiiSections.find((x) => x.id === documentId);
        return forced.forcedPiiSection !== piiSection;
      })
      .map(([documentId, piiSection]) => ({
        documentId,
        piiSection,
      }));

    savePiiSections(payload);
  };

  const close = useCallback(() => {
    clearForcedPiiSections();
    hide();
  }, [clearForcedPiiSections, hide]);

  return (
    <ItsDialog
      isOpen={isShowing}
      className={styles.dialog}
      aria-label="List of files with forced PII section"
      // Required prop, but we want dismiss to be disabled, as we want the user to make an active choice
      onDismiss={() => null}
    >
      {isSaving && <PageSpinner className={styles.loading} />}
      <fieldset disabled={isSaving}>
        <header>
          <div className={styles.title}>
            <h1>Some documents might contain PII</h1>
          </div>

          <p>
            {`The following documents were analysed and seems to contain personally identifiable information (PII).
            They will by default be marked as PII if you don't change anything.`}
          </p>
        </header>

        {isLoading && <PageSpinner />}

        {isError && (
          <div>
            <div className={styles.error}>
              Unable to load sections. The following documents have been automatically flagged to contain PII, and have been saved to your primary
              section.
            </div>
            <ul>
              {forcedPiiSections.map((x) => (
                <li key={x.id}>{x.name}</li>
              ))}
            </ul>
            <div className={styles.actions}>
              <Button data-test-id="qxgmZpT4EzEzj63AZ3wQL" onClick={close}>
                Close
              </Button>
            </div>
          </div>
        )}

        {!isLoading && !isError && (
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values }) => {
              const saveChangesDisabled = forcedPiiSections.every((forcedPii) => forcedPii.forcedPiiSection === values[forcedPii.id]);

              return (
                <Form>
                  <table className={styles.piiTable}>
                    <thead>
                      <tr>
                        <th>Document name</th>
                        <th>PII section</th>
                      </tr>
                    </thead>
                    <tbody>
                      {forcedPiiSections.map((forcedSection) => (
                        <tr key={forcedSection.id} className={styles.document}>
                          <DocumentPiiSelector forcedPii={forcedSection} sections={user.sections || []} />
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className={styles.actions}>
                    <Button data-test-id="BRHB5YvJBmiT1zm24Syue" onClick={close} typeStyle="light">
                      Keep as is
                    </Button>
                    <Button type="submit" disabled={saveChangesDisabled}>
                      Save changes
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </fieldset>
    </ItsDialog>
  );
};

export default ForcedPiiNotifier;
