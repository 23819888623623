import { getClaimSearchResult, getCoverSearchResult, getReinsuranceSearchResult, getSearchResult } from '../../apis';
import {
  mapClaimDocumentPayload,
  mapCoverDocumentPayload,
  mapReinsuranceDocumentPayload,
  mapSearchCasePayload,
  mapSearchClaimsPayload,
  mapSearchPayload,
} from '../../apis/mappers/mapSearchPayload';
import { toAction } from '../../lib';
import { FETCH_CASE_FILTER_FAILURE, FETCH_CASE_FILTER_SUCCESS } from '../CaseSearch';
import { decodeQueryParams, KnownQueryParams, SearchContextType } from '../queryParameters';
import { unselectAllDocuments } from '../SearchResult/actions';
import { FETCH_DOCUMENT_FILTER, FETCH_DOCUMENT_FILTER_FAILURE, FETCH_DOCUMENT_FILTER_SUCCESS } from '../SearchResult/constants';
import { caseDocumentPathMatch, keepFetchingDocuments } from '../thunkHelpers';
import * as a from './constants';

export const toggleExpandedFacet = (facetGroupLabel) => (dispatch) => dispatch(toAction(a.TOGGLE_EXPANDED_FACETS, facetGroupLabel));

export const sortChanged = () => async (dispatch) => dispatch(documentFilterChanged());

export const documentFilterChanged = () => async (dispatch, getState) => {
  const {
    router: { location },
  } = getState();
  const queryParams = decodeQueryParams(location.search);
  const searchPayload = mapSearchPayload(queryParams);

  try {
    const result = await getSearchResult(searchPayload);
    if (!result) {
      // result is undefined if the request was cancelled
      return null;
    }
    dispatch(toAction(FETCH_DOCUMENT_FILTER_SUCCESS, result));
    return dispatch(toAction(a.UPDATE_FILTERS, result.facetGroups));
  } catch (error) {
    return dispatch(toAction(FETCH_DOCUMENT_FILTER_FAILURE, error));
  }
};

export const caseDocumentFilterChanged = () => async (dispatch, getState) => {
  dispatch(unselectAllDocuments);
  dispatch(toAction(FETCH_DOCUMENT_FILTER));
  const {
    router: { location },
  } = getState();

  const { caseType } = caseDocumentPathMatch(location.pathname).params;
  let payload;
  switch (caseType) {
    case SearchContextType.CLAIM:
      payload = mapClaimDocumentPayload(location);
      break;
    case SearchContextType.COVER:
      payload = mapCoverDocumentPayload(location);
      break;
    case SearchContextType.REINSURANCE:
      payload = mapReinsuranceDocumentPayload(location);
      break;
    default:
      throw Error(`Document search for ${caseType} is unsupported`);
  }

  try {
    const result = await getSearchResult(payload);
    if (!result) {
      // result is undefined if the request was cancelled
      return null;
    }

    if (result.hasMoreItems) {
      // Azure search has a limitation of 1000 documents, but here we want all results
      await keepFetchingDocuments(payload, result);
    }

    dispatch(toAction(FETCH_DOCUMENT_FILTER_SUCCESS, result));
    return dispatch(toAction(a.UPDATE_FILTERS, result.facetGroups));
  } catch (error) {
    return dispatch(toAction(FETCH_DOCUMENT_FILTER_FAILURE, error));
  }
};

export const caseFilterChanged = () => async (dispatch, getState) => {
  const {
    router: { location },
  } = getState();
  const queryParams = decodeQueryParams(location.search);
  const searchContext = queryParams[KnownQueryParams.CONTEXT];

  let payload;
  let fetchCallback;

  switch (searchContext) {
    case SearchContextType.CLAIM: {
      payload = mapSearchClaimsPayload(queryParams);
      fetchCallback = () => getClaimSearchResult(payload);
      break;
    }
    case SearchContextType.COVER: {
      payload = mapSearchCasePayload(queryParams);
      fetchCallback = () => getCoverSearchResult(payload);
      break;
    }
    case SearchContextType.REINSURANCE: {
      payload = mapSearchCasePayload(queryParams);
      fetchCallback = () => getReinsuranceSearchResult(payload);
      break;
    }
    default:
      throw Error(`Unhandled case type: ${searchContext}`);
  }

  return dispatch(filterChanged(fetchCallback));
};

const filterChanged = (fetchCallback) => async (dispatch) => {
  try {
    const result = await fetchCallback();
    if (!result) {
      // result is undefined if the request was cancelled
      return null;
    }

    dispatch(toAction(FETCH_CASE_FILTER_SUCCESS, result));
    return dispatch(toAction(a.UPDATE_FILTERS, result.facetGroups));
  } catch (error) {
    return dispatch(toAction(FETCH_CASE_FILTER_FAILURE, error));
  }
};
