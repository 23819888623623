import cn from 'classnames';
import { Filter as ItsFilter } from 'its-react-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { ArrayParam, NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { toggleExpandedFacet as toggleExpandedFacetAction } from '../../containers/Filters/actions';
import { KnownQueryParams } from '../../containers/queryParameters';
import { useDetailsQueryParam } from '../../hooks/documents';
import styles from './Filters.module.scss';

const { container, scrollContainer } = styles;

const Filters = ({ children, className, scrollToTop, onChangeHandler }) => {
  const { facets } = useSelector((state) => ({ ...state.filters }));

  return (
    <SimpleBar className={cn(container, className)} autoHide={false} scrollableNodeProps={{ className: scrollContainer }}>
      {children}
      {facets.map((facetGroup) => (
        <Filter key={facetGroup.label} facetGroup={facetGroup} scrollToTop={scrollToTop} onChangeHandler={onChangeHandler} />
      ))}
    </SimpleBar>
  );
};

Filters.propTypes = {
  children: PropTypes.node,
  scrollToTop: PropTypes.func,
  onChangeHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Filters;

const Filter = ({ facetGroup, scrollToTop, onChangeHandler }) => {
  const dispatch = useDispatch();
  const [checkedFacetsParam, setCheckedFacetParam] = useQueryParam(facetGroup.propertyName, withDefault(ArrayParam, []));
  const [, setPageQueryParam] = useQueryParam([KnownQueryParams.PAGE], NumberParam);
  const { clearQueryParam: clearDetailsQueryParam } = useDetailsQueryParam();
  const facetsVm = useMemo(
    () =>
      facetGroup.facets.map((item) => ({
        ...item,
        name: item.value,
        value: checkedFacetsParam.includes(item.value),
      })),
    [facetGroup, checkedFacetsParam]
  );

  const changeFilter = useCallback(
    (facetLabel, checked) => {
      setPageQueryParam(undefined, 'replaceIn');
      clearDetailsQueryParam();
      if (scrollToTop) {
        scrollToTop();
      }

      if (checked) {
        setCheckedFacetParam([...checkedFacetsParam, facetLabel], 'replaceIn');
      } else {
        const newQueryValues = checkedFacetsParam.filter((value) => value !== facetLabel);
        setCheckedFacetParam(newQueryValues, 'replaceIn');
      }

      onChangeHandler();
    },
    [checkedFacetsParam, clearDetailsQueryParam, onChangeHandler, scrollToTop, setCheckedFacetParam, setPageQueryParam]
  );

  const toggleExpandFacet = useCallback(() => dispatch(toggleExpandedFacetAction(facetGroup.label)), [dispatch, facetGroup]);

  return (
    <ItsFilter
      data-test-id="cQqJi7O4IqcOs1faDcEuT"
      name={facetGroup.label}
      items={facetsVm}
      expanded={facetGroup.expanded}
      onClick={changeFilter}
      showCount={3}
      onExpand={() => toggleExpandFacet(facetGroup.label)}
    />
  );
};

Filter.propTypes = {
  facetGroup: PropTypes.shape({
    expanded: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    propertyName: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    facets: PropTypes.array.isRequired,
  }),
  scrollToTop: PropTypes.func,
  onChangeHandler: PropTypes.func.isRequired,
};
