import { mdiClose } from '@mdi/js';
import MdiIcon from '@mdi/react';
import '@reach/dialog/styles.css';
import PropTypes from 'prop-types';
import React from 'react';
import { ICON_SIZE } from '../constVariables';
import styles from './CloseModalButton.module.scss';

const CloseModalButton = ({ close }) => (
  <div className={styles.closeWrapper}>
    <button data-test-id="4J6FAHffhDj6THjf6WrAW" type="button" onClick={close}>
      <span className="visually-hidden">Close</span>
      <MdiIcon path={mdiClose} size={ICON_SIZE} aria-hidden />
    </button>
  </div>
);

CloseModalButton.propTypes = {
  close: PropTypes.func.isRequired,
};

export default CloseModalButton;
