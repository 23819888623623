import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { mdiChevronRight } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { SearchContextType } from '../../containers/queryParameters';
import { reinsuranceSearchResultShape } from '../../lib/customProptypes';
import style from './CaseSearch.module.scss';
import DescriptionItem from '../Shared/DescriptionItem';
import { BLUE_ICON_COLOR } from '../constVariables';

const ReinsuranceResult = ({ item }) => {
  const location = useLocation();

  return (
    <Link
      className={style.case}
      to={{
        pathname: `/cases/${SearchContextType.REINSURANCE}/${item.companyNumber}/${item.businessAccountNumber}/${item.insuranceYear}`,
        state: { backUrl: location.pathname + location.search },
      }}
    >
      <div className={style.content}>
        <dl className={style.descriptionList}>
          <DescriptionItem term="Underwriting year" detail={item.insuranceYear} />
          <DescriptionItem term="Company name" detail={item.companyName} />
          <DescriptionItem term="Company number" detail={item.companyNumber} />
          <DescriptionItem term="Business account" detail={item.businessAccount} />
          <DescriptionItem term="Business account number" detail={item.businessAccountNumber} />
        </dl>
      </div>

      <div className={style.caseIcon}>
        <MdiIcon path={mdiChevronRight} color={BLUE_ICON_COLOR} />
      </div>
    </Link>
  );
};

ReinsuranceResult.propTypes = {
  item: PropTypes.shape(reinsuranceSearchResultShape),
};

export default ReinsuranceResult;
