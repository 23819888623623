import { useEffect, useReducer, useRef, useState } from 'react';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions, LogLevel } from '@microsoft/signalr';
import XHR_STATUS from '../lib/xhrStatus';

type ErrorsType = keyof typeof ErrorTypes;

export const ErrorTypes = {
  UnsupportedFiles: 'UnsupportedFiles',
  ErrorDocument: 'ErrorDocument',
  GeneralError: 'GeneralError',
  NoSupportedFiles: 'NoSupportedFiles',
} as const;

interface State {
  status: number;
  errorType: ErrorsType | null;
  errorDocument: string[];
  documentIds: string[];
  ignoreUnsupportedFiles: boolean;
  downloadUrl: string | null;
  progress: number;
}

export const initialState: State = {
  status: XHR_STATUS.LOADING,
  errorType: null,
  errorDocument: [],
  documentIds: [],
  ignoreUnsupportedFiles: false,
  downloadUrl: null,
  progress: 0,
};

type ActionsType = keyof typeof Actions;

const Actions = {
  mergeDocuments: 'mergeDocuments',
  error: 'error',
  success: 'success',
  progress: 'progress',
} as const;

export const reducer = (state: State, { type, payload }: { type: ActionsType; payload: any }) => {
  switch (type) {
    case Actions.mergeDocuments:
      return {
        ...initialState,
        documentIds: payload.documentIds,
        ignoreUnsupportedFiles: payload.ignoreUnsupportedFiles,
      };
    case Actions.error:
      return {
        ...state,
        status: XHR_STATUS.ERROR,
        errorType: payload.errorType,
        errorDocument: payload.errorDocument,
      };
    case Actions.success:
      return {
        ...state,
        status: XHR_STATUS.SUCCESS,
        downloadUrl: payload.downloadUrl,
      };
    case Actions.progress:
      return {
        ...state,
        progress: payload.progress,
      };
    default:
      throw new Error('Unknown reducer action');
  }
};

const functionUrl = '/signalr/api';

const connectionOptions: IHttpConnectionOptions = {};

const buildConnection = () => new HubConnectionBuilder().withUrl(functionUrl, connectionOptions).configureLogging(LogLevel.Error).build();

const useMergeDocuments = () => {
  const connection = useRef<HubConnection | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { documentIds, ignoreUnsupportedFiles, downloadUrl, status, errorType, errorDocument, progress } = state;

  useEffect(() => {
    const sendSignalRMessage = async () => {
      try {
        await connection.current?.send('mergeDocuments', JSON.stringify(documentIds), ignoreUnsupportedFiles);
      } catch (err) {
        onError(ErrorTypes.GeneralError, []);
      }
    };

    if (documentIds && isConnected) {
      sendSignalRMessage();
    }
  }, [documentIds, isConnected, ignoreUnsupportedFiles]);

  const mergeDocuments = async (ids: string[], ignore = false) => {
    dispatch({
      type: Actions.mergeDocuments,
      payload: { documentIds: ids, ignoreUnsupportedFiles: ignore },
    });
  };

  const onCompleted = (url: string) => {
    dispatch({
      type: Actions.success,
      payload: { downloadUrl: url },
    });
  };

  const onProgress = (currentProgress: number) => {
    dispatch({
      type: Actions.progress,
      payload: { progress: currentProgress },
    });
  };

  const onError = (error: ErrorsType, files: string[]) => {
    dispatch({
      type: Actions.error,
      payload: { errorType: error, errorDocument: files },
    });
  };

  useEffect(() => {
    connection.current = buildConnection();

    connection.current.start().then(() => {
      setIsConnected(true);
      connection.current!.on('completed', onCompleted);
      connection.current!.on('progress', onProgress);
      connection.current!.on(ErrorTypes.ErrorDocument, (documents) => {
        onError(ErrorTypes.ErrorDocument, documents);
      });
      connection.current!.on(ErrorTypes.UnsupportedFiles, (files) => {
        onError(ErrorTypes.UnsupportedFiles, files);
      });
      connection.current!.on(ErrorTypes.GeneralError, () => {
        onError(ErrorTypes.GeneralError, []);
      });
      connection.current!.on(ErrorTypes.NoSupportedFiles, () => {
        onError(ErrorTypes.NoSupportedFiles, []);
      });
    });
  }, []);

  return {
    mergeDocuments,
    status,
    downloadUrl,
    errorType,
    errorDocument,
    progress,
  };
};

export default useMergeDocuments;
