import {
  mdiAccountAlert,
  mdiAccountOutline,
  mdiClockOutline,
  mdiEmailOutline,
  mdiFileDocumentBox,
  mdiFileDocumentOutline,
  mdiStarOutline,
  mdiTagOutline,
} from '@mdi/js';
import MdiIcon, { Icon } from '@mdi/react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React from 'react';
import { mapTaxonomyDisplayName } from '../../lib';
import { useSections } from '../../hooks/userProfile';
import { documentResultShape } from '../../lib/customProptypes';
import { ICON_SIZE, SMALL_ICON_SIZE } from '../constVariables';
import { DateTime, Tags } from '../Shared';
import SectionTitle from '../Shared/SectionTitle';
import { Spinner } from '../Shared/Spinner';
import styles from './Details.module.scss';
import SenderRecipientContent from './SenderRecipientContent';
import { ViewAuditLog } from './ViewAuditLog';

const { error, iconAndText, middleContainer, piiIcon, piiRow, piiSpinner, row, tags } = styles;

const IconAndText = ({ text, iconPath }) => (
  <div className={iconAndText}>
    <MdiIcon path={iconPath} />
    <div>{text}</div>
  </div>
);

IconAndText.propTypes = {
  text: PropTypes.string.isRequired,
  iconPath: PropTypes.string.isRequired,
};

const hasRecipients = ({ recipients, otherRecipients }) => recipients.length || otherRecipients.length;

const showSenderRecipient = (item, isEmail) => isEmail && hasRecipients(item);

const mapClassification = (classification) => (classification === 'Client Info' ? 'Client information' : classification);

const DetailsMiddleContent = ({ isEmail, item, showAll }) => {
  const docType = item.taxonomy && item.taxonomy.documentType ? mapTaxonomyDisplayName(item.taxonomy.documentType) : null;
  const documentTags = item.taxonomy && item.taxonomy.documentTags ? item.taxonomy.documentTags : null;
  const { sections, isLoading: isLoadingSections, isError: isErrorSections } = useSections();
  const piiSection = !!sections && sections.find((section) => section.mdmExternalId === item.piiSection);
  const hasLogs = item.logs.length > 0;
  const showPiiRow = hasLogs || item.piiSection;

  return (
    <div className={middleContainer}>
      <div className={row}>
        {docType && <IconAndText iconPath={mdiFileDocumentOutline} text={docType} />}
        {showAll && <IconAndText iconPath={mdiStarOutline} text={mapClassification(item.classification) || ''} />}
        {item.date && (
          <div className={iconAndText}>
            <MdiIcon path={mdiClockOutline} size={ICON_SIZE} />
            <DateTime showTime date={item.date} />
          </div>
        )}
      </div>

      {showPiiRow && (
        <div className={piiRow}>
          <Icon path={mdiAccountAlert} className={piiIcon} title="Personally identifiable information indicator" size={SMALL_ICON_SIZE} />
          {item.piiSection && (
            <>
              {piiSection && (
                <span>
                  <SectionTitle section={piiSection} />
                </span>
              )}
              {isLoadingSections && <Spinner className={piiSpinner} />}
              {isErrorSections && <div className={error}>Unable to load PII section.</div>}
            </>
          )}
          {hasLogs && <ViewAuditLog logs={item.logs} sections={sections} />}
        </div>
      )}

      {showSenderRecipient(item, isEmail) ? (
        <SenderRecipientContent showAll={showAll} sender={item.sender} recipients={item.recipients} otherRecipients={item.otherRecipients} />
      ) : (
        <IconAndText iconPath={mdiAccountOutline} text={item.user || ''} />
      )}
      {isEmail && item.subject && <IconAndText iconPath={mdiEmailOutline} text={item.subject} />}
      {item.notes && <IconAndText iconPath={mdiFileDocumentBox} text={item.notes} />}
      {documentTags && !isEmpty(documentTags) && (
        <div className={tags}>
          <MdiIcon path={mdiTagOutline} size={ICON_SIZE} />
          <Tags documentTags={documentTags} />
        </div>
      )}
    </div>
  );
};

DetailsMiddleContent.propTypes = {
  isEmail: PropTypes.bool.isRequired,
  showAll: PropTypes.bool.isRequired,
  item: documentResultShape,
};

export default DetailsMiddleContent;
