import React from 'react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import { Util } from 'pdfjs-dist/legacy/build/pdf';
import { PDFSinglePageViewer, PDFViewer } from 'pdfjs-dist/legacy/web/pdf_viewer';
import LoadingStatusMessage from './LoadingStatusMessage';
import style from './PdfViewer.module.scss';

const convertToTextContentItem = (
  pageViewport,
  { width: ocrPageWidth, height: ocrPageHeight },
  { text, boundingBox: [x1, y1, x2, y2, x3, y3, x4, y4] }
) => {
  const { width: pageWidth, height: pageHeight, transform: viewportTransform, scale } = pageViewport;
  const widthRatio = pageWidth / ocrPageWidth;
  const heightRatio = pageHeight / ocrPageHeight;

  const height = (y3 - y2) * heightRatio;
  return {
    dir: 'ltr',
    fontName: 'serif',
    hasEOL: false,
    str: text,
    width: ((x2 - x1) * widthRatio) / scale,
    height,
    transform: Util.transform(Util.inverseTransform(viewportTransform), [1, 0, 0, -height, x1 * widthRatio, y1 * heightRatio + height]),
  };
};

const convertToTextContent = (pageViewport, ocrPage) => {
  return {
    items: ocrPage.lines.map((line) => convertToTextContentItem(pageViewport, ocrPage, line)),
    styles: { serif: { fontFamily: 'serif', ascent: 0, descent: 0, vertical: false } },
  };
};

const pdfDocumentPageProxy = (obj, ocrPage) => {
  const handler = {
    get(target, prop, receiver) {
      if (prop !== 'streamTextContent') return Reflect.get(target, prop, receiver);
      return () => {
        return new ReadableStream({
          start(controller) {
            // const viewportScale = this.scale * _pdf.PixelsPerInch.PDF_TO_CSS_UNITS; lib/web/pdf_page_view.js#L277
            controller.enqueue(convertToTextContent(target.getViewport({ scale: 96.0 / 72.0, rotation: target.rotate }), ocrPage));
            controller.close();
          },
        });
      };
    },
  };
  return new Proxy(obj, handler);
};

export const pdfDocumentProxy = (obj, ocrResults) => {
  const handler = {
    get(target, prop, receiver) {
      if (prop !== 'getPage') return Reflect.get(target, prop, receiver);
      return (pageNumber) =>
        new Promise((resolve) => {
          target.getPage(pageNumber).then((page) => resolve(pdfDocumentPageProxy(page, ocrResults[pageNumber - 1])));
        });
    },
  };
  return new Proxy(obj, handler);
};

export const buildPdfViewer = (isSinglePageViewer, eventBus, viewerContainerRef, linkServiceRef, findControllerRef, pdfDocument) => {
  const config = {
    container: viewerContainerRef.current,
    eventBus,
    linkService: linkServiceRef.current,
    findController: findControllerRef.current,
    enableScripting: false,
    textLayerMode: isSinglePageViewer ? 0 : 1,
  };
  const pdfViewer = isSinglePageViewer ? new PDFSinglePageViewer(config) : new PDFViewer(config);
  linkServiceRef.current.setViewer(pdfViewer);
  pdfViewer.setDocument(pdfDocument);
  linkServiceRef.current.setDocument(pdfDocument);
  return pdfViewer;
};

export const showLoadingStatus = (loading, error) => (
  <>
    {loading && <LoadingStatusMessage message="Loading document." status="running" />}
    {!loading && !error && <LoadingStatusMessage message="Document loaded." status="completed" />}
    {error && <LoadingStatusMessage message="Text analysis complete, but scanned documents may not be searchable." status="failed" />}
  </>
);

export const sleep = (time) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

export const Paging = ({ pdfViewer, currentPage, pagesAmount }) => (
  <div className={style.paging}>
    <MdiIcon data-test-id="cZcGHg4LhttPGLlA241hq" path={mdiArrowLeft} onClick={() => pdfViewer.current.previousPage()} />
    <span>{currentPage}</span> / <span>{pagesAmount}</span>
    <MdiIcon data-test-id="mykIBuG3MpbbDJNX5YnHM" path={mdiArrowRight} onClick={() => pdfViewer.current.nextPage()} />
  </div>
);
Paging.propTypes = {
  pdfViewer: PropTypes.shape({
    current: PropTypes.shape({
      previousPage: PropTypes.func,
      nextPage: PropTypes.func,
    }),
  }),
  currentPage: PropTypes.number,
  pagesAmount: PropTypes.number,
};
