import { mdiClose, mdiMagnify } from '@mdi/js';
import cn from 'classnames';
import { Icon } from 'its-react-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import * as constants from '../constVariables';
import styles from './Search.module.scss';

const { clearButton, hidden, roundLeft, searchButton, searchInput } = styles;

const Search = ({ onReset, onSearch, activeSearchText, hintText, hasLeftActions = false }) => {
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    // Syncs the search text from query parameters
    setSearchText(activeSearchText || '');
  }, [activeSearchText]);

  const inputEl = useRef(null);
  useEffect(() => {
    inputEl.current.focus();
  }, []);

  const searchTextChanged = (newSearchText) => {
    setSearchText(newSearchText);
  };

  const keyUpHandler = (event) => {
    if (event.key === 'Enter') {
      search(searchText);
    } else if (event.key === 'Escape') {
      reset();
    }
  };

  const search = (text) => {
    if (text) {
      onSearch(text);
    }
  };

  const reset = () => {
    if (searchText) {
      onReset();
      if (!activeSearchText) {
        setSearchText('');
      }
    }
  };

  return (
    <>
      <button
        data-test-id="93mpyWbQvW6ogUeY4TnAu"
        type="button"
        className={cn(clearButton, {
          [hidden]: searchText === '',
          [roundLeft]: hasLeftActions,
        })}
        onClick={reset}
        disabled={searchText === ''}
      >
        <Icon path={mdiClose} size={constants.ICON_SIZE} color={constants.GREY_ICON_COLOR} />
      </button>
      <input
        ref={inputEl}
        className={searchInput}
        type="text"
        placeholder={hintText}
        onChange={(e) => searchTextChanged(e.target.value)}
        onKeyUp={keyUpHandler}
        value={searchText}
      />
      <button type="submit" className={searchButton} onClick={() => search(searchText)} data-test-id="search-button">
        <Icon path={mdiMagnify} size={constants.SEARCH_ICON_SIZE} />
      </button>
    </>
  );
};

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  activeSearchText: PropTypes.string,
  hintText: PropTypes.string,
  hasLeftActions: PropTypes.bool,
};

export default Search;
