import React, { createContext, useContext, useState, useMemo } from 'react';
import ModalContainer from './ModalContainer';
import { childrenShape } from '../../lib/customProptypes';

const ModalContext = createContext(null);

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(null);
  const closeModal = useMemo(() => () => setModal(null), [setModal]);

  const values = useMemo(
    () => ({
      openModal: setModal,
      closeModal,
    }),
    [setModal, closeModal]
  );

  return (
    <ModalContext.Provider value={values}>
      {children}
      {modal && <ModalContainer closeModal={closeModal} {...modal} />}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: childrenShape,
};

export const useModalContext = () => useContext(ModalContext);
