import { mdiFilter } from '@mdi/js';
import '@reach/dialog/styles.css';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { KnownQueryParams } from '../../containers/queryParameters';
import { ICON_SIZE } from '../constVariables';
import { IconButton } from '../Shared';
import CloseModalButton from '../Shared/CloseModalButton';
import ItsDialog from '../Shared/ItsDialog';
import style from './MobileFilterControls.module.scss';

const MobileFilterControls = ({ additionalControls, children, onClearFilters }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [queryParams, setQueryParams] = useQueryParams({
    [KnownQueryParams.CONTEXT]: StringParam,
    [KnownQueryParams.SEARCH_TEXT]: StringParam,
    [KnownQueryParams.SORT_BY]: StringParam,
    [KnownQueryParams.SORT_DIRECTION]: StringParam,
  });

  const openFilters = () => setShowDialog(true);
  const closeFilters = () => setShowDialog(false);

  const clearAllFilters = () => {
    setQueryParams(queryParams, 'replace');
    onClearFilters();
  };

  return (
    <div className={style.mobileControls}>
      <button data-test-id="o-MyyO5P4HhkwfwyNevnS" className={style.clearFilterButton} type="button" onClick={clearAllFilters}>
        Clear filter
      </button>

      <div className={style.sortContainer}>
        <IconButton data-test-id="oRyMKdrHXRVWkrIgY8rvE" text="Filter" icon={mdiFilter} iconSize={ICON_SIZE} onClick={openFilters} />
        {additionalControls ? <div className={style.additionalControls}>{additionalControls}</div> : null}
      </div>

      <ItsDialog isOpen={showDialog} onDismiss={closeFilters} aria-label="Filter menu">
        <CloseModalButton close={closeFilters} />

        {children}
      </ItsDialog>
    </div>
  );
};

MobileFilterControls.propTypes = {
  additionalControls: PropTypes.node,
  children: PropTypes.node.isRequired,
  onClearFilters: PropTypes.func.isRequired,
};

export default MobileFilterControls;
