import { mdiChevronLeft } from '@mdi/js';
import MdiIcon from '@mdi/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as constants from '../constVariables';
import styles from './BackToResultsNavLink.module.scss';

const { goBack, wrap } = styles;

const BackToResultsNavLink = () => {
  const history = useHistory();

  return (
    <div className={wrap}>
      <button data-test-id="ptNfsM1sogTc74FuwjRqX" className={goBack} type="button" onClick={history.goBack}>
        <MdiIcon path={mdiChevronLeft} size={constants.ICON_SIZE} color={constants.BLUE_ICON_COLOR} />
        Back to results
      </button>
    </div>
  );
};

export default BackToResultsNavLink;
