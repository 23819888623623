import React, { useCallback, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mdiClose } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { mutate } from 'swr';
import { WarningNotification } from '@instech/components';
import axios, { AxiosResponse } from 'axios';
import cn from 'classnames';
import { SearchResult } from '../../containers/SearchResult/types';
import { moveDocuments as moveDocumentsRequest } from '../../apis/document';
import check from '../Icons/check.svg';
import cry from '../Icons/cry.svg';
import documentAccessDeniedIcon from '../Icons/document_access_denied.svg';
import loaderModal from '../Icons/loader_modal.svg';
import InsuranceContextsSelector from '../InsuranceContextsSelector';
import ModalMessage from './ModalMessage';
import { deleteSelectedDocuments } from '../../containers/SearchResult/actions';
import { getRoleByContextType } from '../../containers/queryParameters';
import useSignalR from '../../hooks/useSignalR';
import HttpStatus from '../../lib/HttpStatus';
import XHR_STATUS from '../../lib/xhrStatus';
import {
  reducer,
  initialState,
  setComponentStatusAction,
  setErrorAction,
  setSelectedContextsDestinationAction,
  setIsSearchModeAction,
  setHandledDocumentsIdsAction,
} from './modalCopyMoveDocument';
import style from './ModalMoveDocument.module.scss';
import { useDetailsQueryParam } from '../../hooks/documents';

const avdError = "You can't move documents of type 'Average Disbursement'";
const avdText = `
One or more of the documents you are trying to move are of type Average Disbursement. (Often they are doctype Invoice.)
Theses types of documents are per NHC business logic not allowed to be moved from the claim.
You can choose to either exclude these documents from the move operation, or to cancel the operation.`;

const getErrorMessage = (resp: AxiosResponse) => {
  switch (resp.status) {
    case HttpStatus.BAD_REQUEST:
      return resp.data.Message ?? resp.data;
    case HttpStatus.FORBIDDEN:
      return 'You do not have an access to perform this action';
    default:
      return 'Something went wrong';
  }
};

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <button data-test-id="ModalMoveDocumentCloseButton" type="button" className={cn(style.button, style.closeButton)} onClick={() => onClick()}>
    Close
  </button>
);

interface ModalMoveDocumentProps {
  closeModal: () => void;
}
const ModalMoveDocument = ({ closeModal }: ModalMoveDocumentProps) => {
  const { selectedContexts, selectedDocumentsIds } = useSelector((state: { searchResult: SearchResult }) => state.searchResult);

  const globalDispatch = useDispatch();
  const { detailsQueryParam, clearQueryParam } = useDetailsQueryParam();

  const reducerState = {
    ...initialState,
    selectedDocumentsIds,
    selectedContextsSource: selectedContexts.map((x) => x.id),
  };
  const [state, dispatch] = useReducer(reducer, reducerState);
  const [getCorrelationId] = useSignalR(setHandledDocumentsIdsAction(dispatch));

  const moveDocuments = useCallback(
    async (ignoreAvdDocuments = false) => {
      setComponentStatusAction(dispatch, XHR_STATUS.LOADING);

      try {
        const correlationId = await getCorrelationId();
        await moveDocumentsRequest({
          contextsIdsSource: state.selectedContextsSource,
          insuranceContextsDestination: state.selectedContextsDestination,
          documentsIds: state.selectedDocumentsIds,
          correlationId,
          ignoreAvdDocuments,
        });
        state.selectedDocumentsIds.forEach((id) => {
          mutate(`metadata/${id}`);
        });

        if (detailsQueryParam && state.selectedDocumentsIds.includes(detailsQueryParam)) {
          clearQueryParam();
        }

        globalDispatch(deleteSelectedDocuments());
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setErrorAction(dispatch, getErrorMessage(error.response as AxiosResponse));
        } else {
          setErrorAction(dispatch, 'Something went wrong');
        }
        setComponentStatusAction(dispatch, XHR_STATUS.ERROR);
      }
    },
    [
      clearQueryParam,
      detailsQueryParam,
      getCorrelationId,
      globalDispatch,
      state.selectedContextsDestination,
      state.selectedContextsSource,
      state.selectedDocumentsIds,
    ]
  );

  // Skip average dispursement documents when moving
  const retryWithSkipAvd = async () => {
    setComponentStatusAction(dispatch, XHR_STATUS.LOADING);
    await moveDocuments(true);
    setComponentStatusAction(dispatch, XHR_STATUS.SUCCESS);
  };

  const hasSelectedContexts = selectedContexts.length > 0;

  const areContextsHaveSameType = hasSelectedContexts ? selectedContexts.every((x) => x.$type === selectedContexts[0].$type) : true;
  if (!areContextsHaveSameType) {
    return (
      <ModalMessage iconPath={cry} header="That didn't go well" text="All selected documents must have the same insurance context type.">
        <CloseButton onClick={closeModal} data-test-id="LUJDew2JfdVnnfE_9Dzgv" />
      </ModalMessage>
    );
  }

  const activeRole = hasSelectedContexts ? getRoleByContextType(selectedContexts[0].$type) : null;
  const operationStatus = `Moved ${state.handledDocumentsIds.length} of ${state.selectedDocumentsIds.length} document(s)`;

  switch (state.componentStatus) {
    case XHR_STATUS.SUCCESS:
      return (
        <ModalMessage iconPath={check} header="Success" text="Document(s) moved.">
          <WarningNotification size="small" headingText="Usually it takes between 5 and 60 minutes to update data in the system." />
          <CloseButton onClick={closeModal} data-test-id="gImeik8kvjiNHC4wmPBq_" />
        </ModalMessage>
      );
    case XHR_STATUS.ERROR:
      if (state.error === avdError) {
        return (
          <ModalMessage iconPath={documentAccessDeniedIcon} header={avdError} text={avdText}>
            <button data-test-id="wtfGZsjvh9xAcgRHBUdn3" type="button" onClick={retryWithSkipAvd} className={cn(style.button, style.moveButton)}>
              Exclude document(s) and continue
            </button>
            <button data-test-id="NU7jljzCjJ_TSXhyf9Saq" type="button" onClick={closeModal} className={cn(style.button, style.cancelButton)}>
              Cancel
            </button>
          </ModalMessage>
        );
      }
      return (
        <ModalMessage iconPath={cry} header="That didn't go well" text={state.error}>
          <CloseButton onClick={closeModal} data-test-id="92jIXsYyEXM_L6kuameCf" />;
        </ModalMessage>
      );
    case XHR_STATUS.LOADING:
      return <ModalMessage iconPath={loaderModal} header="Please wait ..." text={operationStatus} />;
    default:
      if (!hasSelectedContexts) {
        return <ModalMessage iconPath={cry} header="That didn't go well" text="Please select at least one insurance context to move document(s)." />;
      }
      return (
        <div className={style.wrap}>
          <div className={style.header}>
            <h1>Move document(s)</h1>
            <MdiIcon data-test-id="rl41KlJ67c3PsOYZO-aTB" path={mdiClose} onClick={closeModal} />
          </div>
          <InsuranceContextsSelector
            setSelectedInsuranceContexts={setSelectedContextsDestinationAction(dispatch)}
            setIsSearchMode={setIsSearchModeAction(dispatch)}
            activeRole={activeRole!}
            infoText="The document(s) will be moved to and archived under the selected context(s):"
          />
          {!state.isSearchMode && state.selectedContextsDestination.length > 0 && (
            <div className={style.buttons}>
              <button data-test-id="G---6hdP3d09nlwFYrT2N" type="button" onClick={closeModal}>
                Cancel
              </button>
              <button data-test-id="7OD4INz2IcI3UTrsyNQt7" type="button" onClick={() => moveDocuments()}>
                Move
              </button>
            </div>
          )}
        </div>
      );
  }
};

export default ModalMoveDocument;
