import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchAllCaseDocuments } from '../../containers/Search';
import { toggleDocumentSelection as toggleDocumentSelectionAction } from '../../containers/SearchResult/actions';
import { sortByProp } from '../../lib/helpers';
import XHR_STATUS from '../../lib/xhrStatus';
import MobileFilterControls from '../Filters/MobileFilterControls';
import SelectionPanel from '../SelectionPanel';
import { PageSpinner } from '../Shared';
import SearchView from '../Shared/SearchView';
import CaseDocumentEmptyResults from './CaseDocumentEmptyResults';
import CaseDocumentFilters from './CaseDocumentFilters';
import styles from './CaseDocumentSearchMobile.module.scss';
import CaseDocumentsLimitedSpace from './CaseDocumentsLimitedSpace';
import { mapDocumentToViewModel } from './helpers';

const CaseDocumentSearchMobile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    searchResult: { currentView: documents, selectedDocumentsIds, remoteDataState, remoteFilterChangedDataState },
    filters: { facets },
  } = useSelector(({ searchResult, filters }) => ({ searchResult, filters }));
  const hasDocuments = documents.length > 0;

  const sortableValues = [
    { value: 'from', display: 'From' },
    { value: 'to', display: 'To' },
    { value: 'date', display: 'Date' },
    { value: 'notes', display: 'Notes' },
    { value: 'title', display: 'Title' },
    { value: 'docType', display: 'Document type' },
  ];
  const [sortProp, setSortProp] = useState('');

  const toggleDocumentSelection = useCallback(
    (documentId) => {
      dispatch(toggleDocumentSelectionAction(documentId));
    },
    [dispatch]
  );

  const toggleDocumentDetails = useCallback(
    (documentId) => {
      history.push(`/details/${documentId}`);
    },
    [history]
  );

  const clearAllFilters = () => {
    dispatch(fetchAllCaseDocuments());
  };

  const changeSorting = ({ target: { value } }) => setSortProp(value);

  let documentsViewModel = documents.map(mapDocumentToViewModel);
  if (sortProp) {
    documentsViewModel = [...documentsViewModel].sort(sortByProp(sortProp));
  }

  const hasFilters = remoteDataState.isSuccess() && facets.length > 0;

  return (
    <SearchView className={styles.overflow}>
      {hasFilters && (
        <MobileFilterControls
          onClearFilters={clearAllFilters}
          additionalControls={
            <select value={sortProp} onChange={changeSorting} className={styles.sortSelector}>
              <option value="">Sort by:</option>
              {sortableValues.map(({ value, display }) => (
                <option key={value} value={value}>
                  {display}
                </option>
              ))}
            </select>
          }
        >
          <CaseDocumentFilters />
        </MobileFilterControls>
      )}

      {remoteFilterChangedDataState === XHR_STATUS.LOADING && <PageSpinner className={styles.spinner} />}

      {hasDocuments ? (
        <fieldset disabled={remoteFilterChangedDataState === XHR_STATUS.LOADING}>
          <SelectionPanel />
          <CaseDocumentsLimitedSpace
            documents={documentsViewModel}
            selectedDocumentsIds={selectedDocumentsIds}
            toggleDocumentSelection={toggleDocumentSelection}
            toggleDocumentDetails={toggleDocumentDetails}
          />
        </fieldset>
      ) : (
        <CaseDocumentEmptyResults />
      )}
    </SearchView>
  );
};

export default CaseDocumentSearchMobile;
