import { matchPath } from 'react-router-dom';
import { getSearchResult } from '../apis';

export const claimDocumentPath = '/cases/:caseType/:claimNumber/:insuranceYear';
export const coverDocumentPath = '/cases/:caseType/:riskNumber/:coverNumber/:insuranceYear';
export const reinsuranceDocumentPath = '/cases/:caseType/:companyNumber/:businessAccountNumber/:insuranceYear';

// Access match object in reducer - https://github.com/supasate/connected-react-router/issues/38
export const caseDocumentPathMatch = (pathname) =>
  matchPath(pathname, {
    path: [coverDocumentPath, claimDocumentPath, reinsuranceDocumentPath],
  });

export const keepFetchingDocuments = async (payload, firstResult, page = 2) => {
  const newPayload = { ...payload, page, pageSize: 999 };
  const result = await getSearchResult(newPayload);
  if (!result) {
    // result is undefined if the request was cancelled
    return;
  }

  firstResult.documents = [...firstResult.documents, ...result.documents];
  firstResult.count += result.count;
  // No need to handle Facets - the original query counts all facets based on the search and filters

  if (result.hasMoreItems) {
    await keepFetchingDocuments(payload, firstResult, page + 1);
  }
};
