import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import InfiniteScroll from '../SearchResult/InfiniteScroll';
import style from './InfiniteScrollContainer.module.scss';
import { remoteDataShape } from '../../lib/customProptypes';

const InfiniteScrollContainer = forwardRef(({ children, items, remoteDataState, hasMoreItems, fetchNextSearchResult, scrollToTop }, ref) => (
  <SimpleBar scrollableNodeProps={{ ref }} className={style.scrollable} autoHide={false}>
    <div className={style.searchResults}>
      {children}

      {items.length > 0 && (
        <InfiniteScroll
          items={items}
          searchResult={remoteDataState}
          hasMoreItems={hasMoreItems}
          fetchNextSearchResult={fetchNextSearchResult}
          scrollToTop={scrollToTop}
        />
      )}
    </div>
  </SimpleBar>
));

InfiniteScrollContainer.propTypes = {
  children: PropTypes.node.isRequired,
  remoteDataState: PropTypes.shape(remoteDataShape).isRequired,
  hasMoreItems: PropTypes.bool.isRequired,
  fetchNextSearchResult: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
};

export default InfiniteScrollContainer;
