import React from 'react';
import { useHistory } from 'react-router';
import { Update as UpdatesPageWidget } from 'insify-remote-component-loader';
import { useAccount } from '../../providers/authenticationProvider';
import Header from '../Header';
import style from './UpdatesPage.module.scss';

const portal = {
  name: 'document',
  displayName: 'Document',
};

const UpdatesPage = () => {
  const account = useAccount();
  const history = useHistory();

  return (
    <div className={style.wrap}>
      <div className={style.stickyHeader}>
        <Header />
      </div>
      <UpdatesPageWidget account={account} history={history} portal={portal} />
    </div>
  );
};

export default UpdatesPage;
