import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from '../Shared';
import { documentResultShape, claimContextShape, coverContextShape } from '../../lib/customProptypes';
import { getFormatedDate } from '../../lib/helpers';
import styles from './SearchResult.module.scss';

const ClaimContext = ({ context }) => {
  const { vesselName, interest: { shortName } = {}, date, description = '' } = context;
  const claimDate = getFormatedDate(date);
  const claimDescription = description.substring(0, 40);
  return (
    <>
      <span>{vesselName}</span> | {shortName} | {claimDate} | {claimDescription}
    </>
  );
};
ClaimContext.propTypes = { context: claimContextShape };

const CoverContext = ({ context }) => {
  const { fleetName, businessAccount, interest: { shortName } = {}, insuranceYear } = context;
  return (
    <>
      <span>{fleetName}</span> | {businessAccount} | {shortName} | {insuranceYear}
    </>
  );
};
CoverContext.propTypes = { context: coverContextShape };

const ReinsuranceContext = ({ context }) => {
  const { companyName, businessAccount, insuranceYear } = context;
  return (
    <>
      <span>{companyName}</span> | {businessAccount} | {insuranceYear}
    </>
  );
};
ReinsuranceContext.propTypes = { context: coverContextShape };

const ItemTopContent = ({ item, isMobile }) => {
  const hasContexts = item.contexts && item.contexts.length > 0;
  const context = hasContexts ? item.contexts[0] : {};
  return (
    <div className={styles.itemTopContent}>
      <div>
        {hasContexts && context.$type === 'ClaimContext' && <ClaimContext context={context} />}
        {hasContexts && context.$type === 'CoverContext' && <CoverContext context={context} />}
        {hasContexts && context.$type === 'ReinsuranceContext' && <ReinsuranceContext context={context} />}
      </div>
      <div>
        <DateTime showTime={!isMobile} date={item.date} />
      </div>
    </div>
  );
};

ItemTopContent.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  item: documentResultShape,
};

export default ItemTopContent;
