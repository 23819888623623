import { addNamespace } from '../../lib';

const addNs = addNamespace('case-search');
export const CHANGE_SEARCH_TEXT = addNs('CHANGE_SEARCH_TEXT');
export const FETCH_CASE_SEARCH = addNs('FETCH_CASE_SEARCH');
export const FETCH_CASE_SEARCH_SUCCESS = addNs('FETCH_CASE_SEARCH_SUCCESS');
export const FETCH_CASE_SEARCH_FAILURE = addNs('FETCH_CASE_SEARCH_FAILURE');

export const FETCH_CASE_FILTER_SUCCESS = addNs('FETCH_CASE_FILTER_SUCCESS');
export const FETCH_CASE_FILTER_FAILURE = addNs('FETCH_CASE_FILTER_FAILURE');

export const FETCH_CASE_NEXT_PAGE = addNs('FETCH_CASE_NEXT_PAGE');
export const FETCH_CASE_NEXT_PAGE_SUCCESS = addNs('FETCH_CASE_NEXT_PAGE_SUCCESS');
export const FETCH_CASE_NEXT_PAGE_FAILURE = addNs('FETCH_CASE_NEXT_PAGE_FAILURE');
export const RESET_CASE_STATE = addNs('RESET');

export const FETCH_INITIAL_CASE_DOCUMENTS = addNs('FETCH_INITIAL_CASE_DOCUMENTS');
export const FETCH_INITIAL_CASE_DOCUMENTS_SUCCESS = addNs('FETCH_INITIAL_CASE_DOCUMENTS_SUCCESS');
export const FETCH_INITIAL_CASE_DOCUMENTS_FAILURE = addNs('FETCH_INITIAL_CASE_DOCUMENTS_FAILURE');
