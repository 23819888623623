import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { NhcLogo } from '@instech/icons';
import { reset as resetAction } from '../../containers/Search';
import styles from './Header.module.scss';
import UserFields from './UserFields';
import { PortalSelectorLoader } from './PortalSelectorLoader';
import { FeatureEnum, useFeatureFlag } from '../../providers/FeatureFlagProvider';

const Name = styled.span`
  font-weight: bold;
  font-size: 20px;
`;

const StyledImage = styled.div`
  color: white;
  & svg {
    height: 50px;
    width: 130px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const MenuSection = styled.div`
  display: flex;
  align-items: center;
`;

const Brand = styled.div`
  display: flex;
  align-items: center;
  color: white;
  gap: 16px;
`;

const Header = ({ children }) => {
  const dispatch = useDispatch();
  const reset = () => {
    dispatch(resetAction());
  };
  const portalSelectorIsEnabled = useFeatureFlag(FeatureEnum.PORTALSELECTOR);

  return (
    <header className={styles.container}>
      <div className={styles.actions}>{children}</div>
      <Content>
        <Brand>
          <Link data-test-id="SFJRMj2jocYfPKI_13vU7" to="/" onClick={reset} className={styles.logo}>
            <StyledImage>
              <NhcLogo alt="NHC logo" />
            </StyledImage>
          </Link>
          <Name>Document</Name>
        </Brand>
        <MenuSection>
          <UserFields />
          {portalSelectorIsEnabled && <PortalSelectorLoader />}
        </MenuSection>
      </Content>
    </header>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
