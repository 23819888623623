import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useQueryParam } from 'use-query-params';
import { caseFilterChanged } from '../../containers/Filters/actions';
import { ClaimDateFilter, KnownQueryParams, SearchContextType } from '../../containers/queryParameters';
import Filters from '../Filters';
import { DateFilter } from '../Filters/DateFilters';

const CaseFilters = ({ scrollToTop }) => {
  const dispatch = useDispatch();
  const [searchContext] = useQueryParam(KnownQueryParams.CONTEXT);
  const onFilterChanged = () => {
    dispatch(caseFilterChanged());
  };

  return (
    <Filters scrollToTop={scrollToTop} onChangeHandler={onFilterChanged}>
      {searchContext === SearchContextType.CLAIM ? <DateFilter name={ClaimDateFilter} onChangeHandler={onFilterChanged} /> : null}
    </Filters>
  );
};

CaseFilters.propTypes = {
  scrollToTop: PropTypes.func,
};

export default CaseFilters;
