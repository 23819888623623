import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '../Error/ErrorBoundary';
import s from './ModalContainer.module.scss';

const ModalContainer = ({ component: Component, closeModal, ...rest }) => (
  <div className={s.shadow}>
    <div className={s.modal}>
      <ErrorBoundary>
        <Component closeModal={closeModal} {...rest} />
      </ErrorBoundary>
    </div>
  </div>
);

ModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
};

export default ModalContainer;
