import '@reach/tabs/styles.css';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import * as yup from 'yup';
import { claimContextShape, coverContextShape } from '../../lib/customProptypes';
import { useFileUploader } from '../../providers/FileUploadProvider';
import MultiStepForm from '../Shared/MultiStepForm';
import ContentStep from './uploadFilesForm/ContentStep';
import ContextStep from './uploadFilesForm/ContextStep';
import UploadStep from './uploadFilesForm/UploadStep';

const fileUploadSchema = yup.object().shape({
  files: yup
    .array()
    .min(1, 'Select at least one file to continue.')
    .max(100, 'The number of files allowed to be uploaded at a time is 100, please remove some files.')
    .ensure(),
});

export const defaultContentShape = {
  roleType: yup.string().required('Select a role type'),
  documentType: yup.string().required('Select a document type'),
  classification: yup.string().required('Select a classification'),
};

const contentSchema = yup.object().shape(defaultContentShape);

const contextSchema = yup.object().shape({
  insuranceContexts: yup.array().min(1, 'Select at least one context').ensure(),
});

const AddFiles = ({ currentContext, onSubmit }) => {
  const [isSearchingContexts, setIsSearchingContexts] = useState(false);
  const { queueUploads } = useFileUploader();
  const { caseType } = useParams();
  const [contentStepValidation, setContentStepValidation] = useState(contentSchema);
  const initialValues = {
    files: [],

    roleType: '',
    documentType: '',
    classification: '',
    notes: '',
    keywords: [],
    piiSection: '',

    insuranceContexts: [currentContext],
  };

  const setSearchMode = (isInSearchMode) => {
    setIsSearchingContexts(isInSearchMode);
  };

  const updateContentValidationSchema = useCallback((showPii) => {
    setContentStepValidation(
      showPii
        ? yup.object().shape({
            ...defaultContentShape,
            piiSection: yup.string().required('Select a section'),
          })
        : contentSchema
    );
  }, []);

  const steps = useMemo(
    () => [
      {
        label: 'Upload',
        component: <UploadStep />,
        validationSchema: fileUploadSchema,
      },
      {
        label: 'Content',
        component: <ContentStep updateValidationSchema={updateContentValidationSchema} />,
        validationSchema: contentStepValidation,
      },
      {
        label: 'Context',
        component: <ContextStep currentContext={currentContext} setSearchMode={setSearchMode} />,
        validationSchema: contextSchema,
      },
    ],
    [contentStepValidation, currentContext, updateContentValidationSchema]
  );

  const submitForm = (formValues) => {
    const filesWithMetadata = formValues.files.map((file) => mapToUploadData(formValues, file, caseType));
    queueUploads(filesWithMetadata);

    onSubmit();
  };

  return <MultiStepForm steps={steps} navigationDisabled={isSearchingContexts} initialValues={initialValues} submitForm={submitForm} />;
};

AddFiles.propTypes = {
  currentContext: PropTypes.oneOfType([claimContextShape, coverContextShape]),
  onSubmit: PropTypes.func,
};

export default AddFiles;

const mapToUploadData = ({ notes, classification, insuranceContexts, documentType, keywords, roleType, piiSection }, file, insuranceContextType) => ({
  file,
  notes,
  classification,
  insuranceContexts: JSON.stringify(insuranceContexts),
  piiSection,
  taxonomy: JSON.stringify({
    documentType,
    documentTags: keywords.map((keyword) => keyword.value),
    applicationRoleName: roleType.charAt(0).toUpperCase() + roleType.slice(1),
  }),
  insuranceContextType,
});
