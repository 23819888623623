import { Just, Nothing } from 'monet';

const valid = (val: any) => {
  if (val instanceof Array) {
    return val.length > 0;
  }
  if (typeof val === 'object' && val !== null) {
    return true;
  }
  return !!val;
};

export const toMaybe = (val: any) => (valid(val) ? Just(val) : Nothing());
