import { map } from 'ramda';
import { recipientType } from '../constVariables';

const mapRecipients = (type, recipients) =>
  map(
    (recipient) => ({
      ...recipient,
      recipientType: type,
    }),
    recipients
  );

const concatRecipients = (recipients = [], otherRecipients = []) => [
  ...mapRecipients(recipientType.To, recipients),
  ...mapRecipients(recipientType.Cc, otherRecipients),
];

export default concatRecipients;
