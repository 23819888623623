import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import useSWR, { mutate } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { StringParam, useQueryParams } from 'use-query-params';
import client from '../apis/client';
import { KnownQueryParams, SearchContextType } from '../containers/queryParameters';
import { fetchAllCaseDocuments, fetchSearchResult } from '../containers/Search';
import { caseDocumentPathMatch } from '../containers/thunkHelpers';
import XHR_STATUS from '../lib/xhrStatus';
import { useDetailsQueryParam } from './documents';

export const useSections = () => {
  const key = 'user/sections';
  // We don't expect the sections to change, so we only get this data once.
  const { data, error } = useSWRImmutable(key);

  return {
    sections: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useCurrentUser = () => {
  const { data, error } = useSWR('user/current', { revalidateOnFocus: false });

  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useSaveUserSections = () => {
  const [saveState, setSaveState] = useState(XHR_STATUS.INITIAL);
  const [queryParams] = useQueryParams({
    [KnownQueryParams.CONTEXT]: StringParam,
    [KnownQueryParams.SEARCH_TEXT]: StringParam,
  });
  const { clearQueryParam: clearDetailsQueryParam } = useDetailsQueryParam();
  const location = useLocation();
  const dispatch = useDispatch();

  const saveUserSections = async (sectionIds) => {
    setSaveState(XHR_STATUS.LOADING);

    try {
      await client.post('user/sections', sectionIds);
      // Revalidate the current user when the sections has changed.
      mutate('user/current');
      setSaveState(XHR_STATUS.SUCCESS);
      // Due to PII document metadata should be revalidated
      // The metadata is revalidated when a document is opened in the preview
      clearDetailsQueryParam();

      const isAllDocumentSearch = queryParams[KnownQueryParams.CONTEXT] === SearchContextType.ALL && !!queryParams[KnownQueryParams.SEARCH_TEXT];
      if (isAllDocumentSearch) {
        dispatch(fetchSearchResult());
        return;
      }

      const isCaseDocumentSearch = !!caseDocumentPathMatch(location.pathname);
      if (isCaseDocumentSearch) {
        dispatch(fetchAllCaseDocuments());
        return;
      }
    } catch (error) {
      setSaveState(XHR_STATUS.ERROR);
    }
  };

  return {
    saveState,
    saveUserSections,
  };
};
