import React from 'react';
import { object } from 'prop-types';

const SectionTitle = ({ section }) => (
  <>
    {section.code} - {section.name}
  </>
);

SectionTitle.propTypes = {
  section: object.isRequired,
};

export default SectionTitle;
