import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './SearchResult.module.scss';
import { emailDirection } from '../constVariables';

const { sideColumnGrey, sideColumnBlue, sideColumnGreen } = styles;

export const getDirectionClass = (direction) => {
  switch (direction) {
    case emailDirection.incoming:
      return sideColumnBlue;

    case emailDirection.internal:
      return sideColumnGrey;

    case emailDirection.outgoing:
      return sideColumnGreen;

    default:
      return '';
  }
};

const EmailDirectionIndicator = ({ direction, className }) => (
  <div className={cn(getDirectionClass(direction), className)} title={`Email direction: ${direction}`} />
);

EmailDirectionIndicator.propTypes = {
  direction: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default EmailDirectionIndicator;
