import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon } from 'its-react-ui';
import styles from './Tag.module.scss';
import * as constants from '../constVariables';

export const tagType = Object.freeze({
  fill: 'fill',
  stroke: 'stroke',
  icon: 'icon',
});

const Tag = ({ className, icon = undefined, text = '', type = tagType.fill, withHover = false, ...rest }) => (
  <span
    className={cn(styles.tag, styles[type], className, {
      [styles.withHover]: withHover,
    })}
    {...rest}
  >
    {icon ? <Icon path={icon} size="1em" color={constants.WHITE} /> : text.trim().length > 0 && text}
  </span>
);

Tag.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf(Object.values(tagType)),
  withHover: PropTypes.bool,
};

export default Tag;
