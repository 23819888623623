/* eslint-disable react-hooks/exhaustive-deps */
import { mdiMagnify } from '@mdi/js';
import { isBefore } from 'date-fns';
import { DatePicker, Icon } from 'its-react-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { StringParam, useQueryParam, useQueryParams, withDefault } from 'use-query-params';
import * as constants from '../constVariables';
import DatePickerInput from './DatePickerInput';
import styles from './Filters.module.scss';
import variables from '../../scss/variables.scss';

const { maincolor, white } = variables;
const { datePicker, filterName, searchIcon } = styles;

const ActionIcon = ({ actionHandler, iconPath }) => (
  <div
    data-test-id="i77XUKP5nnTNo1JuGtNoc"
    role="button"
    type="button"
    tabIndex={-1}
    onClick={actionHandler}
    onKeyUp={(event) => {
      if (event.key === 'Enter') {
        actionHandler();
      }
    }}
    className={searchIcon}
  >
    <Icon path={iconPath} size={constants.SEARCH_ICON_SIZE} backgroundcolor={maincolor} color={white} />
  </div>
);

ActionIcon.propTypes = {
  actionHandler: PropTypes.func,
  iconPath: PropTypes.string,
};

export const DateRangeFilter = ({ name, onChangeHandler }) => {
  const ref = useRef();
  const startDateQueryParam = `${name}From`;
  const endDateQueryParam = `${name}To`;
  const [queryParams, setQueryParams] = useQueryParams({
    [name]: withDefault(StringParam, null),
    [startDateQueryParam]: withDefault(StringParam, null),
    [endDateQueryParam]: withDefault(StringParam, null),
  });

  const from = queryParams[startDateQueryParam] || queryParams[name];
  const to = queryParams[endDateQueryParam];

  // onChange only handles clearing the field
  const onChange = (_, dates) => {
    if (!dates) {
      resetAllDates();
    }
  };

  // Handles selection from the datepicker dropdown
  const onSelect = (date) => {
    const selectedDate = new Date(date);
    const selectedDateUtc = selectedDate.toISOString();

    // Has selected from, but a new selection is before the from date
    if (from && isBefore(selectedDate, new Date(from))) {
      handleSpecificDate(selectedDateUtc);
      return;
    }

    // From date has been set, now set to date
    if (from) {
      handleDateRange(from, selectedDateUtc);
      closeDatepicker();
      return;
    }

    // Only one date selected
    handleSpecificDate(selectedDateUtc);
  };

  const closeDatepicker = () => {
    // Need to handle closing of datepicker due to bug
    // https://github.com/Hacker0x01/react-datepicker/issues/2333
    if (ref.current) {
      ref.current.setOpen(false);
    }
  };

  const syncFromDateToDatePicker = (fromInput) => {
    if (ref.current) {
      ref.current.setSelected(fromInput, null, true);
    }
  };

  const handleDateRange = (fromDate, toDate) => {
    setQueryParams(
      {
        [name]: undefined,
        [startDateQueryParam]: fromDate,
        [endDateQueryParam]: toDate,
      },
      'replaceIn'
    );
    onChangeHandler();
  };

  const handleSpecificDate = (date) => {
    setQueryParams(
      {
        [name]: date,
        [startDateQueryParam]: undefined,
        [endDateQueryParam]: undefined,
      },
      'replaceIn'
    );
    onChangeHandler();
  };

  const resetAllDates = () => {
    setQueryParams(
      {
        [name]: undefined,
        [startDateQueryParam]: undefined,
        [endDateQueryParam]: undefined,
      },
      'replaceIn'
    );
    onChangeHandler();
  };

  return (
    <div>
      <div className={filterName}>{name}</div>
      <div className={datePicker}>
        <DatePicker
          ref={ref}
          name={name}
          date={from ? new Date(from) : null}
          startDate={from ? new Date(from) : null}
          endDate={to ? new Date(to) : null}
          onSelect={onSelect}
          selectsRange
          // Need to handle closing of datepicker due to bug
          // https://github.com/Hacker0x01/react-datepicker/issues/2333
          shouldCloseOnSelect={false}
          portalId="datepicker-portal"
          customInput={
            <DatePickerInput
              resetAllDates={resetAllDates}
              handleDateRange={handleDateRange}
              handleSpecificDate={handleSpecificDate}
              stateFrom={from}
              stateTo={to}
              closeDatepicker={closeDatepicker}
              syncFromDateToDatePicker={syncFromDateToDatePicker}
            />
          }
          onChange={onChange}
        />
        <ActionIcon actionHandler={onChangeHandler} iconPath={mdiMagnify} />
      </div>
    </div>
  );
};

DateRangeFilter.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};

export const DateFilter = ({ name, className, onChangeHandler }) => {
  const [dateParam, setDateParam] = useQueryParam(name, withDefault(StringParam, null));

  const onDateChange = useCallback(
    (_, value) => {
      const date = value ? new Date(value).toISOString() : undefined;
      setDateParam(date);
    },
    [setDateParam]
  );

  const onChange = useCallback(
    (_, value) => {
      onDateChange(name, value);

      if (!value) {
        onChangeHandler();
      }
    },
    [onDateChange]
  );

  const onSelect = useCallback((_) => {
    onChangeHandler();
  }, []);

  const onKeyDown = useCallback((event) => {
    if (event.keyCode === constants.ENTER) {
      onChangeHandler();
    }
  }, []);

  return (
    <div className={className}>
      <div className={filterName}>{name}</div>

      <div className={datePicker}>
        <DatePicker
          name={name}
          date={dateParam ? new Date(dateParam) : null}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onSelect={onSelect}
          portalId="datepicker-portal"
        />
        <ActionIcon actionHandler={onChangeHandler} iconPath={mdiMagnify} />
      </div>
    </div>
  );
};

DateFilter.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChangeHandler: PropTypes.func.isRequired,
};
