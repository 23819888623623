import React from 'react';
import { useLocation, useParams } from 'react-router';
import Main from '../Shared/Main';
import Header from '../Header';
import BackToResultsNavLink from '../Shared/BackToResultsNavLink';
import Details from './Details';
import styles from './Details.module.scss';

const DetailsPage = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const showAll = !!(state && state.showAll);

  return (
    <>
      <Header />
      <BackToResultsNavLink />
      <Main className={styles.detailsPage}>
        <Details documentId={id} showAll={showAll} />
      </Main>
    </>
  );
};

export default DetailsPage;
