import { ContextType } from '../../lib';

const mapInterest = (insuranceContext) => {
  const interest = insuranceContext && insuranceContext.interest;

  if (interest && interest.shortName) {
    return interest;
  }

  return { shortName: '' };
};

const mapPeriod = (period) => ({
  from: (period && new Date(period.from)) || null,
  to: (period && new Date(period.to)) || null,
});

const mapContextInfo = (insuranceContext, contextType) => {
  switch (contextType) {
    case ContextType.ClaimContext:
      return {
        description: insuranceContext.description,
        clientReference: insuranceContext.clientReference,
        xChanging: insuranceContext.xChanging,
        contractPeriod: mapPeriod(insuranceContext.contractPeriod),
      };
    default:
      return {};
  }
};

const getContextType = (type) => {
  switch (type) {
    case 'ClaimContext':
      return ContextType.ClaimContext;
    case 'CoverContext':
      return ContextType.CoverContext;
    case 'ReinsuranceContext':
      return ContextType.ReinsuranceContext;
    default:
      return ContextType.SomeOtherContext;
  }
};

const isDateValid = (date) => {
  if (!date) {
    return false;
  }

  const minTime = new Date('0000-01-01T00:00:00Z').getTime();
  const time = new Date(date).getTime();
  return time - minTime > 9999;
};

const mapContext = (insuranceContext, restricted) => {
  const contextType = getContextType(insuranceContext.$type);
  return {
    ...insuranceContext,
    userHasAccess: !restricted,
    contextType,
    interest: mapInterest(insuranceContext),
    ...(insuranceContext.date && {
      date: isDateValid(insuranceContext.date) ? new Date(insuranceContext.date) : undefined,
    }),
    ...(insuranceContext.insurancePeriod && {
      insurancePeriod: insuranceContext.insurancePeriod ? mapPeriod(insuranceContext.insurancePeriod) : undefined,
    }),
    ...mapContextInfo(insuranceContext, contextType),
  };
};

export default mapContext;
