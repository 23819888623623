import { addNamespace } from '../../lib/helpers';

const a = addNamespace('searchResult');
export const DELETE_SELECTED_DOCUMENTS = a('DELETE_SELECTED_DOCUMENTS');
export const TOGGLE_DOCUMENT_SELECTION = a('TOGGLE_DOCUMENT_SELECTION');
export const SELECT_ALL_DOCUMENTS = a('SELECT_ALL_DOCUMENTS');
export const UNSELECT_ALL_DOCUMENTS = a('UNSELECT_ALL_DOCUMENTS');
export const FETCH_NEXT_SEARCH_RESULT = a('FETCH_NEXT_SEARCH_RESULT');
export const NEXT_SEARCH_RESULT_SUCCESS = a('NEXT_SEARCH_RESULT_SUCCESS');
export const NEXT_SEARCH_RESULT_FAILURE = a('NEXT_SEARCH_RESULT_FAILURE');
export const UPDATE_SEARCH_RESULT_AFTER_UPDATE = a('UPDATE_SEARCH_RESULT_AFTER_UPDATE');

export const FETCH_SEARCH_RESULT = a('FETCH_SEARCH_RESULT');
export const FETCH_SEARCH_RESULT_SUCCESS = a('FETCH_SEARCH_RESULT_SUCCESS');
export const FETCH_SEARCH_RESULT_FAILURE = a('FETCH_SEARCH_RESULT_FAILURE');

export const FETCH_DOCUMENT_FILTER = a('FETCH_DOCUMENT_FILTER');
export const FETCH_DOCUMENT_FILTER_SUCCESS = a('FETCH_DOCUMENT_FILTER_SUCCESS');
export const FETCH_DOCUMENT_FILTER_FAILURE = a('FETCH_DOCUMENT_FILTER_FAILURE');
