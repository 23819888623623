import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { documentFilterChanged } from '../../containers/Filters/actions';
import { AllDocumentsDateFilters } from '../../containers/queryParameters';
import Filters from '../Filters';
import { DateRangeFilter } from '../Filters/DateFilters';

const DocumentFilters = ({ scrollToTop }) => {
  const dispatch = useDispatch();
  const onFilterChanged = () => {
    dispatch(documentFilterChanged());
  };

  return (
    <Filters scrollToTop={scrollToTop} onChangeHandler={onFilterChanged}>
      <DateRangeFilter name={AllDocumentsDateFilters} onChangeHandler={onFilterChanged} />
    </Filters>
  );
};

DocumentFilters.propTypes = {
  scrollToTop: PropTypes.func,
};

export default DocumentFilters;
