import React from 'react';
import { useQueryParam } from 'use-query-params';
import Main from '../Shared/Main';
import { Header } from '..';
import { KnownQueryParams, SearchContextType } from '../../containers/queryParameters';
import CaseSearch from '../CaseSearch/CaseSearch';
import DocumentSearch from '../DocumentSearch/DocumentSearch';
import s from './App.module.scss';

const Desktop = () => {
  const [searchContext] = useQueryParam(KnownQueryParams.CONTEXT);

  const isDocumentContext = searchContext === SearchContextType.ALL;

  return (
    <>
      <Header />
      <Main>
        <div className={s.searchView}>{isDocumentContext ? <DocumentSearch /> : <CaseSearch />}</div>
      </Main>
    </>
  );
};

export default Desktop;
