import client, { cancellableGet } from './client';

const httpGet = cancellableGet();

export const getFavorites = async () => client.get('user/favorites');
export const addFavorite = async (favoriteId) => client.post('user/favorites', { documentTypeId: favoriteId });
export const removeFavorite = async (favoriteId) => client.delete(`user/favorites/${favoriteId}`);

export const getDocumentTypesByRoles = async () => client.get('metadata/documentTypesByRoles').then((response) => response.data);

export const getClassifications = async () => client.get('metadata/classifications').then((response) => response.data);

export const getSearchResult = (params) => httpGet('search', params);

export const getClaimSearchResult = (params) => httpGet('search/claims', params);

export const getCoverSearchResult = (params) => httpGet('search/covers', params);

export const getReinsuranceSearchResult = (params) => httpGet('search/reinsurance', params);

export const getBlob = async (fileId) => client.get(`blob/${fileId}`, { responseType: 'blob' }).then((response) => response.data);

export const getBlobAsBytes = (id) => () =>
  new Promise((resolve, reject) => {
    getBlob(id)
      .then((blob) => resolve(new Response(blob).arrayBuffer()))
      .catch((error) => reject(error));
  });

export const getBlobAsText = (id) => () =>
  new Promise((resolve, reject) => {
    getBlob(id)
      .then((blob) => resolve(new Response(blob).text()))
      .catch((error) => reject(error));
  });

export const getDownloadBlobAsync = async (fileId) =>
  client
    .get(`blob/${fileId}/downloadBlob`, { responseType: 'blob', validateStatus: (status) => status < 400 || status === 404 })
    .then((response) => response.data);

export const getOriginalDownloadBlobAsync = async (fileId) =>
  client.get(`blob/${fileId}/originalblob`, { responseType: 'blob' }).then((response) => response.data);

export const getOriginalDownloadBlobMetadataAsync = async (fileId) =>
  client.get(`blob/${fileId}/originalblob/metadata`).then((response) => response.data);

export const getThumbnailAsync = async (fileId) => client.get(`blob/${fileId}/thumbnail`, { responseType: 'blob' }).then((response) => response.data);

export const getMetaDocument = async (documentId) => client.get(`metadata/${documentId}`).then((response) => response.data);

export const startOcrScan = async (fileId) => client.post(`ocr/${fileId}`).then((response) => response.data);

export const getOcrStatus = async (operationId) => client.get(`ocr/${operationId}`).then((response) => response.data);

export const getSavedOcrResult = async (fileId) => client.get(`ocr/result/${fileId}`).then((response) => response.data);

export const saveOcrResult = (fileId, payload) => client.post(`ocr/result/${fileId}`, payload).then((response) => response.data);

export const deleteDocuments = (deleteDocumentsRequest) => client.post('document/contexts', deleteDocumentsRequest);

export const copyDocuments = (copyDocumentsRequest) => client.post('document/copy', copyDocumentsRequest);

export const moveDocuments = (moveDocumentsRequest) => client.post('metadata/move', moveDocumentsRequest);
export function hasInvalidFilenames(data, cancelToken) {
  const formData = mapToFormData(data);

  return client.post('document/hasinvalidfilenames', formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
    // Overwrite default timeout
    timeout: 60 * 10 * 1000,
    cancelToken,
  });
}

export function upload(data, cancelToken, trackProgress, modify) {
  const { insuranceContextType, ...rest } = data;
  const formData = mapToFormData(rest);
  const queryParams = new URLSearchParams({
    modify,
    insuranceContextType,
  });

  return client.post('document/upload', formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
    params: queryParams,
    // Overwrite default timeout
    timeout: 60 * 10 * 1000,
    cancelToken,
    onUploadProgress: (event) => {
      const progress = Math.round((100 * event.loaded) / event.total);
      trackProgress(progress);
    },
  });
}

const mapToFormData = ({ file, ...rest }) => {
  const formData = new FormData();
  formData.append('file', file, file.name);

  Object.entries(rest).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};
