import { useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useResizeObserver = (ref, callback) => {
  const current = ref && ref.current;
  const observer = useRef(null);

  useEffect(() => {
    // if we are already observing old element
    if (observer && observer.current && current) {
      observer.current.unobserve(current);
    }

    const ResizeObserverOrPolyfill = ResizeObserver;
    observer.current = new ResizeObserverOrPolyfill(callback);
    observe();

    return () => {
      if (observer && observer.current && ref && ref.current) {
        observer.current.unobserve(ref.current);
      }
    };
  }, [current]);

  const observe = () => {
    if (ref && ref.current && observer.current) {
      observer.current.observe(ref.current);
    }
  };
};

export default useResizeObserver;
