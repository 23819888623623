import RemoteData from '../../lib/remote-data';
import { createReducer } from '../../lib';
import { RESET_SEARCH } from '../Search/constants';
import * as actionType from './constants';

const initialState = {
  caseResults: null,
  remoteDataState: RemoteData.NotAsked,
  remoteNextPageDataState: RemoteData.NotAsked,

  fetchCaseDocumentsState: RemoteData.NotAsked,
};

const caseSearchResult = createReducer(initialState)({
  // Normal case search
  [actionType.FETCH_CASE_SEARCH]: (state, _) => ({
    ...state,
    remoteDataState: RemoteData.Loading,
  }),
  [actionType.FETCH_CASE_SEARCH_SUCCESS]: (state, { payload }) => ({
    ...state,
    caseResults: payload,
    remoteDataState: RemoteData.Success(payload),
  }),
  [actionType.FETCH_CASE_SEARCH_FAILURE]: (state, { payload }) => ({
    ...state,
    remoteDataState: RemoteData.Failure(payload),
  }),

  // Fetch next page
  [actionType.FETCH_CASE_NEXT_PAGE]: (state, _) => ({
    ...state,
    remoteNextPageDataState: RemoteData.Loading,
    page: state.page + 1,
  }),
  [actionType.FETCH_CASE_NEXT_PAGE_SUCCESS]: (state, { payload }) => {
    const previousCases = state.caseResults.results;
    const newPageCases = payload.results;
    const caseResults = {
      ...payload,
      results: [...previousCases, ...newPageCases],
    };

    return {
      ...state,
      caseResults,
      remoteNextPageDataState: RemoteData.NotAsked,
    };
  },
  [actionType.FETCH_CASE_NEXT_PAGE_FAILURE]: (state, { payload }) => ({
    ...state,
    remoteNextPageDataState: RemoteData.Failure(payload),
  }),

  // Change filters
  [actionType.FETCH_CASE_FILTER_SUCCESS]: (state, { payload }) => ({
    ...state,
    caseResults: payload,
    remoteDataState: RemoteData.Success(payload),
  }),
  [actionType.FETCH_CASE_FILTER_FAILURE]: (state, { payload }) => ({
    ...state,
    remoteDataState: RemoteData.Failure(payload),
  }),

  // Fetch state for loading documents for a case
  [actionType.FETCH_INITIAL_CASE_DOCUMENTS]: (state) => ({
    ...state,
    fetchCaseDocumentsState: RemoteData.Loading,
  }),
  [actionType.FETCH_INITIAL_CASE_DOCUMENTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    fetchCaseDocumentsState: RemoteData.Success(payload),
  }),
  [actionType.FETCH_INITIAL_CASE_DOCUMENTS_FAILURE]: (state, { payload }) => ({
    ...state,
    fetchCaseDocumentsState: RemoteData.Failure(payload),
  }),

  [RESET_SEARCH]: (_, __) => ({
    ...initialState,
  }),
});

export default caseSearchResult;
