import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './SearchView.module.scss';

const SearchView = ({ children, className }) => <div className={cn(styles.root, className)}>{children}</div>;

SearchView.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default SearchView;
