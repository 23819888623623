/* eslint-disable no-unused-vars */
import { RoleCategoryEnum } from '../lib';

export const KnownQueryParams = Object.freeze({
  CONTEXT: 'c', // SearchContextType
  SEARCH_TEXT: 'q',
  PAGE: 'p',

  SORT_BY: 'sb',
  SORT_DIRECTION: 'sd',

  DETAILS: 'details',
});

export const decodeQueryParams = (queryParamsString) => {
  const urlParams = new URLSearchParams(queryParamsString);
  const queryParams = {};

  // A key can have multiple values in the search bar, need to iterate to know if we have multiple
  urlParams.forEach((queryValue, queryKey) => {
    const parameterValues = queryParams[queryKey];

    if (parameterValues) {
      const isArray = Array.isArray(parameterValues);
      queryParams[queryKey] = isArray ? [...parameterValues, queryValue] : [parameterValues, queryValue];
    } else {
      queryParams[queryKey] = queryValue;
    }
  });

  return queryParams;
};

export const SearchContextType = Object.freeze({
  ALL: 'all',
  CLAIM: 'claim',
  COVER: 'cover',
  REINSURANCE: 'reinsurance',
});

export const SearchContextTypeProperties = Object.freeze([
  {
    title: 'All',
    section: 'Documents',
    type: SearchContextType.ALL,
    searchHintText: 'Let’s find what you are looking for…',
  },
  {
    title: 'Claim',
    section: 'Cases',
    type: SearchContextType.CLAIM,
    searchHintText: 'Search for claims by vessel name, fleet name, claim number, IMO or content in the claim information text',
  },
  {
    title: 'Cover',
    section: 'Cases',
    type: SearchContextType.COVER,
    searchHintText: 'Search for fleet name, client name, risk number or cover note number',
  },
  {
    title: 'Reinsurance',
    section: 'Cases',
    type: SearchContextType.REINSURANCE,
    searchHintText: 'Search for company name or business account name',
  },
]);

export const mapRoleToSearchContext = (roles, featureFlags = {}) => {
  const [firstRole] = roles;
  const { enableReinsurance } = featureFlags;

  const roleName = (firstRole && firstRole.name) || '';
  if (roleName.startsWith(RoleCategoryEnum.CLAIMS_HANDLER)) {
    return SearchContextType.CLAIM;
  }

  if (roleName.startsWith(RoleCategoryEnum.UNDERWRITER)) {
    return SearchContextType.COVER;
  }

  if (roleName.startsWith(RoleCategoryEnum.BUSINESS_OPERATIONS)) {
    return SearchContextType.COVER;
  }

  if (enableReinsurance && roleName.startsWith(RoleCategoryEnum.REINSURANCE)) {
    return SearchContextType.REINSURANCE;
  }

  return SearchContextType.ALL;
};

export const getRoleByContextType = (contextType) => {
  switch (contextType) {
    case 'ClaimContext':
    case SearchContextType.CLAIM:
      return { role: 'ClaimsHandler', displayName: 'Claims Handler' };

    case 'CoverContext':
    case SearchContextType.COVER:
      return { role: 'Underwriter', displayName: 'Underwriter' };

    case 'ReinsuranceContext':
    case SearchContextType.REINSURANCE:
      return { role: 'Reinsurance', displayName: 'Reinsurance' };
    default:
      throw new Error('Unsupported role');
  }
};

export const countActiveFilters = (queryString) => {
  // Throw away KnownQueryParams then count what is left
  const {
    [KnownQueryParams.CONTEXT]: _,
    [KnownQueryParams.SEARCH_TEXT]: __,
    [KnownQueryParams.PAGE]: ___,
    [KnownQueryParams.SORT_BY]: ____,
    [KnownQueryParams.SORT_DIRECTION]: _____,
    [KnownQueryParams.DETAILS]: ______,
    ...filters
  } = decodeQueryParams(queryString);

  let counter = 0;
  Object.values(filters).forEach((queryParamValue) => {
    if (Array.isArray(queryParamValue)) {
      counter += queryParamValue.length;
    } else {
      counter += 1;
    }
  });

  return counter;
};

export const CaseDocumentDateFilter = 'Document Date Range';

export const ClaimDateFilter = 'Date of Loss';

export const AllDocumentsDateFilters = 'Document Date Range';
