import React from 'react';
import { coverContextShape } from '../../lib/customProptypes';
import { getFormatedPeriod } from '../../lib/helpers';
import s from './CoverInfo.module.scss';

const CoverInfo = ({ context }) => (
  <div>
    <div className={s.caption}>Insurance Period</div>
    <div>{context.insurancePeriod ? getFormatedPeriod(context.insurancePeriod) : 'Not found'}</div>
    <div className={s.caption}>Policy number</div>
    <div>{context.policyNumber}</div>
    <div className={s.caption}>Risk number</div>
    <div>{context.riskNumber}</div>
    <div className={s.caption}>Fleet</div>
    <div>{context.fleetName}</div>
  </div>
);

CoverInfo.propTypes = { context: coverContextShape };

export default CoverInfo;
