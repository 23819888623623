import { sortWith, prop, descend, pipe, map } from 'ramda';
import mapContext from './mapContext';

const sortByYearAndClaimNum = sortWith([descend(prop('insuranceYear')), descend(prop('claimNumber'))]);

const mapContexts = (contexts) =>
  pipe(
    map((x) => mapContext(x.insuranceContext, x.restricted)),
    sortByYearAndClaimNum
  )(contexts);

export default mapContexts;
