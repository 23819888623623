import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MdiIcon from '@mdi/react';
import { mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiMinusBox } from '@mdi/js';

const Tooltip = styled.div`
  position: relative;
  cursor: pointer;
  :hover {
    span {
      top: -70%;
      left: -50%;
      visibility: visible;
      opacity: 1;
    }
  }
`;
const TooltipText = styled.span`
  position: absolute;
  transform: translate(0, -50%);
  background: ${(props) => props.theme.marineBlue};
  color: ${(props) => props.theme.white};
  white-space: nowrap;
  padding: 4px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;

  ::before {
    content: '';
    position: absolute;
    top: 130%;
    left: 3%;
    transform: translate(0, -50%);
    border: 8px solid;
    border-color: #003c71 transparent transparent transparent;
  }
`;

const Checkbox = ({ isSelected, disabled, onClick, text }) => {
  return (
    <div>
      {disabled ? (
        <Tooltip>
          <TooltipText>{text}</TooltipText>
          <MdiIcon path={mdiMinusBox} />
        </Tooltip>
      ) : (
        <MdiIcon data-test-id="V2FNr6lqm8mezXS9vHdVm" path={isSelected ? mdiCheckboxMarked : mdiCheckboxBlankOutline} onClick={onClick} />
      )}
    </div>
  );
};

Checkbox.propTypes = {
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default Checkbox;
