import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { PageLoader } from '@instech/components';
import { useClaims } from '../services/managementService';

export interface User {
  name?: string;
  email?: string;
}

const login = () => {
  const returnUrl = window.location.pathname + window.location.search;
  // Login and make sure we get back to the current page
  // Use window, instead of navigate, to have it hit the backend
  // instead of the SPA route
  window.location.href = `/management/login?returnUrl=${encodeURIComponent(returnUrl)}`;
};

interface AuthenticationContext {
  isAuthenticated: boolean;
  user: User | null;
  logout: () => void;
  login: () => void;
}

const defaultContextValue: AuthenticationContext = {
  isAuthenticated: false,
  user: null,
  logout: () => {
    throw new Error('Missing authentication provider');
  },
  login,
};

const authenticationContext = createContext<AuthenticationContext>(defaultContextValue);

export const AuthenticationProvider = ({ children }: { children?: ReactNode }) => {
  const { data, loading } = useClaims();

  const value = useMemo((): AuthenticationContext => {
    if (!data?.isAuthenticated) {
      return {
        isAuthenticated: false,
        user: null,
        login,
        logout: () => {
          throw new Error('Not authenticated.');
        },
      };
    }

    return {
      isAuthenticated: true,
      user: {
        name: data.claims?.find((i) => i.type === 'name')?.value,
        email: data.claims?.find((i) => i.type === 'email')?.value,
      },
      login,
      logout: () => {
        const logoutUrl = data.claims?.find((i) => i.type === 'bff:logout_url')?.value;
        if (logoutUrl) window.location.href = logoutUrl;
        else throw new Error('Missing bff:logout_url claim required for logout');
      },
    };
  }, [data]);

  if (!data || loading) return <PageLoader />;

  return <authenticationContext.Provider value={value}>{children}</authenticationContext.Provider>;
};

export const useAccount = () => useContext<AuthenticationContext>(authenticationContext);
