import { mdiChevronRight } from '@mdi/js';
import MdiIcon from '@mdi/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SearchContextType } from '../../containers/queryParameters';
import { claimSearchResultShape } from '../../lib/customProptypes';
import { getFormatedDate } from '../../lib/helpers';
import { BLUE_ICON_COLOR } from '../constVariables';
import DescriptionItem from '../Shared/DescriptionItem';
import style from './CaseSearch.module.scss';

const descriptionList = (item) => (
  <dl className={style.descriptionList}>
    <DescriptionItem term="Vessel" detail={item.vesselName} />
    <DescriptionItem term="Date of loss" detail={getFormatedDate(item.date)} />
    <DescriptionItem term="Claim number" detail={`${item.claimNumber || '—'}  / ${item.insuranceYear || '—'}`} />
    <DescriptionItem term="Interest code" detail={item.interest} />
    <DescriptionItem term="PETI" detail={item.peti} />
    <DescriptionItem term="Claims handler" detail={item.claimsHandler} />
    <DescriptionItem term="Status" detail={item.status} />
    <DescriptionItem term="Our share" detail={item.share ? `${Number(item.share).toFixed(2)}%` : null} />
  </dl>
);

const ClaimResult = ({ item }) => {
  const location = useLocation();

  return (
    <Link
      className={style.case}
      to={{
        pathname: `/cases/${SearchContextType.CLAIM}/${item.claimNumber}/${item.insuranceYear}`,
        state: { backUrl: location.pathname + location.search },
      }}
    >
      <div className={style.content}>
        {descriptionList(item)}

        <div className={style.caseDescription}>{item.description || '—'}</div>
      </div>

      <div className={style.caseIcon}>
        <MdiIcon path={mdiChevronRight} color={BLUE_ICON_COLOR} />
      </div>
    </Link>
  );
};

ClaimResult.propTypes = {
  item: PropTypes.shape(claimSearchResultShape),
};

export default ClaimResult;
