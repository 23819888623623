import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { RovingTabIndexProvider } from 'react-roving-tabindex';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDocumentSelection } from '../../containers/SearchResult/actions';
import { useDetailsQueryParam } from '../../hooks/documents';
import DesktopItem from './DesktopItem';
import MobileItem from './MobileItem';

const SearchResult = ({ isMobile = false }) => {
  const { detailsQueryParam, toggleQueryParam } = useDetailsQueryParam();
  const { currentView: documents, selectedDocumentsIds } = useSelector((state) => state.searchResult);
  const dispatch = useDispatch();
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(null);

  const toggleDocumentDetails = useCallback(
    (documentId) => {
      const documentIndex = documents.findIndex((item) => item.id === documentId);
      setCurrentDocumentIndex(documentIndex);
      toggleQueryParam(documentId);
    },
    [toggleQueryParam, documents]
  );

  const toggleSelection = (documentId) => {
    dispatch(toggleDocumentSelection(documentId));
  };

  const handleNavigateDown = () => {
    if (currentDocumentIndex + 1 < documents.length) {
      const nextDocumentId = documents[currentDocumentIndex + 1].id;
      toggleDocumentDetails(nextDocumentId);
    }
  };
  const handleNavigateUp = () => {
    if (currentDocumentIndex > 0) {
      const prevDocumentId = documents[currentDocumentIndex - 1].id;
      toggleDocumentDetails(prevDocumentId);
    }
  };

  return (
    <RovingTabIndexProvider
      options={{
        focusOnClick: true,
        direction: 'vertical',
      }}
    >
      {documents.map((document) => {
        const hasNoFiles = document.taxonomy?.documentTags?.includes('DocFlow') || false;
        const documentIsSelected = selectedDocumentsIds.includes(document.id) && !hasNoFiles;
        const documentIsOpened = !isMobile && document.id === detailsQueryParam;
        return isMobile ? (
          <MobileItem
            key={document.id}
            hasNoFiles={hasNoFiles}
            document={document}
            isSelected={documentIsSelected}
            toggleDocumentSelection={toggleSelection}
          />
        ) : (
          <DesktopItem
            key={document.id}
            document={document}
            hasNoFiles={hasNoFiles}
            isSelected={documentIsSelected}
            isOpened={documentIsOpened}
            toggleDocumentDetails={toggleDocumentDetails}
            toggleDocumentSelection={toggleSelection}
            handleNavigateDown={handleNavigateDown}
            handleNavigateUp={handleNavigateUp}
          />
        );
      })}
    </RovingTabIndexProvider>
  );
};

SearchResult.propTypes = {
  isMobile: PropTypes.bool,
};

export default SearchResult;
