import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/dist/simplebar.min.css';
import { SWRConfig } from 'swr';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { AuthenticationProvider, useAccount } from './providers/authenticationProvider';
import { theme } from './styles/theme';
import client from './apis/client';
import { LoggedOutPage, ProtectedRoute } from './authorization';
import CallbackPage from './authorization/components/CallbackPage';
import LoginPage from './authorization/components/LoginPage';
import './bootstrap-reboot.css';
import App from './components/App';
import CaseDocumentSearchPage from './components/CaseDocumentSearchPage/CaseDocumentSearchPage';
import DetailsPage from './components/Details/DetailsPage';
import UpdatesPage from './components/UpdatesPage/UpdatesPage';
import DocumentViewer from './components/DocumentViewer';
import MetadataSelector from './components/MetadataSelector';
import { ErrorBoundary } from './components/Error/ErrorBoundary';
import ForcedPiiNotifier from './components/ForcedPiiNotifier/ForcedPiiNotifier';
import { ModalProvider } from './components/Modal/ModalProvider';
import FileUploadProgress from './components/Shared/FileUploadProgress';
import { claimDocumentPath, coverDocumentPath, reinsuranceDocumentPath } from './containers/thunkHelpers';
import { fetchUserRoles, setUser } from './containers/User';
import useAppInsights from './hooks/useAppInsights';
import HttpStatus from './lib/HttpStatus';
import { FeatureFlagProvider } from './providers/FeatureFlagProvider';
import { FileUploadProvider } from './providers/FileUploadProvider';
import { ForcedPiiProvider } from './providers/ForcedPiiProvider';
import './scss/base.scss';
import configureStore, { history } from './store/configureStore';

const initialState = {};
const store = configureStore(initialState);
const rootElement = document.getElementById('root');
const fetcher = (url) => client.get(url).then((res) => res.data);

const PageRouter = () => {
  useAppInsights();
  const { user } = useAccount();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
      dispatch(fetchUserRoles());
    }
  }, [dispatch, user]);

  return (
    <>
      <Switch>
        <ProtectedRoute exact path="/document/:documentId/metadata" component={MetadataSelector} />
        <ProtectedRoute exact path="/document/:documentId" component={DocumentViewer} />
        <ProtectedRoute exact path="/details/:id" component={DetailsPage} />
        <ProtectedRoute exact path="/announcements" component={UpdatesPage} />
        <ProtectedRoute path={coverDocumentPath} component={CaseDocumentSearchPage} />
        <ProtectedRoute path={claimDocumentPath} component={CaseDocumentSearchPage} />
        <ProtectedRoute path={reinsuranceDocumentPath} component={CaseDocumentSearchPage} />
        <ProtectedRoute component={App} />
      </Switch>

      {/* Escape hatch portals for datepickers inside simplebar - it doesn't support absolute overflowing content */}
      <div id="datepicker-portal" className="themeNhc" />
      <FileUploadProgress />
      <ForcedPiiNotifier />
    </>
  );
};

ReactDOM.render(
  <ErrorBoundary>
    <SWRConfig
      value={{
        fetcher,
        revalidateOnFocus: false,
        onErrorRetry: (error, _, config, revalidate, { retryCount }) => {
          // Never retry on 404.
          if (error.response.status === HttpStatus.NOT_FOUND) return;

          // Never retry on 403.
          if (error.response.status === HttpStatus.FORBIDDEN) return;

          // Only retry up to 5 times.
          if (retryCount >= 5) return;

          // Retry after 5 seconds.
          setTimeout(() => revalidate({ retryCount }), 5000);
        },
      }}
    >
      <Provider store={store}>
        <AuthenticationProvider>
          <FeatureFlagProvider>
            <ConnectedRouter history={history}>
              <QueryParamProvider adapter={ReactRouter5Adapter}>
                <ThemeProvider theme={theme}>
                  <ModalProvider>
                    <FileUploadProvider>
                      <ForcedPiiProvider>
                        <Switch>
                          <Route path="/logout" component={LoggedOutPage} />
                          <Route path="/login" component={LoginPage} />
                          <Route path="/callback" component={CallbackPage} />
                          <ProtectedRoute path="/" component={PageRouter} />
                        </Switch>
                      </ForcedPiiProvider>
                    </FileUploadProvider>
                  </ModalProvider>
                </ThemeProvider>
              </QueryParamProvider>
            </ConnectedRouter>
          </FeatureFlagProvider>
        </AuthenticationProvider>
      </Provider>
    </SWRConfig>
  </ErrorBoundary>,
  rootElement
);
