import { mdiHelpCircle } from '@mdi/js';
import MdiIcon from '@mdi/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StringParam, useQueryParams } from 'use-query-params';
import { decodeQueryParams, KnownQueryParams, SearchContextType, SearchContextTypeProperties } from '../../containers/queryParameters';
import { fetchSearchResult as fetchSearchResultAction, reset as resetAction } from '../../containers/Search';
import { hasRole } from '../../containers/User';
import Search from '../Search';
import { ClaimSearchHelper, CoverSearchHelper, DocumentSearchHelper, ReinsuranceSearchHelper } from '../Search/SearchHelp';
import SearchContextSelector from '../SearchContextSelector';
import CloseModalButton from '../Shared/CloseModalButton';
import ItsDialog from '../Shared/ItsDialog';
import { defaultSort } from '../Shared/sortOptions';
import { RoleCategoryEnum } from '../../lib';
import styles from './App.module.scss';

const RootSearchBar = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({ user: state.user }));
  const [showDialog, setShowDialog] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams({
    [KnownQueryParams.CONTEXT]: StringParam,
    [KnownQueryParams.SEARCH_TEXT]: StringParam,

    // Used for default search if claims handler
    status: StringParam,
    claimsHandlerEmail: StringParam,

    // Used for default document search
    [KnownQueryParams.SORT_BY]: StringParam,
    [KnownQueryParams.SORT_DIRECTION]: StringParam,
  });

  const { searchHintText } = useMemo(
    () => SearchContextTypeProperties.find((type) => type.type === queryParams[KnownQueryParams.CONTEXT]) ?? '',
    [queryParams]
  );

  const searchTextQueryParam = queryParams[KnownQueryParams.SEARCH_TEXT];
  const searchContextQueryParam = queryParams[KnownQueryParams.CONTEXT];

  const reset = () => {
    setQueryParams(
      {
        [KnownQueryParams.CONTEXT]: searchContextQueryParam,
      },
      'replace'
    );
    dispatch(resetAction());
  };

  const fetchSearchResult = useCallback(
    (newSearchText) => {
      if (newSearchText) {
        setQueryParams(
          {
            [KnownQueryParams.SEARCH_TEXT]: newSearchText,
            [KnownQueryParams.CONTEXT]: queryParams[KnownQueryParams.CONTEXT],
          },
          'replace'
        );

        const isDefaultDocumentSearch = searchContextQueryParam === SearchContextType.ALL;
        if (isDefaultDocumentSearch) {
          setQueryParams(
            {
              [KnownQueryParams.SORT_BY]: defaultSort.sortBy,
              [KnownQueryParams.SORT_DIRECTION]: defaultSort.sortDirection,
            },
            'replaceIn'
          );
        }

        const isDefaultClaimSearch = searchContextQueryParam === SearchContextType.CLAIM && hasRole(user, RoleCategoryEnum.CLAIMS_HANDLER);
        if (isDefaultClaimSearch) {
          setQueryParams(
            {
              claimsHandlerEmail: user.email,
            },
            'replaceIn'
          );
        }

        dispatch(fetchSearchResultAction());
      }
    },
    [setQueryParams, queryParams, searchContextQueryParam, user, dispatch]
  );

  const openHelpDialog = () => setShowDialog(true);
  const closeHelpDialog = () => setShowDialog(false);

  useDeepLink();

  return (
    <>
      <div className={styles.searchWrapper}>
        <SearchContextSelector className={styles.contextSelector} />

        <Search onSearch={fetchSearchResult} onReset={reset} activeSearchText={searchTextQueryParam} hintText={searchHintText} hasLeftActions />

        <MdiIcon data-test-id="7wHskE2xJgdW_j2eNFPBE" path={mdiHelpCircle} className={styles.helpButton} onClick={openHelpDialog} />
      </div>
      <ItsDialog isOpen={showDialog} onDismiss={closeHelpDialog} aria-label="Search help text">
        <CloseModalButton close={closeHelpDialog} />

        {searchContextQueryParam === SearchContextType.ALL ? <DocumentSearchHelper /> : null}
        {searchContextQueryParam === SearchContextType.CLAIM ? <ClaimSearchHelper /> : null}
        {searchContextQueryParam === SearchContextType.COVER ? <CoverSearchHelper /> : null}
        {searchContextQueryParam === SearchContextType.REINSURANCE ? <ReinsuranceSearchHelper /> : null}
      </ItsDialog>
    </>
  );
};

export default RootSearchBar;

/**
 * Hook used to fetch search results when the app initializes with search query parameters
 */
const useDeepLink = () => {
  const { location } = useSelector((state) => ({
    location: state.router.location,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    const {
      // App will always have the context in the url, so it doesn't count as a deeplink query param
      // eslint-disable-next-line no-unused-vars
      [KnownQueryParams.CONTEXT]: context,
      ...rest
    } = decodeQueryParams(location.search);

    const hasQueryParams = Object.entries(rest).length > 0;
    if (hasQueryParams) {
      dispatch(fetchSearchResultAction());
    }
    // Should only run on first render of this component.
    // Removing dependency array initiates an infinite loop, while adding dependencies makes it react to changes we don't want it to react to
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
