export const TAB = 9;
export const ENTER = 13;
export const ESC = 27;
export const SPACE = 32;

export const WHITE = '#ffffff';
export const BLUE_ICON_COLOR = '#003c71';
export const GREY_ICON_COLOR = '#89a3c3';
export const LIGHT_GREY_ICON_COLOR = '#d9e3ec';
export const WARNING_COLOR = '#ef4444';
export const SEARCH_ICON_SIZE = '25px';
export const ICON_SIZE = '24px';
export const SMALL_ICON_SIZE = '1.6em';
export const ARROW_ICON_SIZE = '1.28rem';
export const LOGIN_ICON_SIZE = '1.71rem';
export const recipientType = {
  To: 'to',
  Cc: 'cc',
};

export const emailDirection = {
  none: 'None',
  incoming: 'Incoming',
  outgoing: 'Outgoing',
  internal: 'Internal',
};
