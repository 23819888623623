import React from 'react';
import { head } from 'ramda';
import { Icon } from 'its-react-ui';
import { mdiArrowRight } from '@mdi/js';
import styles from './helpers/helpers.module.scss';
import * as constants from '../../constVariables';

const getFileExtension = (file) => file.name.split('.').pop();

const isUnique = (value, index, array) => array.indexOf(value) === index;

export const getUniqueFileExtensions = (files, isFile) =>
  files
    .filter((f) => f.isAttachment || isFile)
    .map(getFileExtension)
    .filter(isUnique);

const getText = (person) => (person ? (person.name !== '' ? person.name : person.address) : '');

export const emailData = (item, isMobile) => {
  const hasRecipients = item.recipients && item.recipients.length > 0;
  const mainRecipient = hasRecipients ? head(item.recipients) : null;
  const senderText = getText(item.sender);
  const recipientText = getText(mainRecipient);
  const extraRecipients = (hasRecipients ? item.recipients.length - 1 : 0) + item.otherRecipients ? item.otherRecipients.length : 0;

  return (
    <>
      <div className={styles.sender}>{senderText}</div>
      <Icon path={mdiArrowRight} size={constants.ARROW_ICON_SIZE} color={constants.BLUE_ICON_COLOR} />
      <div className={styles.recipient}>{recipientText}</div>
      {extraRecipients > 0 && <div className={styles.extraRecipients}>&nbsp;+ {extraRecipients}</div>}
      {!isMobile && <div className={styles.subject}>{item.subject}</div>}
    </>
  );
};

export const fileData = (item) => (item.files.length > 0 ? item.files[0].name : '');
