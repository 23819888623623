import { mdiAccount } from '@mdi/js';
import { useFormikContext } from 'formik';
import { ContentRow, DropdownField } from 'its-react-ui';
import { func } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getClassifications } from '../../../apis';
import { getDocumentTypesByRoles } from '../../../apis/document';
import { mapRoleToSearchContext, SearchContextType } from '../../../containers/queryParameters';
import useHttpRequest from '../../../hooks/useHttpRequest';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { Roles } from '../../../lib/roles';
import XHR_STATUS from '../../../lib/xhrStatus';
import { useFeatureFlags } from '../../../providers/FeatureFlagProvider';
import { PageSpinner } from '../../Shared';
import ErrorAction from '../../Shared/ErrorAction';
import {
  ClassificationSelector,
  DocumentTypeSelector,
  KeywordsSelector,
  mapClassificationToVM,
  mapDocumentTypeToVM,
  NotesInput,
} from '../../Shared/upsertDocumentHelpers';
import PiiSelector from './PiiSelector';

const ContentStep = ({ updateValidationSchema }) => {
  const { caseType } = useParams();
  const user = useSelector((state) => state.user);
  const fixedSize = !useIsMobile();
  const featureFlags = useFeatureFlags();

  const {
    values: { documentType: selectedDocumentType, classification: selectedClassification, roleType: selectedUserRole },
    setFieldValue,
    getFieldMeta,
  } = useFormikContext();
  const roleTypeMeta = getFieldMeta('roleType');
  const classificationMeta = getFieldMeta('classification');

  const [roleOptions, setRoleOptions] = useState();

  const { refetch: fetchClassifications, contentState: classificationState, data: classifications } = useHttpRequest(getClassifications);
  const { refetch: fetchDocumentTypes, contentState: documentTypesState, data: documentTypesByRoles } = useHttpRequest(getDocumentTypesByRoles);

  const documentType =
    documentTypesByRoles &&
    documentTypesByRoles[selectedUserRole] &&
    documentTypesByRoles[selectedUserRole].find((d) => d.name === selectedDocumentType);
  const tags = (documentType && documentType.tags) || [];

  useEffect(() => {
    if (user.remoteDataState.isSuccess()) {
      const roles = RolesByCaseType[caseType];

      if (userRoleMatchesContext) {
        const selectedRole = roles.find((r) => !!user.roles.find((ur) => ur.name.toLowerCase() === r.value.toLowerCase()));
        setFieldValue('roleType', selectedRole.value);
      }

      if (!userRoleMatchesContext && roles.length === 1) {
        setFieldValue('roleType', roles[0].value);
      }

      if (roles.length === 1) {
        setFieldValue('roleType', roles[0].value);
      }

      setRoleOptions(roles);
    }
  }, [caseType, setFieldValue, user.remoteDataState, user.roles, userRoleMatchesContext]);

  useEffect(() => {
    if (classifications && classifications.length > 0 && !classificationMeta.value) {
      const defaultClassification = classifications.find((c) => c.name === 'Client Info');
      if (defaultClassification) {
        setFieldValue('classification', defaultClassification.name);
      }
    }
  }, [classificationMeta.value, classifications, setFieldValue]);

  const selectRole = (role) => {
    setFieldValue('keywords', []);
    setFieldValue('documentType', '');
    setFieldValue('roleType', role.value);
  };

  const retryLoadRequiredData = () => {
    fetchClassifications();
    fetchDocumentTypes();
  };

  const userRoleMatchesContext = useMemo(() => mapRoleToSearchContext(user.roles, featureFlags), [featureFlags, user.roles]) === caseType;

  const classificationVMs = useMemo(() => {
    if (!classifications || classifications.length === 0) {
      return [];
    }

    return classifications.map((c) => mapClassificationToVM(c, selectedClassification)).sort((a, b) => a.priority - b.priority);
  }, [classifications, selectedClassification]);

  const documentTypeVMs = useMemo(() => {
    if (!selectedUserRole || !documentTypesByRoles) {
      return [];
    }

    return documentTypesByRoles[selectedUserRole]
      .filter((d) => !d.disabled)
      .map((d) => mapDocumentTypeToVM(d, selectedDocumentType))
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
  }, [documentTypesByRoles, selectedUserRole, selectedDocumentType]);

  const rolesVMs = useMemo(() => {
    if (!roleOptions) {
      return [];
    }

    return roleOptions.map((r) => mapRoleToVM(r, selectedUserRole));
  }, [roleOptions, selectedUserRole]);

  if (classificationState === XHR_STATUS.LOADING || documentTypesState === XHR_STATUS.LOADING) {
    return <PageSpinner />;
  }

  if (classificationState === XHR_STATUS.ERROR || documentTypesState === XHR_STATUS.ERROR) {
    return <ErrorAction text="Unable to load required options" retry={retryLoadRequiredData} />;
  }

  return (
    <div>
      <ContentRow
        icon={mdiAccount}
        title="Role"
        validation={{
          show: roleTypeMeta.error && roleTypeMeta.touched,
          valid: !roleTypeMeta.error,
        }}
      >
        <DropdownField values={rolesVMs} onSelect={selectRole} />
      </ContentRow>

      <DocumentTypeSelector disabled={!selectedUserRole} fixedSize={fixedSize} documentTypeVMs={documentTypeVMs} />

      <ClassificationSelector classificationVMs={classificationVMs} />

      <PiiSelector updateValidationSchema={updateValidationSchema} />

      <NotesInput />

      <KeywordsSelector tags={tags} />
    </div>
  );
};

ContentStep.propTypes = {
  updateValidationSchema: func.isRequired,
};

export default ContentStep;

const mapRoleToVM = (role, selected) => ({
  displayName: role.name,
  selected: role.value === selected,
  value: role.value,
});

const RolesByCaseType = Object.freeze({
  [SearchContextType.CLAIM]: [Roles.CLAIMS_HANDLER, Roles.CLAIMS_HANDLER_PI],
  [SearchContextType.COVER]: [Roles.UNDERWRITER, Roles.UNDERWRITER_ENERGY, Roles.UNDERWRITER_PI, Roles.BUSINESS_OPERATIONS],
  [SearchContextType.REINSURANCE]: [Roles.REINSURANCE],
});
