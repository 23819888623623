import { mdiClose } from '@mdi/js';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StringParam, useQueryParams } from 'use-query-params';
import { countActiveFilters, KnownQueryParams } from '../../containers/queryParameters';
import { fetchAllCaseDocuments } from '../../containers/Search';
import { toggleDocumentSelection as toggleDocumentSelectionAction } from '../../containers/SearchResult/actions';
import { useDetailsQueryParam } from '../../hooks/documents';
import XHR_STATUS from '../../lib/xhrStatus';
import { ICON_SIZE } from '../constVariables';
import Details from '../Details';
import SelectionPanel from '../SelectionPanel';
import { IconButton } from '../Shared';
import SearchGrid from '../Shared/SearchGrid';
import SearchView from '../Shared/SearchView';
import CaseDocumentEmptyResults from './CaseDocumentEmptyResults';
import CaseDocumentFilters from './CaseDocumentFilters';
import styles from './CaseDocumentSearchPage.module.scss';
import CaseDocumentTable from './CaseDocumentTable';
import { mapDocumentToViewModel } from './helpers';

const CaseDocumentSearchDesktop = () => {
  const {
    searchResult: { currentView: documents, selectedDocumentsIds, remoteFilterChangedDataState },
    filters: { facets },
    location,
  } = useSelector(({ searchResult, details, router, filters }) => ({
    searchResult,
    details,
    location: router.location,
    filters,
  }));
  const dispatch = useDispatch();
  const documentsViewModel = documents.map(mapDocumentToViewModel);
  documentsViewModel.sort((a, b) => (a.date < b.date ? 1 : -1));
  const hasDocuments = documents.length > 0;
  const activeFiltersCount = countActiveFilters(location.search);
  const hasFilters = facets.length > 0;
  const { detailsQueryParam, clearQueryParam } = useDetailsQueryParam();
  const documentInDetailsView = documents.find((x) => x.id === detailsQueryParam);
  const showContextsInDetailsView = documentInDetailsView && documentInDetailsView.contexts.length > 1;
  const [queryParamsToKeep, setQueryParamsToKeep] = useQueryParams({
    [KnownQueryParams.SEARCH_TEXT]: StringParam,
  });
  const [selectedDocumentIds, setSelectedDocumentIds] = useState(selectedDocumentsIds);

  const clearAllFilters = () => {
    setQueryParamsToKeep(queryParamsToKeep, 'replace');
    dispatch(fetchAllCaseDocuments());
  };

  const toggleDocumentSelection = useCallback(
    (documentId) => {
      dispatch(toggleDocumentSelectionAction(documentId));
    },
    [dispatch]
  );

  return (
    <SearchView>
      <SearchGrid
        hasDetails={detailsQueryParam}
        leftActions={
          activeFiltersCount > 0 ? (
            <IconButton
              data-test-id="yDFuYGZHwMBTAl-JlNoSk"
              text={`Clear filters (${activeFiltersCount})`}
              icon={mdiClose}
              iconSize={ICON_SIZE}
              onClick={clearAllFilters}
            />
          ) : null
        }
        left={hasFilters ? <CaseDocumentFilters /> : <div />}
        middleActions={hasDocuments ? <SelectionPanel /> : <div />}
        middle={
          hasDocuments ? (
            <CaseDocumentTable
              documents={documentsViewModel}
              openDocumentId={detailsQueryParam}
              selectedDocumentsIds={selectedDocumentsIds}
              toggleDocumentSelection={toggleDocumentSelection}
              selectedDocumentIds={selectedDocumentIds}
              setSelectedDocumentIds={setSelectedDocumentIds}
            />
          ) : (
            <CaseDocumentEmptyResults />
          )
        }
        middleDisabled={remoteFilterChangedDataState === XHR_STATUS.LOADING}
        rightActions={
          !!detailsQueryParam && (
            <IconButton data-test-id="oMYtvttgsotrQk6AXQgIJ" text="Clear preview" icon={mdiClose} iconSize={ICON_SIZE} onClick={clearQueryParam} />
          )
        }
        right={!!detailsQueryParam && <Details documentId={detailsQueryParam} showContext={showContextsInDetailsView} className={styles.details} />}
      />
    </SearchView>
  );
};

export default CaseDocumentSearchDesktop;
