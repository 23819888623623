import { mdiReload } from '@mdi/js';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import * as constants from '../constVariables';
import styles from './ErrorAction.module.scss';
import IconButton from './IconButton';

const ErrorAction = ({ text, retry, className }) => (
  <div className={cn(styles.error, className)}>
    <span>{text}</span>
    <IconButton
      data-test-id="64yVG6scv1Zglk9TRLuJN"
      className={styles.action}
      icon={mdiReload}
      text="Retry"
      iconSize={constants.SMALL_ICON_SIZE}
      onClick={retry}
    />
  </div>
);

ErrorAction.propTypes = {
  text: PropTypes.string.isRequired,
  retry: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ErrorAction;
