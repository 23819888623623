import { useState } from 'react';

const useDialog = (initialState = false) => {
  const [showDialog, setShowDialog] = useState(initialState);
  const openDialog = () => setShowDialog(true);
  const closeDialog = () => setShowDialog(false);

  return { isShowing: showDialog, show: openDialog, hide: closeDialog };
};

export default useDialog;
