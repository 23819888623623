import { useEffect, useState } from 'react';
import { useLocation, useParams, useRouteMatch } from 'react-router';
import {
  getCoverByRiskNumberCoverNumberAndInsuranceYear,
  getClaimByClaimNumberAndInsuranceYear,
  getReinsuranceContext,
} from '../../../apis/insuranceContext';
import { SearchContextType } from '../../../containers/queryParameters';
import { reinsuranceDocumentPath } from '../../../containers/thunkHelpers';

/*
 * Hook for getting the current context id based on the params in the url.
 * It is context type agnostic and only applies to the "case search" pages.
 */
export const useContextId = () => {
  const { caseType, riskNumber, coverNumber, insuranceYear, claimNumber } = useParams();
  const reinsuranceMatch = useRouteMatch(reinsuranceDocumentPath);
  const [data, setData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (caseType === SearchContextType.COVER) {
      getCoverByRiskNumberCoverNumberAndInsuranceYear(riskNumber, coverNumber, insuranceYear).then((result) => setData(result));
    }
  }, [caseType, coverNumber, insuranceYear, riskNumber]);

  useEffect(() => {
    if (caseType === SearchContextType.CLAIM) {
      getClaimByClaimNumberAndInsuranceYear(claimNumber, insuranceYear).then((result) => setData(result));
    }
  }, [caseType, claimNumber, insuranceYear]);

  useEffect(() => {
    if (caseType === SearchContextType.REINSURANCE) {
      if (!reinsuranceMatch) return;
      const { companyNumber, businessAccountNumber } = reinsuranceMatch.params;
      getReinsuranceContext(companyNumber, businessAccountNumber, insuranceYear).then((result) => setData(result));
    }
    // reinsuranceMatch was changing on every render, so we rely on location as a dep instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, caseType, insuranceYear]);

  const id = data ? data.id : undefined;
  return id;
};

export const getContextIsSelected = (contextId, selectedDocument) => {
  const isSelected = selectedDocument && selectedDocument.some((c) => c.id === contextId);
  return isSelected;
};

/*
 * Hook used to call the 'toggle' callback with the current context id,
 * if it is not already selected for deletion.
 * Only applies to the "case search" pages.
 */
export const useInitialContextToggle = (currentContextId, currentDocument, toggle, contextExists) => {
  useEffect(() => {
    const contextIsSelected = getContextIsSelected(currentContextId, currentDocument);

    if (currentContextId && !contextIsSelected && contextExists) {
      toggle(currentContextId);
    }
    // selectedDocument is left out of the deps array on purpose, to avoid toggeling when it should not toggle.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContextId, toggle, contextExists]);
};

export const mapClaims = (contexts) => ({
  headers: ['Claim number', 'Vessel', 'Interest', 'Assured'],
  rows: contexts.map((c) => {
    const { interest: { shortName } = {} } = c;
    const row = {
      content: [c.claimNumber, c.vesselName, shortName, c.businessAccount],
      contextId: c.id,
    };
    return row;
  }),
});

export const mapCovers = (contexts) => ({
  headers: ['Risk number', 'Cover note', 'Interest', 'Fleet name'],
  rows: contexts.map((c) => {
    const { interest: { shortName } = {} } = c;
    const row = {
      content: [c.riskNumber, c.coverNote, shortName, c.fleetName],
      contextId: c.id,
    };
    return row;
  }),
});

export const mapReinsurances = (contexts) => ({
  headers: ['Company', 'Company number', 'Business account', 'Business account number', 'Underwriting year'],
  rows: contexts.map((c) => {
    const row = {
      content: [c.companyName, c.companyNumber, c.businessAccount, c.businessAccountNumber, c.insuranceYear],
      contextId: c.id,
    };
    return row;
  }),
});
