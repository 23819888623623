import PropTypes from 'prop-types';
import React, { forwardRef, useCallback } from 'react';
import { mdiClose } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { NumberParam, useQueryParam, StringParam, useQueryParams } from 'use-query-params';
import { documentFilterChanged } from '../../containers/Filters/actions';
import { ICON_SIZE } from '../constVariables';
import SearchGrid from '../Shared/SearchGrid';
import { EmptyResult, SortByMenu } from '..';
import { KnownQueryParams, countActiveFilters } from '../../containers/queryParameters';
import { fetchNextSearchResult as fetchNextSearchResultAction } from '../../containers/SearchResult/actions';
import { useDetailsQueryParam } from '../../hooks/documents';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import RemoteDataComponent from '../../lib/RemoteData';
import RootSearchBar from '../App/RootSearchBar';
import { DocumentSearchHelper } from '../Search/SearchHelp';
import SelectionPanel from '../SelectionPanel';

import { InfiniteScrollContainer, PageSpinner, SearchView, IconButton } from '../Shared';
import DocumentFilters from './DocumentFilters';
import style from './DocumentSearch.module.scss';
import documentIcon from '../Icons/document.svg';
import Details from '../Details';
import { SearchResult } from '../SearchResult';
import DetailsStatus from '../Details/DetailsStatus';

const DocumentSearch = () => {
  const {
    searchResult: { hasNoItems, hasMoreItems, remoteDataState },
  } = useSelector(({ searchResult }) => ({ searchResult }));
  const [pageQueryParam, setPageQueryParam] = useQueryParam([KnownQueryParams.PAGE], NumberParam);

  const dispatch = useDispatch();
  const fetchNextSearchResult = useCallback(() => {
    setPageQueryParam((pageQueryParam || 1) + 1, 'replaceIn');
    dispatch(fetchNextSearchResultAction());
  }, [dispatch, pageQueryParam, setPageQueryParam]);

  const { scrollableRef, scrollToTop } = useInfiniteScroll(hasMoreItems, fetchNextSearchResult);

  return (
    <>
      <div className={style.searchContainer}>
        <RootSearchBar />
        {!hasNoItems && <SortByMenu scrollToTop={scrollToTop} />}
      </div>

      <RemoteDataComponent
        type={remoteDataState}
        notAsked={() => <DocumentSearchHelper className={style.helpText} />}
        loading={() => <PageSpinner />}
        failure={(_) => <h1>Search failed</h1>}
        success={(_) => <DocumentSearchSuccess ref={scrollableRef} fetchNextSearchResult={fetchNextSearchResult} scrollToTop={scrollToTop} />}
      />
    </>
  );
};

export default DocumentSearch;

const DocumentSearchSuccess = forwardRef(({ scrollToTop, fetchNextSearchResult }, ref) => {
  const {
    searchResult: { remoteDataState, hasNoItems, hasMoreItems, currentView, remoteNextPageDataState },
    filters: { facets },
    location,
  } = useSelector(({ searchResult, router, filters }) => ({ searchResult, location: router.location, filters }));
  const { detailsQueryParam } = useDetailsQueryParam();
  const dispatch = useDispatch();

  const [searchText] = useQueryParam(KnownQueryParams.SEARCH_TEXT);
  const [queryParamsToKeep, setQueryParamsToKeep] = useQueryParams({
    [KnownQueryParams.SEARCH_TEXT]: StringParam,
    [KnownQueryParams.CONTEXT]: StringParam,
    [KnownQueryParams.SORT_BY]: StringParam,
    [KnownQueryParams.SORT_DIRECTION]: StringParam,
  });

  const hasFilters = remoteDataState.isSuccess() && facets.length > 0;
  const activeFiltersCount = countActiveFilters(location.search);

  const clearAllFilters = () => {
    setQueryParamsToKeep(queryParamsToKeep, 'replace');
    dispatch(documentFilterChanged());
  };

  return (
    <SearchView>
      <SearchGrid
        hasDetails
        leftActions={
          activeFiltersCount > 0 ? (
            <IconButton
              data-test-id="VVfvoCMwsGqBvQadrLXpC"
              text={`Clear filters (${activeFiltersCount})`}
              icon={mdiClose}
              iconSize={ICON_SIZE}
              onClick={clearAllFilters}
            />
          ) : null
        }
        left={hasFilters ? <DocumentFilters /> : <div />}
        middleActions={<SelectionPanel />}
        middle={
          hasNoItems ? (
            <div />
          ) : (
            <InfiniteScrollContainer
              ref={ref}
              items={currentView}
              remoteDataState={remoteNextPageDataState}
              hasMoreItems={hasMoreItems}
              fetchNextSearchResult={fetchNextSearchResult}
              scrollToTop={scrollToTop}
            >
              {hasNoItems ? <EmptyResult searchText={searchText} /> : <SearchResult />}
            </InfiniteScrollContainer>
          )
        }
        right={
          detailsQueryParam ? (
            <Details documentId={detailsQueryParam} className={style.details} />
          ) : (
            <DetailsStatus title="No document selected" subtitle="Select a document to inspect it here" image={documentIcon} />
          )
        }
      />
    </SearchView>
  );
});

DocumentSearchSuccess.propTypes = {
  scrollToTop: PropTypes.func.isRequired,
  fetchNextSearchResult: PropTypes.func.isRequired,
};
