import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAccount } from '../../providers/authenticationProvider';

const CallbackComponent = ({ children }) => {
  const { signinCallback } = useAccount();
  const [url, setUrl] = useState(null);

  useEffect(() => {
    signinCallback().then((state) => {
      const route = state && state.route;
      setUrl(route || '/');
    });
  }, []);

  if (url) {
    return <Redirect to={url} />;
  }

  return <div>{children}</div>;
};
CallbackComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const CallbackPage = () => (
  <CallbackComponent>
    <div>Redirecting...</div>
  </CallbackComponent>
);

export default CallbackPage;
