import { mdiCheckAll, mdiLayersOff } from '@mdi/js';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllDocuments, unselectAllDocuments } from '../../containers/SearchResult/actions';
import { IconButton } from '../Shared';
import Actions from './Actions';
import style from './SelectionPanel.module.scss';

const SelectionPanel = () => {
  const { selectedDocumentsIds } = useSelector((state) => state.searchResult);
  const dispatch = useDispatch();
  const selectAll = () => dispatch(selectAllDocuments);
  const unselectAll = () => dispatch(unselectAllDocuments);

  return (
    <div className={style.wrap}>
      <div>
        <div className={style.info}>
          {selectedDocumentsIds.length === 0 && 'Select one or more documents'}
          {selectedDocumentsIds.length === 1 && '1 item selected'}
          {selectedDocumentsIds.length > 1 && `${selectedDocumentsIds.length} items selected`}
        </div>

        <div className={style.selectionActions}>
          <IconButton icon={mdiCheckAll} text="Select all" onClick={selectAll} data-test-id="select-all-icon-button" />
          <IconButton icon={mdiLayersOff} text="Clear all" onClick={unselectAll} data-test-id="unselect-all-icon-button" />
        </div>
      </div>

      <div className={style.buttonActions}>
        <Actions />
      </div>
    </div>
  );
};

export default SelectionPanel;
