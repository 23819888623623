import { mdiAlert } from '@mdi/js';
import cn from 'classnames';
import { ErrorMessage } from 'formik';
import { Icon } from 'its-react-ui';
import PropTypes from 'prop-types';
import React from 'react';
import * as constants from '../constVariables';
import styles from './FormErrorMessage.module.scss';

const FormErrorMessage = ({ className, ...rest }) => (
  <ErrorMessage {...rest}>
    {(message) => (
      <div className={cn(styles.wrap, className)}>
        <Icon path={mdiAlert} size="18px" color={constants.WARNING_COLOR} />
        <span className={styles.message}>{message}</span>
      </div>
    )}
  </ErrorMessage>
);

FormErrorMessage.propTypes = {
  className: PropTypes.string,
};

export default FormErrorMessage;
