import React from 'react';
import PropTypes from 'prop-types';
import { toLower } from 'ramda';
import ItemTopContent from './ItemTopContent';
import EmailDirectionIndicator from './EmailDirectionIndicator';
import { documentResultShape } from '../../lib/customProptypes';
import { isEmailDocument } from '../../lib/helpers';
import ItemBottomContent from './ItemBottomContent';
import style from './SearchResult.module.scss';

const Indicators = ({ item }) => {
  const contextType = item.contexts && item.contexts.length > 0 ? toLower(item.contexts[0].$type) : '';
  return (
    <div className={style.indicators}>
      {contextType === 'claimcontext' ? <span>CL</span> : null}
      {contextType === 'covercontext' ? <span>UW</span> : null}
      {contextType === 'reinsurancecontext' ? <span>RE</span> : null}
      {isEmailDocument(item) && <EmailDirectionIndicator direction={item.direction} />}
    </div>
  );
};
Indicators.propTypes = { item: documentResultShape };
const ItemContent = ({ isMobile, item }) => (
  <div className={style.itemContent}>
    <Indicators item={item} />
    <div className={style.itemContentParts}>
      <ItemTopContent item={item} isMobile={isMobile} />
      <ItemBottomContent isMobile={isMobile} item={item} />
    </div>
  </div>
);

ItemContent.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  item: documentResultShape,
};

export default ItemContent;
