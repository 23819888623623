import { getSearchResult } from '../../apis';
import { mapSearchPayload } from '../../apis/mappers/mapSearchPayload';
import { toAction } from '../../lib';
import { decodeQueryParams } from '../queryParameters';
import * as a from './constants';

export const fetchNextSearchResult = () => async (dispatch, getState) => {
  dispatch(toAction(a.FETCH_NEXT_SEARCH_RESULT));
  const {
    router: { location },
  } = getState();

  const queryParams = decodeQueryParams(location.search);
  const searchPayload = mapSearchPayload(queryParams);

  try {
    const result = await getSearchResult(searchPayload);
    if (!result) {
      // result is undefined if the request was cancelled
      return null;
    }
    return dispatch(toAction(a.NEXT_SEARCH_RESULT_SUCCESS, result));
  } catch (error) {
    return dispatch(toAction(a.NEXT_SEARCH_RESULT_FAILURE, error));
  }
};

export const toggleDocumentSelection = (documentId) => (dispatch) => dispatch(toAction(a.TOGGLE_DOCUMENT_SELECTION, documentId));

export const selectAllDocuments = toAction(a.SELECT_ALL_DOCUMENTS);

export const unselectAllDocuments = toAction(a.UNSELECT_ALL_DOCUMENTS);

export const deleteSelectedDocuments = () => (dispatch, getState) => {
  dispatch(toAction(a.DELETE_SELECTED_DOCUMENTS));

  const {
    searchResult: { currentView, hasMoreItems },
  } = getState();
  if (currentView.length === 0 && hasMoreItems) dispatch(fetchNextSearchResult());
};
