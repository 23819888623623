/* eslint-disable no-await-in-loop */
import { PDFDocumentProxy } from 'pdfjs-dist';

export const pdfHasOcrLayer = async (pdf: PDFDocumentProxy) => {
  for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber += 1) {
    const page = await pdf.getPage(pageNumber);
    const text = await page.getTextContent();
    if (text.items.length > 0) return true;
  }
  return false;
};
