import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './DescriptionItem.module.scss';

const DescriptionItem = ({ term, detail, className, classNameTerm, classNameDetail, onClick }) => (
  <div data-test-id="tuuhqG5q_w9E0seZDZy0_" className={cn(styles.root, className)} onClick={onClick}>
    <dt className={classNameTerm}>{term}</dt>
    <dd className={classNameDetail}>{detail || '—'}</dd>
  </div>
);

DescriptionItem.propTypes = {
  term: PropTypes.string.isRequired,
  detail: PropTypes.string,
  className: PropTypes.string,
  classNameTerm: PropTypes.string,
  classNameDetail: PropTypes.string,
  onClick: PropTypes.func,
};

export default DescriptionItem;
