import MdiIcon from '@mdi/react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Button from './Button';
import styles from './IconButton.module.scss';

const IconButton = ({ icon, iconSize, text, onClick, className, typeStyle = 'transparent', ...rest }) => (
  <Button data-test-id="xNqLGmgR9f7X_JNYsthKk" className={cn(styles.root, className)} onClick={onClick} typeStyle={typeStyle} {...rest}>
    <MdiIcon path={icon} size={iconSize || null} />
    {text && <span>{text}</span>}
  </Button>
);

IconButton.propTypes = {
  text: PropTypes.string,
  iconSize: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  typeStyle: PropTypes.string,
};

export default IconButton;
