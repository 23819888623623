import daggy from 'daggy';

const ContextType = daggy.taggedSum('ContextType', {
  ClaimContext: [],
  CoverContext: [],
  ReinsuranceContext: [],
  SomeOtherContext: [],
});

export default ContextType;
