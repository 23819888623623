import { useState } from 'react';
import client from '../apis/client';
import XHR_STATUS from '../lib/xhrStatus';

export const useSavePiiSections = () => {
  const [saveState, setSaveState] = useState(XHR_STATUS.INITIAL);

  const savePiiSections = async (payload) => {
    setSaveState(XHR_STATUS.LOADING);

    try {
      await client.patch('document/piis', payload);
      setSaveState(XHR_STATUS.SUCCESS);
    } catch (error) {
      setSaveState(XHR_STATUS.ERROR);
    }
  };

  return {
    saveState,
    savePiiSections,
  };
};
